<template>
  <div>
    <div style="width: 1400px; margin: 0 auto">
      <middleWiedt
        :middleImg="middleImg"
        :middleTitle="middleTitle"
        :middleContent="middleContent"
        :middleList="middleList"
      ></middleWiedt>

      <div
        style="
          background-color: #f4f4f4;
          width: 1400px;
          margin: 0 auto 50px;
          padding-bottom: 30px;
        "
      >
        <div>
          <div class="title">EDI许可证申请代理服务内容</div>
          <div class="title_middle" style="margin: 15px 0">
            全程无须您亲自到场/双向快递、极速拿照
          </div>
          <div
            style="
              font-family: Source Han Sans CN;
              font-weight: 400;
              font-size: 16px;
              color: #333333;
              text-align: center;
              margin-bottom: 20px;
            "
          >
            费用包含：EDI证正本及附页；专业技术方案的提供、专业技术人员派遣、EDI业务网站、机房部署、难点技术攻关、业务指导
          </div>
          <div style="text-align: center">
            <img class="none" src="@/assets/img/zzbl/three/2.png" alt="" />
          </div>
          <div
            style="
              font-family: Source Han Sans CN;
              font-weight: 400;
              font-size: 16px;
              color: #333333;
              text-align: center;
            "
          >
            <div style="margin: 20px 50px">SP许可证样本</div>
            <div>
              证书内容:名称、编号、导语、申办单位名称、法定代表人、注册资本、
            </div>
            <div style="margin: 10px 0; padding-bottom: 70px">
              注册住所、业务种类、业务覆盖范围、签发人、发证机关盖章、发证日期、有效期。
            </div>
          </div>
        </div>

        <div style="margin: 0 40px 40px">
          <div class="title" style="margin-bottom: 40px">EDI许可证服务明细</div>
          <div
            style="
              background-color: #fff;
              display: flex;
              justify-content: center;
            "
          >
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/2.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                申请
              </div>
            </div>
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/3.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                变更/增项
              </div>
            </div>
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/4.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                续期/注销
              </div>
            </div>
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/5.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                转让
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="margin-bottom: 100px">
        <div class="title" style="margin-bottom: 35px">
          EDI许可证办理服务流程
        </div>
        <div style="display: flex">
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/6.png" alt="" />
            <div class="xk_title">咨询业务意向</div>
            <div class="xk_t">（确定您办理的业务类型）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/7.png" alt="" />
            <div class="xk_title">商务合作洽谈</div>
            <div class="xk_t">（为您量身定制解决方案）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/8.png" alt="" />
            <div class="xk_title">基础材料准备</div>
            <div class="xk_t">（协助您准备所需硬性材料）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/9.png" alt="" />
            <div class="xk_title">提交审核</div>
            <div class="xk_t">（剩下的事就交给我们了）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/10.png" alt="" />
            <div class="xk_title">领取许可证</div>
          </div>
        </div>
      </div>
    </div>
    <bottomWiedt style="margin-top: 50px;" :bottomTitle="bottomTitle"></bottomWiedt>
  </div>
</template>
<script>
import middleWiedt from "./middleWiedt.vue";
import bottomWiedt from "./bottomWiedt.vue";

export default {
  components: {
    middleWiedt,
    bottomWiedt,
  },
  data() {
    return {
      middleImg: "3",
      middleList: [
        {
          title: "哪些业务需申请EDI许可证",
          content: [
            {
              one:
                "从业务角度可以分为三类:交易处理业务、网络/垫资设备数据处理业务、电子数据交换业务;",
              two:
                "1.“交易处理业务”是指办理各种银行业务、股票买卖、票务买卖、拍卖商品买卖、费用支付等。1",
              three:
                "2.“网络/电子设备数据处理业务”是指通过通信网络传送，对连接到通信网络的电子设备进行控制和数据处理的业务。",
              four:
                "3.“电子数据交换业务”，是一种把贸易或其它行政事务有关的信息和数据按统一规定的格式形成结构化的事务处理数据，通过通信网络在有关用户的计算机之间进行交换和自动处理，完成贸易或其它行政事务的业务。",
            },
          ],
          remerk:
            "从事网上商城、物联网交易、平台交易、P2P交易的企业需办理在线数据处理与交易处理业务牌照。",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "未申请EDI许可证开展业务有何处罚?",
          content: [
            {
              one:
                "根据国务院和工信部以及国家行业管理部门颁布的《中华人民共和国电子签名法》、《商用密码管理条例》、《电子银行业务管理办法》、《网络交易平台服务规范》、《电子认证服务管理办法》、《电子支付指引》和《互联网安全保护技术措施规定》等相关法规和法律文件的规定，未经省通信管理局/工信部审批核准的企业不得从事互联网交易处理业务、网络/垫资设备数据处理业务、电子数据交换业务:",
              two:
                "擅自从事经营性互联网信息服务，或者超出许可的项目提供服务的，由省、自治区、直辖市电信管理机构责令限期改正，有违法所得的，没收违法所得，处违法所得3倍以上5倍以下的罚款:没有违法所得或者违法所得不足5万元的，处10万元以上100万元以下的罚款,情节严重的，责令关闭网站。违反本办法的规定，未履行备案手续，擅自从事非经营性互联网信息服务，或者超出备案的项目提供服务的、由省、自治区、直辖市电信管理机构责令限期改正:拒不改正的，贵令关闭网站。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "SP证申请条件",
          content: [
            {
              one: "(一)经营者为依法设立的公司。",
              two: "(二)有与开展经营活动相适应的资金和专业人员。",
              three: "(三)有为用户提供长期服务的信誉或者能力。",
              four: "(四)省网业务的注册资本最低限额为100万元人民币。",
              five:
                "(五)全网业务的注册资金最低限额为1000万元人民币，且至少在2个省设立分公司。",
              six: "(六)有必要的场地、设施及技术方案。",
              seven:
                "(七)公司及其主要出资者和主要经营管理人员三年内无违反电信监督管理制度的违法记录。",
              eight: "(八)国家规定的其他条件。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
      ],
      middleTitle: "在线数据处理与交易（EDI许可证申请）",
      middleContent:
        "在线数据处理与交易处理业务是指利用各种与公用通信网或互联网相连的数据与交易事务处理应用平台，通过公用通信网或互联网为用户提供在线数据处理和交易/事务处理的业务。在线数据处理与交易处理业务包括交易处理业务、电子数据交换业务和网络/电子设备数据处理业务。从事此类业务需要办理在线数据处理与交易牌照,简称ED许可证。",
      bottomTitle: "创业前沿资讯",
    };
  },
};
</script>
<style scoped>
.kp div {
  margin-right: 15px;
  flex: 1;
}
.title_middle {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  text-align: center;
}
.xk_t {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
.youjiantou {
  margin-top: 60px;
  height: 20px;
  width: 80px;
}
.xk_title {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  margin: 10px 0;
}
.xk_content {
  text-align: center;
  flex: 1;
}
.xk_content .none {
  width: 135px;
  height: 135px;
}
.ICP_content {
  text-align: center;
  flex: 1;
  margin: 30px;
  border-right: 2px solid #ccc;
}
.title {
  text-align: center;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 30px;
  color: #333333;
}
</style>