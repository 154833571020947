<template>
  <div>
    <div style="width: 1400px; margin: 0 auto">
      <middleWiedt
        :middleImg="middleImg"
        :middleTitle="middleTitle"
        :middleContent="middleContent"
        :middleList="middleList"
      ></middleWiedt>

      <div style="background-color: #f4f4f4; padding: 20px">
        <div style="display: flex; margin-bottom: 45px; align-items: center">
          <div>
            <img
              style="width: 240px; height: 150px; margin-right: 25px"
              src="@/assets/img/zzbl/eighteen/2.png"
              alt=""
            />
          </div>
          <div
            style="
              font-family: Source Han Sans CN;
              font-weight: 400;
              font-size: 18px;
              color: #333333;
            "
          >
            <div style="margin-bottom: 10px">
              为国家局审批，全国只有27张(截止2016年4月);
            </div>
            <div style="margin-bottom: 10px">证书编号示例:国A20110001;</div>
            <div style="margin-bottom: 10px">
              服务方式:第三方交易服务平台(即:为药品生产企业、药品经营企业和医疗机构之间的互联网药品交易提供的服务)
            </div>
            <div style="margin-bottom: 10px">
              特点:交易服务平台，只能做为药品生产企业、药品经营企业和医疗机构之间的平台服务商，不得向个人提供药品销售服务。
            </div>
          </div>
        </div>

        <div style="display: flex; margin-bottom: 45px; align-items: center">
          <div>
            <img
              style="width: 240px; height: 150px; margin-right: 25px"
              src="@/assets/img/zzbl/eighteen/3.png"
              alt=""
            />
          </div>
          <div
            style="
              font-family: Source Han Sans CN;
              font-weight: 400;
              font-size: 18px;
              color: #333333;
            "
          >
            <div style="margin-bottom: 10px">
              由地方局省局审批，全国范围135张(截止2016年4月);
            </div>
            <div style="margin-bottom: 10px">证书编号示例:川B2011001;</div>
            <div style="margin-bottom: 10px">
              服务方式:与其他企业进行药品交易(即:药品生产企业、药品批发企业通过自身网站与本企业成员之外的其他企业进行的互联网药品交易);
            </div>
            <div style="margin-bottom: 10px">
              特点:属于自有生产或经营企业向其它企业的批发交易证书;
            </div>
          </div>
        </div>

        <div style="display: flex; margin-bottom: 45px; align-items: center">
          <div>
            <img
              style="width: 240px; height: 150px; margin-right: 25px"
              src="@/assets/img/zzbl/eighteen/4.png"
              alt=""
            />
          </div>
          <div
            style="
              font-family: Source Han Sans CN;
              font-weight: 400;
              font-size: 18px;
              color: #333333;
            "
          >
            <div style="margin-bottom: 10px">
              由地方省局审批，全国范围456张(截止2016年4月);
            </div>
            <div style="margin-bottom: 10px">证书编号示例:川C20110001;</div>
            <div style="margin-bottom: 10px">
              服务方式:向个人消费者提供药品;
            </div>
            <div style="margin-bottom: 10px">特点:只能销售自营非处方药品。</div>
          </div>
        </div>

        <div
          style="
            font-family: Source Han Sans CN;
            font-weight: 400;
            font-size: 18px;
            color: #333333;
            margin-left: 45px;
          "
        >
          <div style="margin: 40px 0 20px">
            互联网药品交易服务机构的验收标准由国家食品药品监督管理局统一制定。
          </div>
          <div style="margin-bottom: 20px">
            互联网药品交易服务机构资格证书由国家食品药品监督管理局统一印制，有效期五年
          </div>
          <div style="margin-bottom: 20px; color: #EE3D33">
            2017年起，国务院取消互联网药品交易服务企业(第三方平台除外)电批，意味着
            B、C证取消电批,
          </div>
        </div>
      </div>

      <div style="width: 1400px; margin: 0 auto 50px; padding-bottom: 30px">
        <div style="margin: 50px 40px 40px">
          <div class="title" style="margin-bottom: 40px">
            互联网药品信息服务资格证服务项目
          </div>
          <div
            style="
              background-color: #fff;
              display: flex;
              justify-content: center;
            "
          >
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/2.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                申请
              </div>
            </div>
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/3.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                A证/B证/C证
              </div>
            </div>
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/4.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                续期
              </div>
            </div>
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/5.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                转让
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class="title" style="margin-bottom: 50px">
          互联网药品交易许可证申请办证流程图
        </div>
        <div><img src="@/assets/img/zzbl/eighteen/5.png" alt="" /></div>
      </div>
    </div>
    <bottomWiedt
      style="margin-top: 100px"
      :bottomTitle="bottomTitle"
    ></bottomWiedt>
  </div>
</template>
<script>
import middleWiedt from "./middleWiedt.vue";
import bottomWiedt from "./bottomWiedt.vue";

export default {
  components: {
    middleWiedt,
    bottomWiedt,
  },
  data() {
    return {
      middleImg: "17",
      middleList: [],
      middleTitle: "互联网药品交易许可证申请",
      middleContent:
        "互联网药品交易服务资格证书是由国家食品药品监督管理局给从事互联网药品交易服务的企业颁发的互联网药品交易服务机构资格证书，分为A、B、C三种。",
      bottomTitle: "创业前沿资讯",
    };
  },
};
</script>
<style scoped>
.xk_content .none {
  width: 135px;
  height: 135px;
}
.none {
  width: 100%;
  height: 260px;
}
.kp div {
  margin-right: 15px;
  flex: 1;
}
.title_middle {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  text-align: center;
}
.xk_t {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
.youjiantou {
  margin-top: 60px;
  height: 20px;
  width: 80px;
}
.xk_title {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  margin: 10px 0;
}
.xk_content {
  text-align: center;
  flex: 1;
}

.ICP_content {
  text-align: center;
  flex: 1;
  margin: 30px;
  border-right: 2px solid #ccc;
}
.title {
  text-align: center;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 30px;
  color: #333333;
}
</style>