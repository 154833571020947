import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    title: '打造前程集团',
    pageCount: 8,
    selected: 0,
    commonData: null,
  },
  mutations: {
    setCommonData(state, data) {
      state.commonData = data;
    },
  },
  actions: {
    fetchCommonData({ commit }) {
      if (JSON.parse(sessionStorage.getItem("myKey")) == null) {
        const url = "https://admin.atqf.cn/prod-api/atgw/gywm/list";
        const options = {
          method: "GET", // 请求方法
          headers: {
            "Content-Type": "application/json", // 设置请求头
          },
        };

        fetch(url, options)
          .then((response) => response.json())
          .then((data) => {
            commit('setCommonData', data.data[0]);
            let values = JSON.stringify(data.data[0])
            sessionStorage.setItem('myKey', values);
          })
          .catch((error) => console.error("Error:", error));
      } else {
        commit('setCommonData', JSON.parse(sessionStorage.getItem("myKey")));
      }

      // axios.get('/api/common-data')
      //   .then(response => {
      //     commit('setCommonData', response.data);
      //   })
      //   .catch(error => {
      //     console.error('Error fetching common data:', error);
      //   });
    },
  },
  getters: {
    getCommonData: state => state.commonData,
  },
  modules: {
  }
})
