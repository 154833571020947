<template>
  <div>
    <div style="width: 1400px; margin: 0 auto">
      <middleWiedt
        :middleImg="middleImg"
        :middleTitle="middleTitle"
        :middleContent="middleContent"
        :middleList="middleList"
      ></middleWiedt>

      <div style="background-color: #f4f4f4; padding: 0 20px">
        <div
          style="
            font-family: Source Han Sans CN;
            font-weight: bold;
            font-size: 24px;
            color: #333333;
            padding: 20px 0;
            border-bottom: 1px solid #D0D0D0;
            position: relative;
          "
        >
          <div class="title"></div>
          经营性网站备案申请流程
        </div>
        <div class="content">
          <div style="margin-top: 15px">(一)前期准备</div>
          <div style="margin-left: 30px">
            1、申请者向通信管理部门申领《ICP许可证》。
          </div>
          <div style="margin-left: 30px">
            2、申请者取得《ICP许可证》后，向工商行政管理机关申请增加“互联网信息服务”或“因特网信息服务”的经营范围。。
          </div>

          <div style="margin-top: 15px">(二)在线提交申请</div>
          <div style="margin-left: 30px">
            1、登录省工商行政管理局的网上工作平台，进入“网站备案”系统中的“备案申请”模块。
          </div>
          <div style="margin-left: 30px">
            2、在《经营性网站备案申请书》的栏目中，填写网站的名称、域名、IP地址、管理负责人、ISP提供商、服务器所在地地址、联系办法等相关内容。
          </div>
          <div style="margin-left: 30px">
            3、在线提交《经营性网站备案申请书》。
          </div>
          <div style="margin-left: 30px">4、打印《经营性网站备案申请书》。</div>
          <div style="margin-top: 15px">(三)准备书面材料</div>
          <div style="margin-left: 30px">
            1、加盖网站所有者公章的《经营性网站备案申请书》。
          </div>
          <div style="margin-left: 30px">
            2、加盖网站所有者公章的《企业法人营业执照》或《个体工商户营业执照》的复印件。
          </div>
          <div style="margin-left: 30px">
            如网站有共同所有者，应提交全部所有者《企业法人营业执照》或《个体工商户营业执照》的复印件。
          </div>
          <div style="margin-left: 30px">
            3、加盖域名所有者或域名管理机构、域名代理机构公章的《域名注册证》复印件，或其他对所提供域名享有权利的证明材料。
          </div>
          <div style="margin-left: 30px">
            4、加盖网站所有者公章的《ICP许可证》复印件及相关批准文件的复印件。
          </div>
          <div style="margin-left: 30px">
            5、对网站所有权有合同约定的，应当提交相应的证明材料，
          </div>
          <div style="margin-left: 30px">
            6、所提交的复印件或下载的材料，均应加盖申请者的公章。
          </div>
          <div style="margin-left: 30px"></div>

          <div style="margin-top: 15px">(四)送达</div>
          <div style="margin-left: 30px">
            1、将书面材料通过邮寄或当面方式送达至省工商行政管理局特殊交易监督管理处。
          </div>
          <div style="margin-left: 30px">
            以当面方式送达的，经办人应提交身份证复印件、网站所有者介绍信或法定代表人签署的授权委托书。
          </div>
          <div style="margin-left: 30px">
            2、书面材料应于完成在线申请程序后30日内提交。逾期提交视为未申请。
          </div>
          <div style="margin-left: 30px">
            3、申请者对所提交申请材料的真实、合法、有效性负责。
          </div>

          <div style="margin-top: 15px">(五)备案确认</div>
          <div style="margin-left: 30px">
            1、确认在线和书面申请材料的内容齐全、符合形式的，受理备案。
          </div>
          <div style="margin-left: 30px">
            2、申请材料存在瑕疵或备案网站名称存在事实或法律冲突的，终止备案申请，并将终止原因告知申请者。
          </div>
          <div style="margin-left: 30px">
            3、符合备案的申请，自受理申请5个工作日内，对该网站备案的主要内容予以公告，公告期为30日。
          </div>
          <div style="margin-left: 30px">
            4、公告期内任何单位和个人如对所公告的经营网站备案申请持有异议，均可向省工商行政管理局提出书面异议声明。
          </div>
          <div style="margin-left: 30px">
            (1)与主张权利人所设立的企业、个体工商户名称相同。
          </div>
          <div style="margin-left: 30px">
            (2)与主张权利人已办理备案的网站名称相同或近似，可能造成他人误认。
          </div>
          <div style="margin-left: 30px">
            (3)使用了主张权利人拥有的驰名商标、著名商标的文字部分(含中、英文及汉语拼音或其缩写)。
          </div>
          <div style="margin-left: 30px">
            (4)主张且有证据证明，申请备案的网站所提供的信息不真实。
          </div>
          <div style="margin-left: 30px">
            (5)主张且有证据证明，主张权利人对申请备案的网站拥有所有权。
          </div>
        </div>

        <div
          style="
            font-family: Source Han Sans CN;
            font-weight: bold;
            font-size: 24px;
            color: #333333;
            padding: 20px 0;
            border-bottom: 1px solid #D0D0D0;
            position: relative;
          "
        >
          <div class="title"></div>
          如何安装经营性网站备案电子标识
        </div>
        <div class="content">
          <div style="margin-top: 15px">
            网站所有者应于15日内将备案电子标识安装在网站首页的右下方，并将其链接到省工商行政管理局“经营性网站备案信息”数据库，以供公众查询。
          </div>
        </div>

        <div
          style="
            font-family: Source Han Sans CN;
            font-weight: bold;
            font-size: 24px;
            color: #333333;
            padding: 20px 0;
            border-bottom: 1px solid #D0D0D0;
            position: relative;
          "
        >
          <div class="title"></div>
          经营性网站备案注销
        </div>
        <div class="content" style="padding-bottom: 30px">
          <div style="margin-top: 15px">
            属于下列情况之一的，经营性网站所办理的备案自动注销。
          </div>
          <div style="margin-left: 30px">
            (一)备案得到确认后60日内，未在网站主页上加贴备案电子标识的。
          </div>
          <div style="margin-left: 30px">
            (二)网站所有者未通过企业年检被吊销的。
          </div>
          <div style="margin-left: 30px">
            (三)网站所有者注销或因其他原因被吊销的。
          </div>
          <div style="margin-left: 30px">
            (四)网站备案信息发生变化后，未能按期办理经营性网站备案变更手续的。
          </div>
          <div style="margin-left: 30px">
            (五)网站转让后未办理经营性网站备案转让手续的。
          </div>
          <div style="margin-left: 30px">
            (六)网站停止运营后30日内，未办理经营性网站备案取消手续的。
          </div>
        </div>
      </div>
    </div>
    <bottomWiedt
      :bottomTitle="bottomTitle"
      style="margin-top: 50px"
    ></bottomWiedt>
  </div>
</template>
<script>
import middleWiedt from "./middleWiedt.vue";
import bottomWiedt from "./bottomWiedt.vue";

export default {
  components: {
    middleWiedt,
    bottomWiedt,
  },
  data() {
    return {
      middleImg: "23",
      middleList: [
        {
          title: "经营性网站备案概述",
          content: [
            {
              one:
                "1、经营性网站备案又称红盾315备案，是指经营性网站向工商行政管理机关申请备案，工商行政管理机关在网站的首页上加贴经营性网站备案电子标识，并将备案信息向社会公开。",
              two:
                "2、经营性网站，是指企业和个体工商户为实现通过互联网发布信息、广告、设立电子信箱、开展商务活动以及向他人提供实施上述行为所需互联网空间等经营性目的，利用互联网技术建立的并拥有向域名管理机构申请的独立域名的电子平台。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "申请经营性网站备案的条件",
          content: [
            {
              one:
                "(一)网站的所有者拥有独立域名，或得到独立域名所有者的使用授权;",
              two:
                "(二)网站的所有者取得省电信管理机关颁发的《电信与信息服务业务经营许可证》(以下简称《ICP许可证》)。 网站有共同所有者的，全部所有者均应取得《ICP许可证》。",
              three:
                "(三)网站所有者的《企业法人营业执照》或《个体工商户营业执照》中核定有“互联网信息服务”或“因特网信息服务”经营范围。",
              four:
                "网站有共同所有者的，全部所有者的《企业法人营业执照》或《个体工商户营业执照》中均应核定有“互联网信息服务”或“因特网信息服务”经营范围。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
      ],
      middleTitle: "经营性网站备案",
      middleContent:
        "为了规范网站备案登记工作，规范网站的经营行为，保护消费者和网站所有者的合法权益，根据国家工商局的授权，省工商行政管理局在2000年8月15日制定了《网站名称注册管理暂行办法》、《经营性网站备案登记管理暂行办法》及其实施细则。",
      bottomTitle: "创业前沿资讯",
    };
  },
};
</script>
<style scoped>
.content_title {
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
}
.content {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 18px;
  color: #333333;
}
.content div {
  margin-bottom: 15px;
}
.title {
  height: 5px;
  width: 75px;
  background-color: #F55656;
  position: absolute;
  bottom: -3px;
}
.content_title {
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
}
.content {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 18px;
  color: #333333;
}
.content div {
  margin-bottom: 15px;
}
.title {
  height: 5px;
  width: 75px;
  background-color: #F55656;
  position: absolute;
  bottom: -3px;
}
.xk_content .none {
  width: 135px;
  height: 135px;
}
.none {
  width: 100%;
  height: 280px;
}
.kp div {
  margin-right: 15px;
  flex: 1;
}
.title_middle {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  text-align: center;
}
.xk_t {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
.youjiantou {
  margin-top: 60px;
  height: 20px;
  width: 80px;
}
.xk_title {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  margin: 10px 0;
}
.xk_content {
  text-align: center;
  flex: 1;
}

.ICP_content {
  text-align: center;
  flex: 1;
  margin: 30px;
  border-right: 2px solid #ccc;
}
.title {
  text-align: center;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 30px;
  color: #333333;
}
</style>