<template>
  <div>
    <!-- <div style="position: fixed; bottom: 0"> -->
    <div
      style="
        width: 100vw;
        height: 260px;
        margin: 0 auto;
        background-color: #151518;
      "
    >
      <div
        style="
          display: flex;
          align-items: center;
          width: 1600px;
          color: #fdfdfd;
          margin: 0 auto;
        "
      >
        <div style="flex: 1; margin-top: 40px; border-right: 1px solid #2e2f33">
          <div
            style="
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #FFFFFF;
              margin: 20px 60px 0 0;
              padding: 20px 0;
              border-bottom: 1px solid #2e2f33;
            "
          >
            政策法规
          </div>

          <div style="display: flex; margin-top: 15px">
            <div style="flex: 1">
              <div style="margin-bottom: 5px">首页</div>
              <div style="margin-bottom: 5px">工商财税</div>
              <div style="margin-bottom: 5px">资质办理</div>
            </div>
            <div style="flex: 1; margin-bottom: 8px">
              <div style="margin-bottom: 5px">知识产权</div>
              <div style="margin-bottom: 5px">知识课堂</div>
              <div style="margin-bottom: 5px">资质转让</div>
            </div>
            <div style="flex: 1; margin-bottom: 8px">
              <div style="margin-bottom: 5px">关于我们</div>
            </div>
          </div>
        </div>

        <div
          style="
            flex: 1;
            margin-top: 40px;
            border-right: 1px solid #2e2f33;
            padding-left: 40px;
          "
        >
          <div
            style="
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #FFFFFF;
              margin: 20px 60px 0 0;
              padding: 12px 0;
              border-bottom: 1px solid #2e2f33;
              display: flex;
              align-items: center;
            "
          >
            <span>联系我们</span>
            <span
              style="
                margin-left: auto;
                padding: 8px 15px;
                background-color: #e7242a;
                color: #fff;
                border-radius: 5px;
                cursor: pointer;
              "
              @click="dialogVisible = true"
              >在线客服</span
            >
          </div>
          <div style="margin-bottom: 5px; margin-top: 20px">
            <i class="el-icon-location"></i>
            地址：{{ obj.address }}
          </div>
          <div style="margin-bottom: 5px">
            <i class="el-icon-phone"></i>
            电话：{{ obj.phonenumber }}
          </div>
          <div style="margin-bottom: 5px">
            <i class="el-icon-message"></i>
            邮箱：{{ obj.email }}
          </div>
        </div>
        <div style="flex: 1; padding: 40px 80px 0">
          <div style="display: flex">
            <div style="flex: 1; text-align: center">
              <div><img :src="obj.swWx" alt="" /></div>
              <div style="margin-top: 10px">商务微信</div>
            </div>
            <div style="flex: 1; text-align: center; margin-right: 80px">
              <div><img :src="obj.qtWx" alt="" /></div>
              <div style="margin-top: 10px">其他微信</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      style="
        background-color: #0d0d0d;
        width: 100vw;
        text-align: center;
        color: #fff;
        padding: 15px 0;
      "
    >
      版权所有 爱听企服有限公司 闽ICP备2024060843号
    </div>

    <Dialog @Visible="Visible" :dialogVisible="dialogVisible"></Dialog>
  </div>
</template>
<script>
import Dialog from "@/components/dialog";
export default {
  components: {
    Dialog,
  },
  data() {
    return {
      obj: null,
      dialogVisible: false,
    };
  },
  created() {
    this.$store.dispatch("fetchCommonData");
    this.obj = this.$store.getters.getCommonData;
  },
  methods: {
    Visible() {
      this.dialogVisible = false;
    },
  },
};
</script>
<style scoped>
</style>