<template>
  <div style="position: fixed; top: 0; z-index: 999">
    <div class="wrap">
      <div
        style="
          width: 1400px;
          margin: 0 auto;
          height: 100%;
          display: flex;
          align-items: center;
          position: relative;
        "
      >
        <div
          @mousemove="zsktMousemove"
          @mouseout="zsktTouchend"
          v-if="isClassRoom == true"
          style="
            position: absolute;
            top: 68px;
            left: 695px;
            height: 150px;
            width: 120px;
            background-color: #fff;
            z-index: 9999999999999999999 !important;
          "
        >
          <div
            @click="changeClick(0)"
            class="zskt"
            :class="isNum == 0 ? 'isNumStyle' : ''"
          >
            政策法规
          </div>
          <div
            @click="changeClick(1)"
            class="zskt"
            style="
              border-top: 1px solid #7f8180;
              border-bottom: 1px solid #7f8180;
            "
            :class="isNum == 1 ? 'isNumStyle' : ''"
          >
            行业知识
          </div>
          <div
            @click="changeClick(2)"
            class="zskt"
            :class="isNum == 2 ? 'isNumStyle' : ''"
          >
            常见问题
          </div>
        </div>

        <!-- 关于我们 -->
        <div
          @mousemove="isUs = true"
          @mouseout="isUs = false"
          v-if="isUs == true"
          style="
            position: absolute;
            top: 68px;
            left: 820px;
            height: 100px;
            width: 120px;
            background-color: #fff;
            z-index: 9;
          "
        >
          <div
            @click="isUsClick(0)"
            class="zskt"
            :class="isUsNum == 0 ? 'isNumStyle' : ''"
          >
            企业介绍
          </div>
          <div
            @click="isUsClick(1)"
            class="zskt"
            style="
              border-top: 1px solid #7f8180;
              border-bottom: 1px solid #7f8180;
            "
            :class="isUsNum == 1 ? 'isNumStyle' : ''"
          >
            联系我们
          </div>
          <!-- <div
            @click="isUsClick(2)"
            class="zskt"
            :class="isUsNum == 2 ? 'isNumStyle' : ''"
          >
            帮助中心
          </div> -->
        </div>

        <div
          v-if="isPropertyRight == true"
          class="bg"
          @mousemove="zscqMousemove"
          @mouseout="zscqTouchend"
        >
          <div
            style="
              display: flex;
              width: 800px;
              margin: 0 auto;
              padding-top: 50px;
              text-align: left;
              font-family: Source Han Sans CN;
              font-weight: bold;
              font-size: 16px;
              color: #333333;
              cursor: pointer;
            "
          >
            <div
              style="flex: 1"
              :class="isNavActive == 0 ? 'NavActiveStyle' : ''"
              @click="navClick(0)"
            >
              商标注册 >
            </div>
            <div
              style="flex: 1"
              :class="isNavActive == 1 ? 'NavActiveStyle' : ''"
              @click="navClick(1)"
            >
              版权服务 >
            </div>
            <div
              style="flex: 1"
              :class="isNavActive == 2 ? 'NavActiveStyle' : ''"
              @click="navClick(2)"
            >
              专利服务 >
            </div>
            <div
              style="flex: 1"
              :class="isNavActive == 3 ? 'NavActiveStyle' : ''"
              @click="navClick(3)"
            >
              企业认证 >
            </div>
          </div>

          <div
            style="
              display: flex;
              width: 800px;
              margin: 0 auto;
              padding-top: 50px;
              text-align: left;
              cursor: pointer;
            "
          >
            <div style="flex: 1"></div>
            <div style="flex: 1; text-align: left">
              <div class="navContent" @click="navClick(1)">
                计算机软件著作权
              </div>
              <div class="navContent" @click="navClick(1)">
                计算机软件著作权加急
              </div>
              <div class="navContent" @click="navClick(1)">
                软件著作权软件定制开发
              </div>
              <div class="navContent" @click="navClick(4)">作品著作权登记</div>
            </div>
            <div style="flex: 1; text-align: left">
              <div class="navContent" @click="navClick(2)">发明专利</div>
              <div class="navContent" @click="navClick(2)">实用新型专利</div>
              <div class="navContent" @click="navClick(2)">外观设计专利</div>
              <div class="navContent" @click="navClick(2)">专利转让</div>
            </div>
            <div style="flex: 1"></div>
          </div>
        </div>

        <div
          v-if="iszzbl == true"
          class="bg"
          style="height: 680px"
          @mousemove="zzblMousemove"
          @mouseout="zzblTouchend"
        >
          <div
            style="
              display: flex;
              width: 1400px;
              margin: 0 auto;
              padding-top: 50px;
              text-align: left;
              font-family: Source Han Sans CN;
              font-weight: bold;
              font-size: 16px;
              color: #333333;
              cursor: pointer;
            "
          >
            <div
              style="flex: 1"
              :class="isNavActive == 0 ? 'NavActiveStyle' : ''"
              @click="zzblNavClick(0)"
            >
              增值电信业务经营许可证 >
            </div>
            <div
              style="flex: 1"
              :class="isNavActive == 1 ? 'NavActiveStyle' : ''"
              @click="zzblNavClick(28)"
            >
              网络文化/网络游戏 >
            </div>
            <div
              style="flex: 1"
              :class="isNavActive == 2 ? 'NavActiveStyle' : ''"
              @click="zzblNavClick(19)"
            >
              影视审批 >
            </div>
            <div
              style="flex: 1"
              :class="isNavActive == 3 ? 'NavActiveStyle' : ''"
              @click="zzblNavClick(13)"
            >
              互联网出版 >
            </div>
            <div
              style="flex: 1"
              :class="isNavActive == 3 ? 'NavActiveStyle' : ''"
              @click="zzblNavClick(16)"
            >
              互联网医药 >
            </div>
            <div
              style="flex: 1"
              :class="isNavActive == 3 ? 'NavActiveStyle' : ''"
              @click="zzblNavClick(21)"
            >
              网站备案 >
            </div>
          </div>

          <div
            style="
              display: flex;
              width: 1400px;
              margin: 0 auto;
              padding-top: 50px;
              text-align: left;
              cursor: pointer;
            "
          >
            <div style="flex: 1">
              <div class="navContent" @click="zzblNavClick(0)">
                互联网信息服务业务(ICP证)
              </div>
              <div class="navContent" @click="zzblNavClick(1)">
                移动网信息服务业务(SP证)
              </div>
              <div class="navContent" @click="zzblNavClick(2)">
                在线数据处理业务(EDI)
              </div>
              <div class="navContent" @click="zzblNavClick(3)">
                呼叫中心业务
              </div>
              <!-- <div class="navContent" @click="zzblNavClick(4)">
                互联网接入服务业务(ISP证)
              </div> -->
              <div class="navContent" @click="zzblNavClick(4)">
                数据中心业务(IDC证)
              </div>
              <div class="navContent" @click="zzblNavClick(5)">
                内容分发网络业务(CDN证)
              </div>
              <div class="navContent" @click="zzblNavClick(6)">
                国内多方通信服务业务
              </div>
              <div class="navContent" @click="zzblNavClick(7)">
                网络托管业务
              </div>
              <div class="navContent" @click="zzblNavClick(8)">
                存储转发业务
              </div>
              <div class="navContent" @click="zzblNavClick(9)">
                外商投资经营电信业务审批
              </div>
              <div class="navContent" @click="zzblNavClick(10)">95号码</div>
              <!-- <div class="navContent" @click="zzblNavClick(11)">96号码</div> -->
              <div class="navContent" @click="zzblNavClick(11)">1069码号</div>
              <div class="navContent" @click="zzblNavClick(12)">10698码号</div>
            </div>

            <div style="flex: 1">
              <div class="navContent" @click="zzblNavClick(28)">
                网络文化经营许可证
              </div>
              <div class="navContent" @click="zzblNavClick(29)">
                营业性演出许可证
              </div>
              <div class="navContent" @click="zzblNavClick(30)">
                艺术品经营单位备案
              </div>
              <div class="navContent" @click="zzblNavClick(31)">
                关术品进出口经营
              </div>
              <div class="navContent" @click="zzblNavClick(32)">游戏版号</div>
              <div class="navContent" @click="zzblNavClick(33)">游戏备案</div>
              <!-- <div class="navContent" @click="zzblNavClick(34)">活动审批</div> -->
            </div>

            <div style="flex: 1; text-align: left">
              <div class="navContent" @click="zzblNavClick(19)">
                广播电视节目许可证
              </div>
              <div class="navContent" @click="zzblNavClick(20)">
                营业性演出许可证
              </div>
            </div>
            <div style="flex: 1; text-align: left">
              <div class="navContent" @click="zzblNavClick(13)">
                网络出版许可证
              </div>
              <div class="navContent" @click="zzblNavClick(14)">
                出版物经营许可证
              </div>
              <div class="navContent" @click="zzblNavClick(15)">
                互联网新闻信息服务许可证
              </div>
            </div>
            <div style="flex: 1">
              <div class="navContent" @click="zzblNavClick(16)">
                互联网药品信息服务
              </div>
              <div class="navContent" @click="zzblNavClick(17)">
                互联网药品交易服务
              </div>
              <div class="navContent" @click="zzblNavClick(18)">
                医疗器械网络交易第三方备案
              </div>
            </div>
            <div style="flex: 1">
              <div class="navContent" @click="zzblNavClick(21)">
                ICP网站域名备案
              </div>
              <div class="navContent" @click="zzblNavClick(22)">公安网备</div>
              <div class="navContent" @click="zzblNavClick(23)">
                经营性网站备案
              </div>
              <div class="navContent" @click="zzblNavClick(24)">
                信息系统安全等级保护备案
              </div>
              <div class="navContent" @click="zzblNavClick(25)">
                商业特许经营备案
              </div>
              <div class="navContent" @click="zzblNavClick(26)">许可年检</div>
              <div class="navContent" @click="zzblNavClick(27)">可信网站</div>
            </div>
          </div>
        </div>

        <div style="width: 160px; margin-right: 70px">
          <img src="@/assets/img/nav/navlogos.png" alt="" />
        </div>

        <div style="display: flex; align-items: center" class="navStyle">
          <div
            @click="handleClick(0)"
            :class="isActive == 0 ? 'activeStyle' : 'noneStyle'"
          >
            首页
          </div>
          <div
            @click="handleClick(1)"
            :class="isActive == 1 ? 'activeStyle' : 'noneStyle'"
          >
            工商财税
          </div>
          <div
            @click="handleClick(2)"
            :class="isActive == 2 ? 'activeStyle' : 'noneStyle'"
            @mousemove="zzblMousemove"
            @mouseout="zzblTouchend"
          >
            资质办理
          </div>
          <div
            @click="handleClick(3)"
            :class="isActive == 3 ? 'activeStyle' : 'noneStyle'"
            @mousemove="zscqMousemove"
            @mouseout="zscqTouchend"
          >
            知识产权
          </div>
          <div
            @click="handleClick(4)"
            :class="isActive == 4 ? 'activeStyle' : 'noneStyle'"
            @mousemove="zsktMousemove"
            @mouseout="zsktTouchend"
          >
            知识课堂
          </div>
          <!-- <div
            @click="handleClick(5)"
            :class="isActive == 5 ? 'activeStyle' : ''"
          >
            资质转让
          </div> -->
          <div
            @click="handleClick(6)"
            :class="isActive == 6 ? 'activeStyle' : 'noneStyle'"
            @mousemove="isUs = true"
            @mouseout="isUs = false"
          >
            关于我们
          </div>
        </div>

        <div style="margin-left: auto">
          <img
            style="width: 28px; height: 25px"
            src="@/assets/img/nav/dianhua.png"
            alt=""
          />
        </div>
        <div
          style="
            font-family: Source Han Sans CN;
            font-weight: bold;
            font-size: 20px;
            color: #FF0000;
            margin-left: 8px;
            margin-right: 25px;
          "
        >
          400-0066-991
        </div>
        <div
          style="
            background-color: #ff2626;
            color: #fff;
            padding: 8px 25px;
            border-radius: 30px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 20px;
            cursor: pointer;
          "
          @click="goHerf"
        >
          控制台
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Nav",
  props: {
    internalActiveName: {
      type: String,
    },
  },
  data() {
    return {
      // activeName: "1",
      isActive: "0",
      isClassRoom: false,
      isNum: null,
      isUs: false,
      isUsNum: null,
      isPropertyRight: false,
      isNavActive: null,
      iszzblNavActive: null,
      iszzbl: false,
    };
  },
  watch: {
    $route: {
      // 值发生变化时的回调函数
      handler(now) {
        if (now.name == "index") {
          this.isActive = "0";
        } else if (now.name == "finance") {
          this.isActive = "1";
        } else if (now.name == "qualifications") {
          this.isActive = "2";
        } else if (now.name == "propertyRight") {
          this.isActive = "3";
        } else if (now.name == "classroom") {
          this.isActive = "4";
        } else if (now.name == "us") {
          this.isActive = "6";
        }
      },
      immediate: true, //立即执行
    },
  },
  methods: {
    goHerf() {
      window.location.href = "https://admin.atqf.cn";
    },
    zzblNavClick(val) {
      console.log(val, "vvvvvvvvvvvaal");
      this.iszzblNavActive = val;
      this.$router.push({ name: "qualifications", query: { id: val } });
      this.iszzbl = false;
    },
    navClick(val) {
      this.isNavActive = val;
      this.$router.push({ name: "propertyRight", params: { id: val } });
      this.isPropertyRight = false;
    },
    zsktTouchend() {
      this.isClassRoom = false;
    },
    zsktMousemove() {
      this.isClassRoom = true;
    },
    zzblMousemove() {
      this.iszzbl = true;
    },
    zzblTouchend() {
      this.iszzbl = false;
    },
    zscqMousemove() {
      this.isPropertyRight = true;
    },
    zscqTouchend() {
      this.isPropertyRight = false;
    },
    isUsClick(val) {
      this.isUsNum = val;
      this.$router.push("/us/" + 0);
    },
    changeClick(val) {
      this.isNum = val;
      this.$router.push("/classroom/" + 0);
    },
    handleClick(tab) {
      // let tab = val.index;
      this.isActive = tab;
      if (tab == 0) {
        this.$router.push("/");
      }
      if (tab == 1) {
        this.$router.push("/finance");
      }
      if (tab == 2) {
        this.$router.push({ name: "qualifications", query: { id: null } });
      }
      if (tab == 3) {
        this.$router.push("/propertyRight/" + 0);
      }

      if (tab == 4) {
        this.isClassRoom = true;
        // this.$router.push("/classroom");
        this.$router.push("/classroom/" + 0);
        this.isClassRoom = false;
      } else {
        this.isClassRoom = false;
      }

      if (tab == 6) {
        this.isUs = true;
        this.$router.push("/us/" + 0);
        this.isUs = false;
      } else {
        this.isUs = false;
      }
    },
  },
};
</script>
<style scoped>
.NavActiveStyle {
  color: #EE3D33;
}
.navContent {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  margin-bottom: 15px;
}
.bg {
  padding-top: 10px;
  position: absolute;
  top: 102px;
  left: -245px;
  height: 300px;
  width: 2050px;
  background-color: #fff;
  z-index: 9;
  text-align: center;
  background-image: url("~@/assets/img/propertyRight/22.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.activeStyle {
  color: #EC3437;
  border-bottom: 4px solid #EC3437 !important;
  padding: 35px 0;
}
.noneStyle{
  border-bottom: 4px solid #fff;
}
::v-deep .el-tabs__active-bar {
  height: 4px !important;
}
.navStyle {
  font-family: Source Han Sans CN !important;
  /* font-weight: bold !important; */
  font-size: 24px !important;
}
.navStyle div {
  margin: 0 15px;
  padding: 35px 0;
  cursor: pointer;
}
.navStyle div:hover {
  color: #EC3437 !important;
}
::v-deep .el-tabs__nav-wrap::after {
  display: none !important;
}
.isNumStyle {
  color: #EC3437 !important;
}
.zskt {
  cursor: pointer;
  text-align: center;
  line-height: 48px;
  height: 48px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 16px;
  color: #333333;
}
.noneActive {
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 20px;
  color: #000000;
  margin-right: 40px;
  cursor: pointer;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 3px solid #fff;
}
.wrap {
  position: relative;
  width: 100vw;
  height: 100px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
}

.wrap > .logo > img {
  position: absolute;
  left: 360px;
  top: 23px;
  width: 181px;
  height: 54px;
}

.nav {
  width: 556px;
  position: absolute;
  left: 1000px;
  top: -9px;
}

.nav ul {
  height: 100%;
}

.nav ul li {
  height: 46px;
  float: left;
  display: flex;
  list-style-type: none;
  color: rgba(68, 68, 68, 1);
  font-size: 16px;
  margin-right: 20px;
  line-height: 50%;
  cursor: pointer;
}

.nav ul li:hover {
  color: rgba(35, 51, 127, 1);
  border-bottom: 2px solid rgba(35, 51, 127, 1);
}
.dropbtn {
  background-color: #fff;
  color: rgba(68, 68, 68, 1);
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown {
  top: 30px;
  left: 120px;
  position: relative;
  display: inline-block;
}

.dropdown-content {
  z-index: 999;
  text-align: center;
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 95px;
  min-height: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}

.dropdown:hover .dropdown-content {
  display: block;
}
::v-deep.el-tabs__header {
  margin: 0 !important;
}
::v-deep .el-tabs__item.is-active {
  color: #ff2626 !important;
}
::v-deep .el-tabs__active-bar {
  background-color: #ff2626 !important;
}
::v-deep .el-tabs__item:hover {
  color: #ff2626 !important;
}
</style>
