<template>
  <div>
    <div style="width: 1400px; margin: 0 auto">
      <middleWiedt
        :middleImg="middleImg"
        :middleTitle="middleTitle"
        :middleContent="middleContent"
        :middleList="middleList"
      ></middleWiedt>

      <div style="background-color: #f4f4f4; width: 1400px; margin: 0 auto">
        <div style="margin-bottom: 100px; padding: 0 50px 50px">
          <div class="title" style="margin-bottom: 20px">
            办理SP许可证相关业务
          </div>
          <div
            style="
              font-family: Source Han Sans CN;
              font-weight: 400;
              font-size: 16px;
              color: #333333;
              margin-bottom: 45px;
              text-align: center;
            "
          >
            移动网增值服务许可证全套解决方案
          </div>
          <div style="display: flex">
            <div class="xk_content">
              <img class="none" src="@/assets/img/zzbl/one/6.png" alt="" />
              <div class="xk_title">SP许可证变更</div>
            </div>

            <div class="xk_content">
              <img class="none" src="@/assets/img/zzbl/one/7.png" alt="" />
              <div class="xk_title">SP许可证续期</div>
            </div>

            <div class="xk_content">
              <img class="none" src="@/assets/img/zzbl/one/8.png" alt="" />
              <div class="xk_title">SP许可证年检</div>
            </div>

            <div class="xk_content">
              <img class="none" src="@/assets/img/zzbl/one/9.png" alt="" />
              <div class="xk_title">SP许可证备案</div>
            </div>

            <div class="xk_content">
              <img class="none" src="@/assets/img/zzbl/one/10.png" alt="" />
              <div class="xk_title">SP许可证补办</div>
            </div>
          </div>
        </div>

        <div>
          <div class="title">SP许可证代理服务内容</div>
          <div class="title_middle" style="margin: 15px 0">
            全程无须您亲自到场/双向快递、极速拿照
          </div>
          <div
            style="
              font-family: Source Han Sans CN;
              font-weight: 400;
              font-size: 16px;
              color: #333333;
              text-align: center;
              margin-bottom: 20px;
            "
          >
            费用包含：SP证正本及附页；专业技术方案的提供、专业技术人员派遣、专业申请网站、难点技术攻关、后续业务指导v
          </div>
          <div style="text-align: center">
            <img class="none" src="@/assets/img/zzbl/two/4.png" alt="" />
          </div>
          <div
            style="
              font-family: Source Han Sans CN;
              font-weight: 400;
              font-size: 16px;
              color: #333333;
              text-align: center;
            "
          >
            <div style="margin: 20px 50px">SP许可证样本</div>
            <div>
              证书内容:名称、编号、导语、申办单位名称、法定代表人、注册资本、
            </div>
            <div style="margin: 10px 0; padding-bottom: 70px">
              注册住所、业务种类、业务覆盖范围、签发人、发证机关盖章、发证日期、有效期。
            </div>
          </div>
        </div>
      </div>

      <div style="margin-bottom: 90px">
        <div class="title" style="margin: 50px 0">
          办理SP许可证需要准备的材料
        </div>
        <div style="display: flex; align-items: center" class="kp">
          <div style="position: relative">
            <img class="none" src="@/assets/img/zzbl/two/5.png" alt="" />
            <div
              style="
                position: absolute;
                text-align: center;
                left: 15%;
                top: 25%;
              "
            >
              <div
                style="
                  width: 65px;
                  height: 65px;
                  line-height: 65px;
                  background: #F4F4F4;
                  border-radius: 50%;
                  border: 1px solid #EE3D33;
                  font-family: Alimama ShuHeiTi;
                  font-weight: bold;
                  font-size: 28px;
                  color: #EE3D33;
                  font-style: italic;
                  background-color: #fff;
                  margin: 0 auto;
                "
              >
                01
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin: 15px 0;
                "
              >
                企业营业执照副本复印件
              </div>
            </div>
          </div>
          <div style="position: relative">
            <img class="none" src="@/assets/img/zzbl/two/5.png" alt="" />
            <div
              style="
                position: absolute;
                text-align: center;
                left: 15%;
                top: 25%;
              "
            >
              <div
                style="
                  width: 65px;
                  height: 65px;
                  line-height: 65px;
                  background: #F4F4F4;
                  border-radius: 50%;
                  border: 1px solid #EE3D33;
                  font-family: Alimama ShuHeiTi;
                  font-weight: bold;
                  font-size: 28px;
                  color: #EE3D33;
                  font-style: italic;
                  background-color: #fff;
                  margin: 0 auto;
                "
              >
                02
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin: 15px 0;
                "
              >
                管理和技术人员身份证、学历证明彩色扫描件和复印件
              </div>
            </div>
          </div>
          <div style="position: relative">
            <img class="none" src="@/assets/img/zzbl/two/5.png" alt="" />
            <div
              style="
                position: absolute;
                text-align: center;
                left: 30%;
                top: 25%;
              "
            >
              <div
                style="
                  width: 65px;
                  height: 65px;
                  line-height: 65px;
                  background: #F4F4F4;
                  border-radius: 50%;
                  border: 1px solid #EE3D33;
                  font-family: Alimama ShuHeiTi;
                  font-weight: bold;
                  font-size: 28px;
                  color: #EE3D33;
                  font-style: italic;
                  background-color: #fff;
                  margin: 0 auto;
                "
              >
                03
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin: 15px 0;
                "
              >
                社保证明原件
              </div>
            </div>
          </div>
          <div style="position: relative">
            <img class="none" src="@/assets/img/zzbl/two/5.png" alt="" />
            <div
              style="
                position: absolute;
                text-align: center;
                left: 25%;
                top: 25%;
              "
            >
              <div
                style="
                  width: 65px;
                  height: 65px;
                  line-height: 65px;
                  background: #F4F4F4;
                  border-radius: 50%;
                  border: 1px solid #EE3D33;
                  font-family: Alimama ShuHeiTi;
                  font-weight: bold;
                  font-size: 28px;
                  color: #EE3D33;
                  font-style: italic;
                  background-color: #fff;
                  margin: 0 auto;
                "
              >
                04
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin: 15px 0;
                "
              >
                章程工商调档原件
              </div>
            </div>
          </div>
          <div style="position: relative">
            <img class="none" src="@/assets/img/zzbl/two/5.png" alt="" />
            <div
              style="
                position: absolute;
                text-align: center;
                left: 15%;
                top: 25%;
              "
            >
              <div
                style="
                  width: 65px;
                  height: 65px;
                  line-height: 65px;
                  background: #F4F4F4;
                  border-radius: 50%;
                  border: 1px solid #EE3D33;
                  font-family: Alimama ShuHeiTi;
                  font-weight: bold;
                  font-size: 28px;
                  color: #EE3D33;
                  font-style: italic;
                  background-color: #fff;
                  margin: 0 auto;
                "
              >
                05
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin: 15px 0;
                "
              >
                法人、股东为自然人，提交其身份证原件扫描件
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bottomWiedt style="margin-top: 50px;" :bottomTitle="bottomTitle"></bottomWiedt>
  </div>
</template>
<script>
import middleWiedt from "./middleWiedt.vue";
import bottomWiedt from "./bottomWiedt.vue";

export default {
  components: {
    middleWiedt,
    bottomWiedt,
  },
  data() {
    return {
      middleImg: "2",
      middleList: [
        {
          title: "SP经营许可证分类",
          content: [
            {
              one:
                "SP证分为全网SP证和地网SP证，所谓的全网SP证是经营全国范围SP业务资质",
              two:
                "地网SP证是经营公司所在省份的SP业务资质，这两者的区别就是经营范围的不同。;",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "2",
        },
        {
          title: "谁需要办理SP证!",
          content: [
            {
              one:
                "向用户提供服务、娱乐、游戏、短信、彩信，WAP、铃声下载，商业信息和定位信息等服务。信息服务业务面向的用户是固定通信网络用户、移动通信网络用户、因特网用户或其他数据传送网络的用户。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "SP证申请条件",
          content: [
            {
              one: "公司注册资金为全网1000万元及以上/地网100万元及以上。",
              two: "公司注册资金不要为技术入资，或其它入资形式",
              three: "公司的经营地址不得与注册地址不同",
              four: "有与开展经营活动相适应的资金和专业人员",
              five: "有为用户提供长期服务的信誉或者能力",
              six: "有可行性研究报告和相关技术方案",
            },
          ],
          remerk: "",
          icon: true,
          img: "",
          iconImg: "",
        },
      ],
      middleTitle: "SP经营许可证分类",
      middleContent:
        "建立与移动网络直接连接的服务平台，为移动网增值电信业务的业务专项经营者提供接入移动网络的服务，定位服务等各种增值服务的业务。",
      bottomTitle: "创业前沿资讯",
    };
  },
};
</script>
<style scoped>
.kp div {
  margin-right: 15px;
  flex: 1;
}
.title_middle {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  text-align: center;
}
.xk_t {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
.youjiantou {
  margin-top: 60px;
  height: 20px;
  width: 80px;
}
.xk_title {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  margin: 10px 0;
}
.xk_content {
  text-align: center;
  flex: 1;
}
.xk_content .none {
  width: 135px;
  height: 135px;
}
.ICP_content {
  text-align: center;
  flex: 1;
  margin: 30px;
  border-right: 2px solid #ccc;
}
.title {
  text-align: center;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 30px;
  color: #333333;
}
</style>