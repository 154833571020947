<template>
  <div style="position: relative; margin-top: 120px">
    <!-- <div @click="anniu" style="margin-top: 80px; background-color: pink">
      跳转
    </div> -->
    <!-- 右侧快捷方式 -->
    <el-card
      v-if="kj_active == 0"
      style="
        background-color: #fff;
        width: 200px;
        height: 70px;
        position: fixed;
        right: 140px;
        z-index: 9999999;
        top: 430px;
        padding-top: 10px;
        text-align: center;
      "
    >
      <span
        style="
          font-family: Source Han Sans CN;
          font-weight: bold;
          font-size: 16px;
          color: #e43743;
        "
      >
        400-0066-991
      </span>
    </el-card>

    <el-card
      v-if="kj_active == 1"
      style="
        background-color: #fff;
        width: 260px;
        height: 300px;
        position: fixed;
        right: 140px;
        z-index: 9999999;
        top: 530px;
        text-align: center;
      "
    >
      <div style="font-size: 16px">
        请使用微信扫码关注公众号后进行人工客服在线咨询
      </div>
      <img
        style="height: 230px; width: 230px"
        src="@/assets/img/home/zx.jpg"
        alt=""
      />
    </el-card>

    <el-card
      v-if="kj_active == 2"
      style="
        background-color: #fff;
        width: 300px;
        height: 180px;
        position: fixed;
        right: 140px;
        z-index: 9999999;
        top: 630px;
        padding-top: 10px;
        text-align: center;
      "
    >
      <div style="display: flex">
        <div style="flex: 1; text-align: center">
          <div><img src="@/assets/img/nav/swwx.png" alt="" /></div>
          <div style="margin-top: 10px; font-size: 16px">商务微信</div>
        </div>
        <div style="flex: 1; text-align: center; margin-left: 20px">
          <div><img src="@/assets/img/nav/wx.png" alt="" /></div>
          <div style="margin-top: 10px; font-size: 16px">其他微信</div>
        </div>
      </div>
    </el-card>

    <div style="position: fixed; right: 0; top: 400px; z-index: 9">
      <el-card style="height: 430px; width: 120px">
        <div
          style="
            height: 100px;
            width: 100%;
            border-bottom: 1px solid #dddddd;
            text-align: center;
            position: relative;
            cursor: pointer;
          "
          @mouseout="mouseout"
          @mouseover="kjClick(0)"
        >
          <div style="padding-top: 20px">
            <div style="position: relative">
              <img
                style="height: 40px; width: 40px"
                src="@/assets/img/home/dianhua.png"
                alt=""
              />
              <div class="kj_title">电话</div>
            </div>
          </div>
        </div>

        <div
          style="
            height: 100px;
            width: 100%;
            border-bottom: 1px solid #dddddd;
            text-align: center;
            position: relative;
            cursor: pointer;
          "
          @mouseout="mouseout"
          @mouseover="kjClick(1)"
        >
          <div style="padding-top: 20px">
            <img
              style="height: 40px; width: 40px"
              src="@/assets/img/home/kj_2.png"
              alt=""
            />
            <div class="kj_title">咨询</div>
          </div>
        </div>

        <div
          style="
            height: 100px;
            width: 100%;
            border-bottom: 1px solid #dddddd;
            text-align: center;
          "
          @mouseout="mouseout"
          @mouseover="kjClick(2)"
        >
          <div style="padding-top: 20px">
            <img
              style="height: 40px; width: 40px"
              src="@/assets/img/home/kj_3.png"
              alt=""
            />
            <div class="kj_title">微信</div>
          </div>
        </div>
        <div
          style="
            height: 100px;
            width: 100%;
            border-bottom: 1px solid #dddddd;
            text-align: center;
          "
          @click="scrollToTop"
        >
          <div style="padding-top: 20px">
            <img
              style="height: 40px; width: 40px"
              src="@/assets/img/home/kj_4.png"
              alt=""
            />
            <div class="kj_title">置顶</div>
          </div>
        </div>
      </el-card>
    </div>

    <Nav :internalActiveName="internalActiveName"></Nav>
    <div style="width: 2000px; margin: 0 auto">
      <div>
        <el-carousel style="height: 580px; width: 2000px">
          <el-carousel-item v-for="(item, index) in bannerList" :key="index">
            <img style="height: 580px; width: 100%" :src="item.img" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>

      <div
        style="
          text-align: center;
          font-family: SourceHanSansCN-Bold;
          margin: 70px 0 10px 0;
          font-size: 37px;
          font-weight: bold;
        "
      >
        产品与服务
      </div>

      <div style="display: flex; align-items: center; justify-content: center">
        <div style="height: 2px; background-color: #ec3639; width: 50px"></div>
        <div
          style="
            margin: 0 30px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #ADADAD;
            font-size: 32px;
          "
        >
          PRODUCT AND SERVICE
        </div>
        <div style="height: 2px; background-color: #ec3639; width: 50px"></div>
      </div>

      <div
        style="
          margin: 10px 0 50px 0;
          text-align: center;
          font-family: Source Han Sans CN;
          font-weight: bold;
          font-size: 16px;
          color: #ADADAD;
        "
      >
        您只需要提出需求，其他的一切放心交给我们
      </div>

      <div style="display: flex; align-items: center">
        <div style="flex: 2" class="c_bg">
          <div style="flex: 1" class="c_sCon">
            <div v-if="isActiveNum == 0" class="isActiveStyle">
              <div style="position: relative">
                <img
                  style="height: 185px; width: 600px; opacity: 0.8"
                  src="@/assets/img/home/c_2.png"
                  alt=""
                />
                <div style="position: absolute; top: 20px; left: 265px">
                  <div class="c_name" style="color: #fff">公司注册</div>
                  <div class="c_title" style="color: #fff">让创业更简单</div>
                </div>
                <div
                  style="
                    position: absolute;
                    bottom: 20px;
                    left: 265px;
                    color: #fff;
                    font-family: Source Han Sans CN;
                    font-size: 16px;
                  "
                >
                  查看详情
                  <i class="el-icon-arrow-right"></i>
                </div>
              </div>
            </div>
            <div @mousemove="changeContent(0)">
              <div class="c_name">公司注册</div>
              <div class="c_title">让创业更简单</div>
            </div>
          </div>
          <div style="flex: 1" class="c_sCon">
            <div v-if="isActiveNum == 1" class="isActiveStyle">
              <div style="position: relative">
                <img
                  style="height: 185px; width: 600px; opacity: 0.8"
                  src="@/assets/img/home/c_2.png"
                  alt=""
                />
                <div style="position: absolute; top: 20px; left: 265px">
                  <div class="c_name" style="color: #fff">资质办理</div>
                  <div class="c_title" style="color: #fff">让服务更简单</div>
                </div>
                <div
                  style="
                    position: absolute;
                    bottom: 20px;
                    left: 265px;
                    color: #fff;
                    font-family: Source Han Sans CN;
                    font-size: 16px;
                  "
                >
                  查看详情
                  <i class="el-icon-arrow-right"></i>
                </div>
              </div>
            </div>
            <div @mousemove="changeContent(1)">
              <div class="c_name">资质办理</div>
              <div class="c_title">让服务更简单</div>
            </div>
          </div>
          <div style="flex: 1" class="c_sCon">
            <div v-if="isActiveNum == 2" class="isActiveStyle">
              <div style="position: relative">
                <img
                  style="height: 185px; width: 600px; opacity: 0.8"
                  src="@/assets/img/home/c_2.png"
                  alt=""
                />
                <div style="position: absolute; top: 20px; left: 265px">
                  <div class="c_name" style="color: #fff">行业审批</div>
                  <div class="c_title" style="color: #fff">让服务更简单</div>
                </div>
                <div
                  style="
                    position: absolute;
                    bottom: 20px;
                    left: 265px;
                    color: #fff;
                    font-family: Source Han Sans CN;
                    font-size: 16px;
                  "
                >
                  查看详情
                  <i class="el-icon-arrow-right"></i>
                </div>
              </div>
            </div>
            <div @mousemove="changeContent(2)">
              <div class="c_name">行业审批</div>
              <div class="c_title">让服务更简单</div>
            </div>
          </div>
          <div style="flex: 1" class="c_sCon">
            <div v-if="isActiveNum == 3" class="isActiveStyle">
              <div style="position: relative">
                <img
                  style="height: 185px; width: 600px; opacity: 0.8"
                  src="@/assets/img/home/c_2.png"
                  alt=""
                />
                <div style="position: absolute; top: 20px; left: 265px">
                  <div class="c_name" style="color: #fff">知识产权</div>
                  <div class="c_title" style="color: #fff">
                    保障您的合法权益
                  </div>
                </div>
                <div
                  style="
                    position: absolute;
                    bottom: 20px;
                    left: 265px;
                    color: #fff;
                    font-family: Source Han Sans CN;
                    font-size: 16px;
                  "
                >
                  查看详情
                  <i class="el-icon-arrow-right"></i>
                </div>
              </div>
            </div>
            <div @mousemove="changeContent(3)">
              <div class="c_name">知识产权</div>
              <div class="c_title">保障您的合法权益</div>
            </div>
          </div>
        </div>
        <div style="flex: 9">
          <div
            v-if="isActiveNum == 0"
            style="background-color: #f5f5f5; height: 700px; padding: 20px 10px"
          >
            <div style="position: relative" @click="kjdl()">
              <img
                style="width: 100%; height: 220px"
                src="@/assets/img/home/c_3.png"
                alt=""
              />
              <div style="position: absolute; left: 40px; top: 30px">
                <img src="@/assets/img/home/c_4.png" alt="" />
                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    font-size: 24px;
                    color: #333333;
                  "
                >
                  公司注册
                </div>
                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 18px;
                    color: #666666;
                    margin-top: 5px;
                  "
                >
                  注册公司+注册地址+三证五章全套
                </div>
              </div>

              <div
                style="
                  position: absolute;
                  right: 20px;
                  bottom: 30px;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  cursor: pointer;
                "
              >
                查看详情
                <i style="font-size: 18px" class="el-icon-arrow-right"></i>
              </div>
            </div>

            <div
              style="
                display: flex;
                align-items: center;
                height: 220px;
                margin-top: 10px;
              "
            >
              <div
                style="
                  flex: 1;
                  background-color: #fff;
                  width: 100%;
                  height: 100%;
                  margin-right: 10px;
                "
              >
                <div
                  style="cursor: pointer"
                  @mousemove="changeContentVal(0)"
                  @click="kjdl()"
                  :class="isNine == 0 ? 'c_9style' : ''"
                >
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      padding: 10% 30px;
                    "
                  >
                    <div style="margin-right: 10px">
                      <img src="@/assets/img/home/c_5.png" alt="" />
                    </div>
                    <div>
                      <div
                        :class="isNine == 0 ? 'c_9styleSize' : 'gsStyle'"
                        style="
                          font-family: Source Han Sans CN;
                          font-weight: bold;
                          font-size: 24px;
                        "
                      >
                        公司起名/核名
                      </div>
                      <div
                        :class="isNine == 0 ? 'c_9styleSize' : 'mzStyle'"
                        style="
                          font-family: Source Han Sans CN;
                          font-weight: 400;
                          font-size: 16px;
                          margin-top: 10px;
                        "
                      >
                        给公司起个名字
                      </div>
                    </div>
                    <div style="margin-left: auto">
                      <i
                        style="font-size: 20px"
                        class="el-icon-arrow-right"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style="
                  flex: 1;
                  background-color: #fff;
                  width: 100%;
                  height: 100%;
                  margin-right: 10px;
                "
              >
                <div
                  style="cursor: pointer"
                  @mousemove="changeContentVal(1)"
                  @click="kjdl()"
                  :class="isNine == 1 ? 'c_9style' : ''"
                >
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      padding: 10% 30px;
                    "
                  >
                    <div style="margin-right: 10px">
                      <img src="@/assets/img/home/c_6.png" alt="" />
                    </div>
                    <div>
                      <div
                        :class="isNine == 1 ? 'c_9styleSize' : 'gsStyle'"
                        style="
                          font-family: Source Han Sans CN;
                          font-weight: bold;
                          font-size: 24px;
                        "
                      >
                        会计代理
                      </div>
                      <div
                        :class="isNine == 1 ? 'c_9styleSize' : 'mzStyle'"
                        style="
                          font-family: Source Han Sans CN;
                          font-weight: 400;
                          font-size: 16px;
                          margin-top: 10px;
                        "
                      >
                        我要代理记账￥200/月
                      </div>
                    </div>
                    <div style="margin-left: auto">
                      <i
                        style="font-size: 20px"
                        class="el-icon-arrow-right"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                height: 220px;
                margin-top: 10px;
              "
            >
              <div
                style="
                  flex: 1;
                  background-color: #fff;
                  width: 100%;
                  height: 100%;
                  margin-right: 10px;
                "
              >
                <div
                  style="cursor: pointer"
                  @mousemove="changeContentVal(2)"
                  @click="kjdl()"
                  :class="isNine == 2 ? 'c_9style' : ''"
                >
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      padding: 10% 30px;
                    "
                  >
                    <div style="margin-right: 10px">
                      <img src="@/assets/img/home/c_7.png" alt="" />
                    </div>
                    <div>
                      <div
                        :class="isNine == 2 ? 'c_9styleSize' : 'gsStyle'"
                        style="
                          font-family: Source Han Sans CN;
                          font-weight: bold;
                          font-size: 24px;
                        "
                      >
                        公司变更
                      </div>
                      <div
                        :class="isNine == 2 ? 'c_9styleSize' : 'mzStyle'"
                        style="
                          font-family: Source Han Sans CN;
                          font-weight: 400;
                          font-size: 16px;
                          margin-top: 10px;
                        "
                      >
                        变更公司信息￥700/起
                      </div>
                    </div>
                    <div style="margin-left: auto">
                      <i
                        style="font-size: 20px"
                        class="el-icon-arrow-right"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style="
                  flex: 1;
                  background-color: #fff;
                  width: 100%;
                  height: 100%;
                  margin-right: 10px;
                "
              >
                <div
                  style="cursor: pointer"
                  @mousemove="changeContentVal(3)"
                  @click="kjdl()"
                  :class="isNine == 3 ? 'c_9style' : ''"
                >
                  <div
                    style="
                      display: flex;
                      align-items: center;
                      padding: 10% 30px;
                    "
                  >
                    <div style="margin-right: 10px">
                      <img src="@/assets/img/home/c_8.png" alt="" />
                    </div>
                    <div>
                      <div
                        :class="isNine == 3 ? 'c_9styleSize' : 'gsStyle'"
                        style="
                          font-family: Source Han Sans CN;
                          font-weight: bold;
                          font-size: 24px;
                        "
                      >
                        公司起名/核名
                      </div>
                      <div
                        :class="isNine == 3 ? 'c_9styleSize' : 'mzStyle'"
                        style="
                          font-family: Source Han Sans CN;
                          font-weight: 400;
                          font-size: 16px;
                          margin-top: 10px;
                        "
                      >
                        给公司做年报￥100
                      </div>
                    </div>
                    <div style="margin-left: auto">
                      <i
                        style="font-size: 20px"
                        class="el-icon-arrow-right"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="isActiveNum == 1"
            style="
              background-color: #f5f5f5;
              height: 700px;
              padding: 20px 10px;
              cursor: pointer;
            "
          >
            <div
              style="
                display: flex;
                align-items: center;
                height: 220px;
                margin-top: 10px;
              "
            >
              <div
                style="
                  flex: 1;
                  margin-right: 10px;
                  width: 100%;
                  height: 100%;
                  background-color: #fff;
                "
              >
                <div
                  style="display: flex; align-items: center; line-height: 240px"
                  @mousemove="changeContentVal(0)"
                  :class="isNine == 0 ? 'c_9style' : ''"
                >
                  <div
                    :class="isNine == 0 ? 'boxstyle' : 'c_9noneboxstyle'"
                    style="height: 10px; width: 10px; margin-left: 50px"
                  ></div>
                  <div
                    style="
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      font-size: 24px;
                      margin-left: 20px;
                    "
                    @click="zzdx()"
                  >
                    增值电信业务经营许可证
                  </div>

                  <div style="margin-left: auto; margin-right: 30px">
                    <i style="font-size: 20px" class="el-icon-arrow-right"></i>
                  </div>
                </div>
              </div>
              <div
                style="
                  flex: 1;
                  background-color: #fff;
                  width: 100%;
                  height: 100%;
                "
              >
                <div
                  style="
                    display: flex;
                    align-items: center;
                    line-height: 240px;
                    cursor: pointer;
                  "
                  @mousemove="changeContentVal(1)"
                  @click="yyxyc()"
                  :class="isNine == 1 ? 'c_9style' : ''"
                >
                  <div
                    :class="isNine == 1 ? 'boxstyle' : 'c_9noneboxstyle'"
                    style="height: 10px; width: 10px; margin-left: 50px"
                  ></div>
                  <div
                    style="
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      font-size: 24px;
                      margin-left: 20px;
                    "
                  >
                    营业性演出许可证
                  </div>

                  <div style="margin-left: auto; margin-right: 30px">
                    <i style="font-size: 20px" class="el-icon-arrow-right"></i>
                  </div>
                </div>
              </div>
            </div>

            <div
              style="
                display: flex;
                align-items: center;
                height: 220px;
                margin-top: 10px;
              "
            >
              <div
                style="
                  flex: 1;
                  width: 100%;
                  height: 100%;
                  margin-right: 10px;
                  background-color: #fff;
                "
              >
                <div
                  style="display: flex; align-items: center; line-height: 240px"
                  @mousemove="changeContentVal(2)"
                  :class="isNine == 2 ? 'c_9style' : ''"
                  @click="hlwcb()"
                >
                  <div
                    :class="isNine == 2 ? 'boxstyle' : 'c_9noneboxstyle'"
                    style="height: 10px; width: 10px; margin-left: 50px"
                  ></div>
                  <div
                    style="
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      font-size: 24px;
                      margin-left: 20px;
                    "
                  >
                    互联网出版
                  </div>

                  <div style="margin-left: auto; margin-right: 30px">
                    <i style="font-size: 20px" class="el-icon-arrow-right"></i>
                  </div>
                </div>
              </div>
              <div
                style="
                  flex: 1;
                  background-color: #fff;
                  width: 100%;
                  height: 100%;
                "
              >
                <div
                  style="display: flex; align-items: center; line-height: 240px"
                  @mousemove="changeContentVal(3)"
                  :class="isNine == 3 ? 'c_9style' : ''"
                  @click="hlwyy()"
                >
                  <div
                    :class="isNine == 3 ? 'boxstyle' : 'c_9noneboxstyle'"
                    style="height: 10px; width: 10px; margin-left: 50px"
                  ></div>
                  <div
                    style="
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      font-size: 24px;
                      margin-left: 20px;
                    "
                  >
                    互联网医药
                  </div>

                  <div style="margin-left: auto; margin-right: 30px">
                    <i style="font-size: 20px" class="el-icon-arrow-right"></i>
                  </div>
                </div>
              </div>
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                height: 220px;
                margin-top: 10px;
              "
            >
              <div
                style="
                  flex: 1;
                  margin-right: 10px;
                  width: 100%;
                  height: 100%;
                  background-color: #fff;
                "
              >
                <div
                  style="display: flex; align-items: center; line-height: 240px"
                  @mousemove="changeContentVal(4)"
                  :class="isNine == 4 ? 'c_9style' : ''"
                  @click="yssp()"
                >
                  <div
                    :class="isNine == 4 ? 'boxstyle' : 'c_9noneboxstyle'"
                    style="height: 10px; width: 10px; margin-left: 50px"
                  ></div>
                  <div
                    style="
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      font-size: 24px;
                      margin-left: 20px;
                    "
                  >
                    影视审批
                  </div>

                  <div style="margin-left: auto; margin-right: 30px">
                    <i style="font-size: 20px" class="el-icon-arrow-right"></i>
                  </div>
                </div>
              </div>
              <div
                style="
                  flex: 1;
                  background-color: #fff;
                  width: 100%;
                  height: 100%;
                "
              >
                <div
                  style="display: flex; align-items: center; line-height: 240px"
                  @mousemove="changeContentVal(5)"
                  @click="wlwh()"
                  :class="isNine == 5 ? 'c_9style' : ''"
                >
                  <div
                    :class="isNine == 5 ? 'boxstyle' : 'c_9noneboxstyle'"
                    style="height: 10px; width: 10px; margin-left: 50px"
                  ></div>
                  <div
                    style="
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      font-size: 24px;
                      margin-left: 20px;
                    "
                  >
                    网络文化/网络游戏/网站备案
                  </div>

                  <div style="margin-left: auto; margin-right: 30px">
                    <i style="font-size: 20px" class="el-icon-arrow-right"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="isActiveNum == 2"
            style="background-color: #f5f5f5; height: 700px; padding: 20px 10px"
          >
            <div
              style="
                display: flex;
                align-items: center;
                height: 220px;
                margin-top: 10px;
              "
            >
              <div
                style="
                  flex: 1;
                  margin-right: 10px;
                  width: 100%;
                  height: 100%;
                  background-color: #fff;
                "
              >
                <div
                  style="display: flex; align-items: center; line-height: 240px"
                  @mousemove="changeContentVal(0)"
                  @click="wlwh()"
                  :class="isNine == 0 ? 'c_9style' : ''"
                >
                  <div
                    :class="isNine == 0 ? 'boxstyle' : 'c_9noneboxstyle'"
                    style="height: 10px; width: 10px; margin-left: 50px"
                  ></div>
                  <div
                    style="
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      font-size: 24px;
                      margin-left: 20px;
                    "
                  >
                    网络文化经营许可证
                  </div>

                  <div style="margin-left: auto; margin-right: 30px">
                    <i style="font-size: 20px" class="el-icon-arrow-right"></i>
                  </div>
                </div>
              </div>
              <div
                style="
                  flex: 1;
                  background-color: #fff;
                  width: 100%;
                  height: 100%;
                "
              >
                <div
                  style="display: flex; align-items: center; line-height: 240px"
                  @mousemove="changeContentVal(1)"
                  @click="wlwh()"
                  :class="isNine == 1 ? 'c_9style' : ''"
                >
                  <div
                    :class="isNine == 1 ? 'boxstyle' : 'c_9noneboxstyle'"
                    style="height: 10px; width: 10px; margin-left: 50px"
                  ></div>
                  <div
                    style="
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      font-size: 24px;
                      margin-left: 20px;
                    "
                  >
                    虚拟货币交易与发行
                  </div>

                  <div style="margin-left: auto; margin-right: 30px">
                    <i style="font-size: 20px" class="el-icon-arrow-right"></i>
                  </div>
                </div>
              </div>
            </div>

            <div
              style="
                display: flex;
                align-items: center;
                height: 220px;
                margin-top: 10px;
              "
            >
              <div
                style="
                  flex: 1;
                  margin-right: 10px;
                  width: 100%;
                  height: 100%;
                  background-color: #fff;
                "
              >
                <div
                  style="display: flex; align-items: center; line-height: 240px"
                  @mousemove="changeContentVal(2)"
                  @click="yxbbh()"
                  :class="isNine == 2 ? 'c_9style' : ''"
                >
                  <div
                    :class="isNine == 2 ? 'boxstyle' : 'c_9noneboxstyle'"
                    style="height: 10px; width: 10px; margin-left: 50px"
                  ></div>
                  <div
                    style="
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      font-size: 24px;
                      margin-left: 20px;
                    "
                  >
                    游戏版号/游戏备案
                  </div>

                  <div style="margin-left: auto; margin-right: 30px">
                    <i style="font-size: 20px" class="el-icon-arrow-right"></i>
                  </div>
                </div>
              </div>
              <div
                style="
                  flex: 1;
                  background-color: #fff;
                  width: 100%;
                  height: 100%;
                "
              >
                <div
                  style="display: flex; align-items: center; line-height: 240px"
                  @mousemove="changeContentVal(3)"
                  @click="yxzzq()"
                  :class="isNine == 3 ? 'c_9style' : ''"
                >
                  <div
                    :class="isNine == 3 ? 'boxstyle' : 'c_9noneboxstyle'"
                    style="height: 10px; width: 10px; margin-left: 50px"
                  ></div>
                  <div
                    style="
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      font-size: 24px;
                      margin-left: 20px;
                    "
                  >
                    游戏著作权
                  </div>

                  <div style="margin-left: auto; margin-right: 30px">
                    <i style="font-size: 20px" class="el-icon-arrow-right"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="isActiveNum == 3"
            style="background-color: #f5f5f5; height: 700px; padding: 20px 10px"
          >
            <div
              style="
                display: flex;
                align-items: center;
                height: 220px;
                margin-top: 10px;
              "
            >
              <div
                style="
                  flex: 1;
                  margin-right: 10px;
                  width: 100%;
                  height: 100%;
                  background-color: #fff;
                "
              >
                <div
                  style="display: flex; align-items: center; line-height: 240px"
                  @mousemove="changeContentVal(0)"
                  @click="sbzc()"
                  :class="isNine == 0 ? 'c_9style' : ''"
                >
                  <div
                    :class="isNine == 0 ? 'boxstyle' : 'c_9noneboxstyle'"
                    style="height: 10px; width: 10px; margin-left: 50px"
                  ></div>
                  <div
                    style="
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      font-size: 24px;
                      margin-left: 20px;
                    "
                  >
                    商标注册
                  </div>

                  <div style="margin-left: auto; margin-right: 30px">
                    <i style="font-size: 20px" class="el-icon-arrow-right"></i>
                  </div>
                </div>
              </div>
              <div
                style="
                  flex: 1;
                  background-color: #fff;
                  width: 100%;
                  height: 100%;
                "
              >
                <div
                  style="display: flex; align-items: center; line-height: 240px"
                  @mousemove="changeContentVal(1)"
                  @click="yxzzq()"
                  :class="isNine == 1 ? 'c_9style' : ''"
                >
                  <div
                    :class="isNine == 1 ? 'boxstyle' : 'c_9noneboxstyle'"
                    style="height: 10px; width: 10px; margin-left: 50px"
                  ></div>
                  <div
                    style="
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      font-size: 24px;
                      margin-left: 20px;
                    "
                  >
                    计算机软件著作权
                  </div>

                  <div style="margin-left: auto; margin-right: 30px">
                    <i style="font-size: 20px" class="el-icon-arrow-right"></i>
                  </div>
                </div>
              </div>
            </div>

            <div
              style="
                display: flex;
                align-items: center;
                height: 220px;
                margin-top: 10px;
              "
            >
              <div
                style="
                  flex: 1;
                  margin-right: 10px;
                  width: 100%;
                  height: 100%;
                  background-color: #fff;
                "
              >
                <div
                  style="display: flex; align-items: center; line-height: 240px"
                  @mousemove="changeContentVal(2)"
                  @click="fmzl()"
                  :class="isNine == 2 ? 'c_9style' : ''"
                >
                  <div
                    :class="isNine == 2 ? 'boxstyle' : 'c_9noneboxstyle'"
                    style="height: 10px; width: 10px; margin-left: 50px"
                  ></div>
                  <div
                    style="
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      font-size: 24px;
                      margin-left: 20px;
                    "
                  >
                    发明专利
                  </div>

                  <div style="margin-left: auto; margin-right: 30px">
                    <i style="font-size: 20px" class="el-icon-arrow-right"></i>
                  </div>
                </div>
              </div>
              <div
                style="
                  flex: 1;
                  background-color: #fff;
                  width: 100%;
                  height: 100%;
                "
              >
                <div
                  style="display: flex; align-items: center; line-height: 240px"
                  @mousemove="changeContentVal(3)"
                  @click="zpdj()"
                  :class="isNine == 3 ? 'c_9style' : ''"
                >
                  <div
                    :class="isNine == 3 ? 'boxstyle' : 'c_9noneboxstyle'"
                    style="height: 10px; width: 10px; margin-left: 50px"
                  ></div>
                  <div
                    style="
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      font-size: 24px;
                      margin-left: 20px;
                    "
                  >
                    作品著作权登记
                  </div>

                  <div style="margin-left: auto; margin-right: 30px">
                    <i style="font-size: 20px" class="el-icon-arrow-right"></i>
                  </div>
                </div>
              </div>
            </div>
            <div
              style="
                display: flex;
                align-items: center;
                height: 220px;
                margin-top: 10px;
              "
            >
              <div
                style="
                  flex: 1;
                  width: 100%;
                  height: 100%;
                  background-color: #fff;
                "
              >
                <div
                  style="display: flex; align-items: center; line-height: 240px"
                  @mousemove="changeContentVal(4)"
                  @click="fmzl()"
                  :class="isNine == 4 ? 'c_9style' : ''"
                >
                  <div
                    :class="isNine == 4 ? 'boxstyle' : 'c_9noneboxstyle'"
                    style="height: 10px; width: 10px; margin-left: 50px"
                  ></div>
                  <div
                    style="
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      font-size: 24px;
                      margin-left: 20px;
                    "
                  >
                    专利转让
                  </div>

                  <div style="margin-left: auto; margin-right: 30px">
                    <i style="font-size: 20px" class="el-icon-arrow-right"></i>
                  </div>
                </div>
              </div>
              <div style="flex: 1"></div>
            </div>
          </div>
        </div>
      </div>

      <div
        style="
          background: linear-gradient(to right, #ffe9e9, #fff1f1, #fff5f5);
          margin-top: 70px;
          height: 800px;
          padding: 30px 0;
        "
      >
        <div
          style="
            text-align: center;
            font-family: SourceHanSansCN-Bold;
            margin: 30px 0 10px 0;
            font-size: 37px;
            font-weight: bold;
          "
        >
          实用工具
        </div>

        <div
          style="display: flex; align-items: center; justify-content: center"
        >
          <div
            style="height: 2px; background-color: #ec3639; width: 50px"
          ></div>
          <div
            style="
              margin: 0 30px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #ADADAD;
              font-size: 32px;
            "
          >
            PRACTICAL TOOLS
          </div>
          <div
            style="height: 2px; background-color: #ec3639; width: 50px"
          ></div>
        </div>

        <div
          style="
            margin: 10px 0 50px 0;
            text-align: center;
            font-family: Source Han Sans CN;
            font-weight: bold;
            font-size: 16px;
            color: #ADADAD;
          "
        >
          让创业更简单 让服务更专业
        </div>

        <div style="width: 1600px; margin: 0 auto">
          <div style="display: flex; align-items: center; height: 500px">
            <div style="flex: 1; margin-right: 10px">
              <div style="height: 240px">
                <img
                  style="width: 100%; height: 100%"
                  src="@/assets/img/home/c_10.png"
                  alt=""
                />
              </div>
              <div
                style="
                  height: 200px;
                  width: 100%;
                  border-bottom-left-radius: 30px;
                  border-bottom-right-radius: 30px;
                  background: linear-gradient(to right, #f3a15f, #e7252a);
                "
              >
                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    font-size: 24px;
                    color: #FFFFFF;
                    text-align: center;
                    padding: 55px 0 20px 0;
                  "
                >
                  公司注册
                </div>

                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 14px;
                    color: #FFFFFF;
                    text-align: center;
                    padding: 0 20px;
                  "
                >
                  注册公司+注册地址+三证五章全套
                </div>
              </div>
            </div>
            <div style="flex: 1; margin-right: 10px">
              <div style="height: 240px">
                <img
                  style="width: 100%; height: 100%"
                  src="@/assets/img/home/c_11.png"
                  alt=""
                />
              </div>
              <div
                style="
                  height: 200px;
                  width: 100%;
                  border-bottom-left-radius: 30px;
                  border-bottom-right-radius: 30px;
                  background: linear-gradient(to right, #f3a15f, #e7252a);
                "
              >
                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    font-size: 24px;
                    color: #FFFFFF;
                    text-align: center;
                    padding: 55px 0 20px 0;
                  "
                >
                  商标查询
                </div>

                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 14px;
                    color: #FFFFFF;
                    text-align: center;
                    padding: 0 20px;
                  "
                >
                  商标信息变更，商标使用，为你轻 松开启创业之路
                </div>
              </div>
            </div>
            <div style="flex: 1; margin-right: 10px">
              <div style="height: 240px">
                <img
                  style="width: 100%; height: 100%"
                  src="@/assets/img/home/c_12.png"
                  alt=""
                />
              </div>
              <div
                style="
                  height: 200px;
                  width: 100%;
                  border-bottom-left-radius: 30px;
                  border-bottom-right-radius: 30px;
                  background: linear-gradient(to right, #f3a15f, #e7252a);
                "
              >
                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    font-size: 24px;
                    color: #FFFFFF;
                    text-align: center;
                    padding: 55px 0 20px 0;
                  "
                >
                  软著查询
                </div>

                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 14px;
                    color: #FFFFFF;
                    text-align: center;
                    padding: 0 20px;
                  "
                >
                  更深入全面的了解企业，为企业之间 合作提供全力保障
                </div>
              </div>
            </div>
            <div style="flex: 1; margin-right: 10px">
              <div style="height: 240px">
                <img
                  style="width: 100%; height: 100%"
                  src="@/assets/img/home/c_13.png"
                  alt=""
                />
              </div>
              <div
                style="
                  height: 200px;
                  width: 100%;
                  border-bottom-left-radius: 30px;
                  border-bottom-right-radius: 30px;
                  background: linear-gradient(to right, #f3a15f, #e7252a);
                "
              >
                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    font-size: 24px;
                    color: #FFFFFF;
                    text-align: center;
                    padding: 55px 0 20px 0;
                  "
                >
                  增值许可证查询
                </div>

                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 14px;
                    color: #FFFFFF;
                    text-align: center;
                    padding: 0 20px;
                  "
                >
                  增值电信业务许可证获得 企业详情
                </div>
              </div>
            </div>
            <div style="flex: 1; margin-right: 10px">
              <div style="height: 240px">
                <img
                  style="width: 100%; height: 100%"
                  src="@/assets/img/home/c_14.png"
                  alt=""
                />
              </div>
              <div
                style="
                  height: 200px;
                  width: 100%;
                  border-bottom-left-radius: 30px;
                  border-bottom-right-radius: 30px;
                  background: linear-gradient(to right, #f3a15f, #e7252a);
                "
              >
                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    font-size: 24px;
                    color: #FFFFFF;
                    text-align: center;
                    padding: 55px 0 20px 0;
                  "
                >
                  营业性演出许可证
                </div>

                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 14px;
                    color: #FFFFFF;
                    text-align: center;
                    padding: 0 20px;
                  "
                >
                  经济机构、表演团体、经纪人挂靠 营业性演出许可证严审
                </div>
              </div>
            </div>
          </div>

          <div
            style="
              border: 2px solid #E7242A;
              width: 380px;
              text-align: center;
              padding: 8px 0;
              color: #E7242A;
              font-weight: bold;
              border-radius: 30px;
              font-size: 18px;
              margin: 30px auto 0;
              cursor: pointer;
            "
            @click="dialogVisible = true"
          >
            了解详情
          </div>
        </div>
      </div>

      <!-- 前沿咨询 -->
      <div
        style="
          background: #f6f6f6;
          margin-top: 70px;
          height: 800px;
          padding-bottom: 30px;
        "
      >
        <div style="background-color: #fff">
          <div
            style="
              text-align: center;
              font-family: SourceHanSansCN-Bold;
              margin: 30px 0 10px 0;
              font-size: 37px;
              font-weight: bold;
            "
          >
            前沿咨询
          </div>

          <div
            style="display: flex; align-items: center; justify-content: center"
          >
            <div
              style="height: 2px; background-color: #ec3639; width: 50px"
            ></div>
            <div
              style="
                margin: 0 30px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                color: #ADADAD;
                font-size: 32px;
              "
            >
              FRONTIER CONSULTING
            </div>
            <div
              style="height: 2px; background-color: #ec3639; width: 50px"
            ></div>
          </div>

          <div
            style="
              margin: 10px 0 30px 0;
              text-align: center;
              font-family: Source Han Sans CN;
              font-weight: bold;
              font-size: 16px;
              color: #ADADAD;
            "
          >
            让创业更简单 让服务更专业
          </div>

          <div style="width: 1600px; margin: 0 auto">
            <div style="display: flex; height: 80px; line-height: 80px">
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 24px;
                  color: #333333;
                  margin: 0 20px;
                  cursor: pointer;
                "
                @click="tabBtn(0)"
                :class="c_isActive == 0 ? 'c_isActiveStyle' : ''"
              >
                政策法规
              </div>

              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 24px;
                  color: #333333;
                  margin: 0 20px;
                  cursor: pointer;
                "
                @click="tabBtn(1)"
                :class="c_isActive == 1 ? 'c_isActiveStyle' : ''"
              >
                行业知识
              </div>
              <div
                style="
                  width: 400px;
                  background: linear-gradient(to right, #ee3d33, #ee3d33);
                  margin-left: auto;
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 24px;
                  color: #FFFFFF;
                  cursor: pointer;
                "
              >
                <div style="display: flex">
                  <span style="margin-left: 30px">常见问题</span>
                  <span
                    @click="moreClick()"
                    style="
                      margin-left: auto;
                      margin-right: 10px;
                      font-size: 18px;
                    "
                    >MORE
                    <i class="el-icon-right"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style="width: 1600px; margin: 0 auto; cursor: pointer">
          <div style="display: flex; height: 500px">
            <div>
              <div @click="goNext">
                <div
                  class="zc_Content"
                  :style="index == 0 ? 'margin-top: 30px' : ''"
                  v-for="(item, index) in list"
                  :key="index"
                >
                  <div>{{ item.title }}</div>
                  <span
                    >{{ item.createTime.slice(0, 4) }} /
                    {{ item.createTime.slice(5, 7) }} /
                    {{ item.createTime.slice(8, 10) }}</span
                  >
                </div>
              </div>
              <div class="zc_Content" @click="handleClick()">
                MORE
                <i
                  style="margin-left: 20px; font-weight: bold; font-size: 16px"
                  class="el-icon-right"
                ></i>
              </div>
            </div>
            <div style="margin-left: auto; width: 400px">
              <div>
                <img
                  style="width: 100%"
                  src="@/assets/img/home/c_15.png"
                  alt=""
                />
              </div>
              <div class="q_content">
                <div
                  style="
                    display: flex;
                    align-items: center;
                    margin: 10px 0 20px;
                  "
                >
                  <img
                    style="width: 30px; height: 30px"
                    src="@/assets/img/home/c_16.png"
                    alt=""
                  />
                  <span> 企业为什么要保护cn域名？</span>
                </div>
                <p style="display: flex">
                  <img
                    style="width: 30px; height: 30px"
                    src="@/assets/img/home/c_17.png"
                    alt=""
                  />
                  <span
                    >根据相关数据统计，cn域名注册量已经超过一千万个，甚至在某、一期间内，注册量竟然赶超com域名，一度成就了国内域名霸主地位。从数据可看出，越来越受到企业的重视。</span
                  >
                </p>
              </div>
              <div class="q_content">
                <div
                  style="
                    display: flex;
                    align-items: center;
                    margin: 10px 0 20px;
                  "
                >
                  <img
                    style="width: 30px; height: 30px"
                    src="@/assets/img/home/c_16.png"
                    alt=""
                  />
                  <span> 企业使用商标注意事项？</span>
                </div>
                <p style="display: flex">
                  <img
                    style="width: 30px; height: 30px"
                    src="@/assets/img/home/c_17.png"
                    alt=""
                  />
                  <span
                    >近年来，越来越多的企业开始使用商标，然而商标使用不当的现象也愈发突出，由于商标使用不当是会给企业造成损失的，因此有人会问企业应该如何使用商标?商标使用过程中需要注意</span
                  >
                </p>
              </div>

              <div class="q_content">
                <div
                  style="
                    display: flex;
                    align-items: center;
                    margin: 10px 0 20px;
                  "
                >
                  <img
                    style="width: 30px; height: 30px"
                    src="@/assets/img/home/c_16.png"
                    alt=""
                  />
                  <span> 企业为什么要保护cn域名？</span>
                </div>
                <p style="display: flex">
                  <img
                    style="width: 30px; height: 30px"
                    src="@/assets/img/home/c_17.png"
                    alt=""
                  />
                  <span
                    >根据相关数据统计，cn域名注册量已经超过一千万个，甚至在某、一期间内，注册量竟然赶超com域名，一度成就了国内域名霸主地位。从数据可看出，越来越受到企业的重视。</span
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 客户案例 -->
      <div style="margin-top: 70px; height: 620px; padding-bottom: 30px">
        <div style="background-color: #fff">
          <div
            style="
              text-align: center;
              font-family: SourceHanSansCN-Bold;
              margin: 30px 0 10px 0;
              font-size: 37px;
              font-weight: bold;
            "
          >
            客户案例
          </div>

          <div
            style="display: flex; align-items: center; justify-content: center"
          >
            <div
              style="height: 2px; background-color: #ec3639; width: 50px"
            ></div>
            <div
              style="
                margin: 0 30px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                color: #ADADAD;
                font-size: 32px;
              "
            >
              FRONTIER CONSULTING
            </div>
            <div
              style="height: 2px; background-color: #ec3639; width: 50px"
            ></div>
          </div>

          <div
            style="
              margin: 10px 0 30px 0;
              text-align: center;
              font-family: Source Han Sans CN;
              font-weight: bold;
              font-size: 16px;
              color: #ADADAD;
            "
          >
            您只需要提出需求，其他的一切放心交给我们
          </div>

          <div style="width: 1600px; margin: 0 auto; background-color: #fff">
            <div class="kh_content">
              <div>
                <img
                  style="height: 100%; width: 100%"
                  src="@/assets/img/home/k_01.png"
                  alt=""
                />
              </div>
              <div>
                <img
                  style="height: 100%; width: 100%"
                  src="@/assets/img/home/k_02.png"
                  alt=""
                />
              </div>
              <div>
                <img
                  style="height: 100%; width: 100%"
                  src="@/assets/img/home/k_03.png"
                  alt=""
                />
              </div>
              <div>
                <img
                  style="height: 100%; width: 100%"
                  src="@/assets/img/home/k_04.png"
                  alt=""
                />
              </div>
              <div>
                <img
                  style="height: 100%; width: 100%"
                  src="@/assets/img/home/k_05.png"
                  alt=""
                />
              </div>
            </div>
            <div class="kh_content">
              <div>
                <img
                  style="height: 100%; width: 100%"
                  src="@/assets/img/home/k_06.png"
                  alt=""
                />
              </div>
              <div>
                <img
                  style="height: 100%; width: 100%"
                  src="@/assets/img/home/k_07.png"
                  alt=""
                />
              </div>
              <div>
                <img
                  style="height: 100%; width: 100%"
                  src="@/assets/img/home/k_08.png"
                  alt=""
                />
              </div>
              <div>
                <img
                  style="height: 100%; width: 100%"
                  src="@/assets/img/home/k_09.png"
                  alt=""
                />
              </div>
              <div>
                <img
                  style="height: 100%; width: 100%"
                  src="@/assets/img/home/k_10.png"
                  alt=""
                />
              </div>
            </div>
            <div class="kh_content">
              <div>
                <img
                  style="height: 100%; width: 100%"
                  src="@/assets/img/home/k_11.png"
                  alt=""
                />
              </div>
              <div>
                <img
                  style="height: 100%; width: 100%"
                  src="@/assets/img/home/k_12.png"
                  alt=""
                />
              </div>
              <div>
                <img
                  style="height: 100%; width: 100%"
                  src="@/assets/img/home/k_13.png"
                  alt=""
                />
              </div>
              <div>
                <img
                  style="height: 100%; width: 100%"
                  src="@/assets/img/home/k_14.png"
                  alt=""
                />
              </div>
              <div>
                <img
                  style="height: 100%; width: 100%"
                  src="@/assets/img/home/k_15.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>

    <Dialog @Visible="Visible" :dialogVisible="dialogVisible"></Dialog>
  </div>
</template>
<script>
import Footer from "@/components/footer";
import Nav from "@/components/nav";
import Dialog from "@/components/dialog";
export default {
  components: {
    Footer,
    Nav,
    Dialog,
  },
  data() {
    return {
      bannerList: [],
      picList: [],
      isActiveNum: 0,
      c_isActive: 0,
      kj_active: null,
      scrollThreshold: 200,
      internalActiveName: "1",
      dialogVisible: false,
      isNine: 0,

      pageNum: 1,
      pageSize: 7,
      total: 0,
      list: [],
    };
  },
  created() {
    this.getBannerList();
    this.getPolicylist();
  },

  mounted() {
    // 在组件挂载后开始监听滚动事件
    window.addEventListener("scroll", this.handleScroll);
    // 解绑事件监听，以防内存泄漏（可选，根据实际需求选择是否解绑）
    // this.$once('hook:beforeDestroy', () => {
    //   window.removeEventListener('scroll', this.handleScroll);
    // });
  },
  methods: {
    kjdl() {
      this.$router.push("/finance");
    },
    moreClick() {
      this.$router.push("/classroom/" + 2);
    },
    zpdj() {
      this.$router.push({ name: "propertyRight", params: { id: 4 } });
    },
    fmzl() {
      this.$router.push({ name: "propertyRight", params: { id: 2 } });
    },
    sbzc() {
      this.$router.push({ name: "propertyRight", params: { id: 0 } });
    },
    yxzzq() {
      this.$router.push({ name: "propertyRight", params: { id: 1 } });
    },
    yxbbh() {
      this.$router.push({ name: "qualifications", query: { id: 32 } });
    },
    wlwh() {
      this.$router.push({ name: "qualifications", query: { id: 28 } });
    },
    yssp() {
      this.$router.push({ name: "qualifications", query: { id: 19 } });
    },
    hlwyy() {
      this.$router.push({ name: "qualifications", query: { id: 16 } });
    },
    hlwcb() {
      this.$router.push({ name: "qualifications", query: { id: 13 } });
    },
    yyxyc() {
      this.$router.push({ name: "qualifications", query: { id: 20 } });
    },
    zzdx() {
      this.$router.push({ name: "qualifications", query: { id: null } });
    },
    getPolicylist() {
      const url = `https://admin.atqf.cn/prod-api/atgw/zskt/list?pageNum=${this.pageNum}&pageSize=${this.pageSize}&type=${this.c_isActive}`;
      const options = {
        method: "GET", // 请求方法
        headers: {
          "Content-Type": "application/json", // 设置请求头
        },
      };

      fetch(url, options)
        .then((response) => response.json())
        .then((res) => {
          this.list = res.rows;
          this.total = res.total;
          console.log(this.list, "===========");
        })
        .catch((error) => console.error("Error:", error));
    },
    goNext() {
      this.$router.push("/newRead");
    },
    tabBtn(val) {
      this.c_isActive = val;
      this.getPolicylist();
    },
    Visible() {
      this.dialogVisible = false;
    },
    anniu() {
      this.$router.push({
        name: "mregister",
      });
    },
    handleClick() {
      this.$router.push("/classroom/" + 0);
    },
    // 获取banner
    getBannerList() {
      const url = "https://admin.atqf.cn/prod-api/atgw/banner/list";
      const options = {
        method: "GET", // 请求方法
        headers: {
          "Content-Type": "application/json", // 设置请求头
        },
      };

      fetch(url, options)
        .then((response) => response.json())
        .then((data) => {
          this.bannerList = data.rows;
        })
        .catch((error) => console.error("Error:", error));
    },
    scrollToTop() {
      // 使用window.scrollTo方法平滑滚动到页面顶部
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    handleScroll() {
      // 监听滚动事件，判断是否显示回到顶部按钮
      this.showBackToTop = window.scrollY > this.scrollThreshold;
    },
    mouseout() {
      this.kj_active = null;
    },
    kjClick(val) {
      this.kj_active = val;
    },
    changeContent(val) {
      this.isActiveNum = val;
    },
    changeContentVal(val) {
      this.isNine = val;
    },
  },
};
</script>
<style scoped>
.boxstyle {
  background-color: #fff;
}
.c_9noneboxstyle {
  background-color: #000;
}
.c_9nonestyle {
  color: #fff;
}
.mzStyle {
  color: #666;
}
.gsStyle {
  color: #333;
}
.kj_title {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 16px;
  color: #333333;
}
.kh_content {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.kh_content div {
  height: 130px;
  flex: 1;
  margin-right: 15px;
  border: 1px solid #989898;
}
.q_content {
  height: 130px;
  width: 100%;
  margin: 20px;
  border-bottom: 1px dashed #989898;
}
.q_content div span {
  margin-left: 5px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 16px;
  color: #333333;
}
.q_content p span {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
.zc_Content {
  width: 1100px;
  height: 65px;
  line-height: 65px;
  display: flex;
  align-items: center;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 16px;
  color: #333333;
}
.zc_Content span {
  margin-left: auto;
  font-size: 14px;
}
.c_isActiveStyle {
  border-bottom: 3px solid #E7242A;
}
.c_9style {
  border-radius: 5px;
  background-image: url("~@/assets/img/home/c_9.png");
  background-size: cover;
  width: 100%;
  height: 100%; /* 或具体像素值，如 500px */
  background-position: center center;
  background-repeat: no-repeat;
  color: #fff !important;
}
.c_9styleSize {
  color: #fff !important;
}
.isActiveStyle {
  position: absolute;
  right: -10px;
  top: -20px;
}
.c_sCon {
  cursor: pointer;
  position: relative;
}
.c_title {
  margin-top: 10px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 16px;
  color: #999999;
}
.c_name {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 24px;
  color: #333333;
}
.c_bg {
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  margin-left: 200px;
  margin-right: 20px;
  background-image: url("~@/assets/img/home/c_1.png");
  background-size: cover;
  width: 100%;
  height: 700px; /* 或具体像素值，如 500px */
  background-position: center center;
  background-repeat: no-repeat;
}
::v-deep .el-carousel__container {
  height: 400px !important;
}

::v-deep .el-carousel__item {
  height: 580px !important;
}
</style>