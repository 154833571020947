<template>
  <div>
    <div style="width: 1400px; margin: 0 auto">
      <middleWiedt
        :middleImg="middleImg"
        :middleTitle="middleTitle"
        :middleContent="middleContent"
        :middleList="middleList"
      ></middleWiedt>

      <div style="width: 1400px; margin: 0 auto 50px; padding-bottom: 30px">
        <div style="margin: 50px 40px 40px">
          <div class="title" style="margin-bottom: 40px">
            互联网药品信息服务资格证服务项目
          </div>
          <div
            style="
              background-color: #fff;
              display: flex;
              justify-content: center;
            "
          >
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/2.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                申请
              </div>
            </div>
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/3.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                变更/增项
              </div>
            </div>
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/4.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                续期/注销
              </div>
            </div>
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/5.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                转让
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="margin-bottom: 100px">
        <div class="title" style="margin-bottom: 50px">
          互联网药品信息服务资格证办理服务流程
        </div>
        <div style="display: flex">
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/6.png" alt="" />
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/7.png" alt="" />
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/8.png" alt="" />
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/9.png" alt="" />
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/10.png" alt="" />
          </div>
        </div>

        <div
          style="
            font-family: Source Han Sans CN;
            font-weight: 400;
            font-size: 18px;
            color: #333333;
          "
        >
          <div style="margin: 30px 0">
            1、申请人向省局政务受理部门提交申请材料。省局政务受理部门在5个工作日内进行形式审查，符合规定的，发给受理通知书，并将申请材料移交药品化妆品流通监管处;不符合规定的，发给不予受理通知书并一次性书面告知申请人所要补正的全部材料。
          </div>
          <div>
            2、食品药品监督管理局收到申请材料后，在20个工作日内进行审核、审批，批准的，制作《互联网药品信息服务资格证书》，移交省局政务受理部门;不予批准的，制作《不予行政许可决定书》，移交省局政务受理部门;
          </div>
          <div style="margin: 30px 0">
            3、省局政务受理部门收到材料后，在1个工作日内通知申请人，并发放《互联网药品信息服务资格证书》或《不子行政许可决定书》
          </div>
        </div>
      </div>
    </div>
    <bottomWiedt style="margin-top: 50px;" :bottomTitle="bottomTitle"></bottomWiedt>
  </div>
</template>
<script>
import middleWiedt from "./middleWiedt.vue";
import bottomWiedt from "./bottomWiedt.vue";

export default {
  components: {
    middleWiedt,
    bottomWiedt,
  },
  data() {
    return {
      middleImg: "16",
      middleList: [
        {
          title: "互联网信息服务许可证分类",
          content: [
            {
              one:
                "经营性互联网药品信息服务:通过互联网向上网用户有偿提供药品信息等服务的活动。",
              two:
                "非经营性互联网药品信息服务:通过互联网向上网用户无偿提供公开的、共享性药品信息等服务的活动。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "未申请互联网信息服务许可证申请开展业务有何处罚?",
          content: [
            {
              one:
                "未取得或者超出有效期使用《互联网药品信息服务资格证书》从事互联网药品信息服务的，由国家食品药品监督管理局或者省、自治区、直辖市(食品)药品监督管理部门给予警告，并责令其停止从事互联网药品信息服务;情节严重的，移送相关部门，依照有关法律、法规给予处罚。提供互联网药品信息服务的网站不在其网站主页的显著位置标注《互联网药品信息服务资格证书》的证书编号的，国家食品药品监督管理局或者省、自治区、直辖市(食品)药品监督管理部门给予警告，责令限期改正;在限定期限内拒不改正的，对提供非经营性互联网药品信息服务的网站处以500元以下罚款，对提供经营性互联网药品信息服务的网站处以500元以上1万元以下罚款。",
            },
          ],
          remerk: "",
          icon: false,
          img: "16",
          iconImg: "",
        },
        {
          title: "办理互联网药品信息服务资格证申请条件",
          content: [
            {
              one:
                "(一)互联网药品信息服务的提供者应当为依法设立的企事业单位或者其它组织",
              two:
                "(二)具有与开展互联网药品信息服务活动相适应的专业人员、设施及相关制度。",
              three:
                "(三)有两名以上熟悉药品、医疗器械管理法律、法规和药品、医疗器械专业知识，或者依法经资格认定的药学、医疗器械技术人员。",
              four:
                "(四)提供互联网药品信息服务的申请应当以一个网站为基本单元。",
              five:
                "(五)公司及其主要出资者和主要经营管理人员三年内无违反电信监督管理制度的违法记录。",
              six: "(六)有为用户提供长期服务的信誉或者能力。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "办理互联网药品信息服务资格证需要的材料",
          content: [
            {
              one: "(一)企业营业执照复印件。",
              two: "(二)网站域名证书。",
              three: "(三)两名药品及医疗器械相关专业技术人。",
              four:
                "(四)至少5名专业编辑人员(含2名或2名以上中级编辑)专业职称证明和身份证明复印件。",
              five: "(五)网站主服务器所在地地址。",
              six: "(六)单位联系人联系方式。",
              seven: "(七)法人配合签字、配合盖公章。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
      ],
      middleTitle: "互联网药品信息服务许可证申请",
      middleContent:
        "互联网药品信息服务资格证书，是指通过互联网向上网用户提供药品(含医疗器械)信息服务活动的一种资质。互联网药品信息服务分为经营性和非经营性两类：经营性互联网药品信息服务是指通过互联网向上网用户有偿提供药品信息等服务的活动；非经营性互联网药品信息服务是指通过互联网向上网用户无偿提供公开的、共享性药品信息等服务的活动。",
      bottomTitle: "创业前沿资讯",
    };
  },
};
</script>
<style scoped>
.xk_content .none {
  width: 135px;
  height: 135px;
}
.none {
  width: 100%;
  height: 260px;
}
.kp div {
  margin-right: 15px;
  flex: 1;
}
.title_middle {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  text-align: center;
}
.xk_t {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
.youjiantou {
  margin-top: 60px;
  height: 20px;
  width: 80px;
}
.xk_title {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  margin: 10px 0;
}
.xk_content {
  text-align: center;
  flex: 1;
}

.ICP_content {
  text-align: center;
  flex: 1;
  margin: 30px;
  border-right: 2px solid #ccc;
}
.title {
  text-align: center;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 30px;
  color: #333333;
}
</style>