<template>
  <div style="width: 1630px; margin: 0 auto; min-height: 700px">
    <div
      style="
        font-family: Source Han Sans CN;
        font-weight: bold;
        font-size: 30px;
        color: #333333;
        text-align: center;
        margin-bottom: 70px;
      "
    >
      {{ bottomTitle }}
    </div>

    <div style="display: flex; justify-content: center">
      <div
        class="bottomStyle"
        v-for="(item, index) in list"
        :key="index"
        @click="nextContent(item)"
      >
        <div
          style="
            font-family: Source Han Sans CN;
            font-weight: bold;
            font-size: 16px;
            color: #333333;
          "
        >
          {{ item.title }}
        </div>
        <div
          style="
            font-family: Source Han Sans CN;
            font-weight: 400;
            font-size: 14px;
            color: #666666;
            margin: 10px 0 50px;
          "
          v-html="item.content"
        ></div>

        <div style="width: 292px;height: 1px;background: #E3E3E3;margin: 0 auto 30px;"></div>

        <div
          style="
            display: flex;
            align-items: center;
            font-family: Source Han Sans CN;
            font-weight: 400;
            font-size: 14px;
            color: #666666;
          "
        >
          <div>{{ item.createTime.slice(0, 10) }}</div>
          <div style="margin-left: auto">查看详情 >></div>
        </div>
      </div>
    </div>

    <div
      style="
        text-align: center;
        margin: 80px auto;
        width: 203px;
        height: 42px;
        line-height: 42px;
        background: #EE3D33;
        border-radius: 21px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        font-size: 16px;
        color: #FFFFFF;
        cursor: pointer;
      "
      @click="dialogVisible = true"
    >
      了解更多
    </div>
    <Dialog @Visible="Visible" :dialogVisible="dialogVisible"></Dialog>
  </div>
</template>
<script>
import Dialog from "@/components/dialog";
export default {
  components: {
    Dialog,
  },
  props: {
    // middleImg: {
    //   type: String,
    //   default: "",
    // },
    bottomTitle: {
      type: String,
      default: "",
    },
    // middleContent: {
    //   type: String,
    //   default: "",
    // },
    // middleList: {
    //   type: Array,
    //   default: () => [],
    // },
  },
  data() {
    return {
      dialogVisible: false,
      list: [],
      pageNum: 1,
      pageSize: 4,
    };
  },
  created() {
    this.getPolicylist();
  },
  methods: {
    nextContent(val) {
      this.$router.push({
        path:'/information',
        query:{
          params:JSON.stringify(val)
        }
      });
    },
    getPolicylist() {
      const url = `https://admin.atqf.cn/prod-api/atgw/cyqyzx/list?pageNum=${this.pageNum}&pageSize=${this.pageSize}`;
      const options = {
        method: "GET", // 请求方法
        headers: {
          "Content-Type": "application/json", // 设置请求头
        },
      };

      fetch(url, options)
        .then((response) => response.json())
        .then((res) => {
          this.list = res.rows;
        })
        .catch((error) => console.error("Error:", error));
    },
    Visible() {
      this.dialogVisible = false;
    },
  },
};
</script>
<style scoped>
.bottomStyle {
  border: 1px solid #D4D4D4;
  flex: 1;
  padding: 50px 30px 30px;
  margin-right: 35px;
  cursor: pointer;
}
</style>