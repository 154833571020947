<template>
  <div style="margin-top: 120px;">
    <Nav></Nav>
    <div style="width: 2000px; margin: 0 auto">
      <div>
        <div class="bg"></div>
      </div>

      <div
        style="
          display: flex;
          align-items: center;
          margin: 80px 0 50px;
          justify-content: center;
        "
      >
        <div
          class="btn"
          @click="changeBtn(0)"
          :class="isNum == 0 ? 'isActiveStyle' : ''"
        >
          政策法规
        </div>
        <div
          class="btn"
          @click="changeBtn(1)"
          :class="isNum == 1 ? 'isActiveStyle' : ''"
        >
          行业知识
        </div>
        <div
          class="btn"
          @click="changeBtn(2)"
          :class="isNum == 2 ? 'isActiveStyle' : ''"
        >
          常见问题
        </div>
      </div>

      <div style="width: 1600px; margin: 0 auto">
        <div style="display: flex">
          <div style="flex: 7; margin-right: 30px" v-if="isNum == 0">
            <div
              style="
                display: flex;
                /* align-items: center; */
                height: 240px;
                width: 100%;
                border: 1px solid #D5D5D5;
                padding: 10px 30px 10px 10px;
              "
              v-for="(item, index) in list"
              :key="index"
              @click="readBtn()"
            >
              <div style="margin-right: 20px">
                <img
                  style="width: 320px; height: 220px"
                  :src="item.img"
                  alt=""
                />
              </div>
              <div>
                <div class="title">{{ item.title }}</div>
                <p v-html="item.content"></p>
                <div
                  style="display: flex; align-items: center; margin-top: 40px"
                  class="time"
                >
                  <img src="@/assets/img/classRoom/2.png" alt="" />
                  <div style="margin: 0 45px 0 5px">{{ item.createTime }}</div>
                  <img
                    style="margin-right: 5px"
                    src="@/assets/img/classRoom/3.png"
                    alt=""
                  />
                  <div>{{ item.viewCount }}</div>
                  <i
                    class="el-icon-right"
                    style="font-size: 18px; color: #ED3831FF; margin-left: auto"
                  ></i>
                </div>
              </div>
            </div>
          </div>

          <div style="flex: 7; margin-right: 30px" v-if="isNum == 1">
            <div
              style="
                display: flex;
                /* align-items: center; */
                height: 240px;
                width: 100%;
                border: 1px solid #D5D5D5;
                padding: 10px 30px 10px 10px;
              "
              v-for="(item, index) in list"
              :key="index"
              @click="readBtn()"
            >
              <div style="margin-right: 20px">
                <img
                  style="width: 320px; height: 220px"
                  :src="item.img"
                  alt=""
                />
              </div>
              <div>
                <div class="title">{{ item.title }}</div>
                <p v-html="item.content"></p>
                <div
                  style="display: flex; align-items: center; margin-top: 40px"
                  class="time"
                >
                  <img src="@/assets/img/classRoom/2.png" alt="" />
                  <div style="margin: 0 45px 0 5px">{{ item.createTime }}</div>
                  <img
                    style="margin-right: 5px"
                    src="@/assets/img/classRoom/3.png"
                    alt=""
                  />
                  <div>{{ item.viewCount }}</div>
                  <i
                    class="el-icon-right"
                    style="font-size: 18px; color: #ED3831FF; margin-left: auto"
                  ></i>
                </div>
              </div>
            </div>
          </div>

          <!-- 常见问题 -->
          <div style="flex: 7; margin-right: 30px" v-if="isNum == 2">
            <el-collapse v-model="activeName" accordion>
              <el-collapse-item
                style="
                  border: 1px solid #D5D5D5;
                  padding: 10px;
                  font-family: Source Han Sans CN;
                  font-weight: 500;
                  font-size: 18px;
                  color: #333333;
                  margin-bottom: 20px;
                "
                :name="index"
                v-for="(item, index) in list"
                :key="index"
              >
                <template #title>
                  <img
                    style="width: 30px; height: 30px; margin: 0 10px"
                    src="@/assets/img/home/c_16.png"
                    alt=""
                  />
                  {{ item.title }}
                </template>

                <div class="q_content" v-html="item.content"></div>
              </el-collapse-item>
            </el-collapse>
          </div>
          <div style="flex: 2; position: relative" class="rightContent">
            <div
              style="
                font-family: Source Han Sans CN;
                font-weight: 500;
                font-size: 18px;
                color: #333333;
                padding: 15px;
                border-bottom: 1px solid #D5D5D5;
                position: relative;
              "
            >
              推荐新闻
              <div
                style="
                  height: 3px;
                  width: 75px;
                  background-color: #ee3d33;
                  position: absolute;
                  bottom: 0;
                "
              ></div>
            </div>

            <div class="rightCon" v-for="(item, index) in list" :key="index">
              <div style="display: flex; padding: 10px">
                <div style="margin-right: 20px">
                  <img
                    style="height: 70px; width: 105px"
                    src="@/assets/img/classRoom/5.png"
                    alt=""
                  />
                </div>
                <div>
                  <div
                    class="rightTitle"
                    v-if="item.title.length > 10"
                    style="margin: 10px 0 15px"
                  >
                    {{ item.title.slice(0, 10) }}...
                  </div>
                  <div class="rightTitle" v-else style="margin: 10px 0 15px">
                    {{ item.title }}
                  </div>
                  <div
                    class="rightTitle"
                    style="display: flex; align-items: center"
                  >
                    <span>{{ item.time }}</span>
                    <i class="el-icon-right" style="margin-left: auto"></i>
                  </div>
                </div>
              </div>
            </div>

            <img
              style="width: 100%; position: absolute; bottom: 0"
              src="@/assets/img/classRoom/4.png"
              alt=""
            />
          </div>
        </div>

        <el-pagination
          v-show="total > 0"
          style="margin: 70px 0; text-align: center"
          background
          layout="prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Footer from "@/components/footer";
import Nav from "@/components/nav";
export default {
  components: {
    Footer,
    Nav,
  },
  data() {
    return {
      isNum: null,
      list: [],
      activeName: "0",
      pageNum: 1,
      pageSize: 10,
      total: 0,
    };
  },
  created() {
    this.isNum = this.$route.params.id;
    this.getPolicylist();
  },
  watch: {
    $route: {
      // 值发生变化时的回调函数
      handler(now) {
        this.isNum = now.params.id;
      },
      immediate: true, //立即执行
    },
  },
  methods: {
    getPolicylist() {
      const url = `https://admin.atqf.cn/prod-api/atgw/zskt/list?pageNum=${this.pageNum}&pageSize=${this.pageSize}&type=${this.isNum}`;
      const options = {
        method: "GET", // 请求方法
        headers: {
          "Content-Type": "application/json", // 设置请求头
        },
      };

      fetch(url, options)
        .then((response) => response.json())
        .then((res) => {
          this.list = res.rows;
          this.total = res.total;
          // console.log(res, "===========");
        })
        .catch((error) => console.error("Error:", error));
    },
    readBtn() {
      this.$router.push("/newRead");
    },
    changeBtn(val) {
      this.isNum = val;
      this.pageNum = 1;
      this.pageSize = 10;
      this.total = 0;
      this.getPolicylist();
    },
  },
};
</script>
<style scoped>
.q_content {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
}
::v-deep .el-collapse-item__header.is-active {
  color: #EE3D33 !important;
}
::v-deep .el-collapse-item__header {
  font-family: Source Han Sans CN !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  color: #333333 !important;
}
.rightTitle {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 16px;
  color: #666666;
}
.rightCon {
  height: 90px;
  border-bottom: 1px solid #D5D5D5;
}
.rightContent {
  border: 1px solid #D5D5D5;
  margin-left: 30px;
}
::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #ee3d33 !important;
  border-radius: 50% !important;
}
::v-deep.el-pagination.is-background .el-pager li {
  border-radius: 50% !important;
}
.time {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 16px;
  color: #666666;
}
.content {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
}
.title {
  margin: 30px 0;
  font-family: Source Han Sans CN;
  font-weight: 500;
  font-size: 18px;
  color: #333333;
}
.isActiveStyle {
  background-color: #ee3d33 !important;
  color: #fff !important;
}
.btn {
  cursor: pointer;
  margin-right: 25px;
  padding: 8px 25px;
  background-color: #f0f0f0;
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
}
.bg {
  text-align: center;
  height: 400px;
  width: 100%;
  background-image: url("https://atqf.oss-cn-beijing.aliyuncs.com/file/2024-07-12/banner (2).jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
</style>