<template>
  <div style="margin-top: 120px">
    <Nav :internalActiveName="internalActiveName"></Nav>
    <div style="width: 2000px; margin: 0 auto">
      <div>
        <div class="bg">
          <div
            style="
              font-family: Alimama ShuHeiTi;
              font-weight: bold;
              font-size: 49px;
              color: #FFFFFF;
              padding-top: 100px;
            "
          >
            申请增值电信业务经营许可证
          </div>
          <div
            style="
              font-family: Alimama ShuHeiTi;
              font-weight: bold;
              font-size: 30px;
              color: #FFFFFF;
              margin: 10px 0;
            "
          >
            我们最懂！
          </div>
          <div
            style="
              font-family: Alimama ShuHeiTi;
              font-weight: bold;
              font-size: 16px;
              color: #FFFFFF;
              margin-bottom: 20px;
            "
          >
            致力于提供增值电信业务行政审批全套解决方案
          </div>
          <div
            style="
              width: 320px;
              height: 46px;
              background-color: #fff;
              text-align: center;
              line-height: 46px;
              border-radius: 30px;
              font-family: Alimama ShuHeiTi;
              font-weight: bold;
              font-size: 30px;
              color: #913333;
              margin: 0 auto;
              cursor: pointer;
            "
            @click="dialogVisible = true"
          >
            立马办理
          </div>
        </div>
      </div>

      <div
        style="height: 260px"
        class="c_bg"
        :class="scrollPosition > 420 ? 'topStyle' : ''"
      >
        <div style="width: 1600px; margin: 0 auto">
          <div style="text-align: left; padding: 25px 0; display: flex">
            <div
              style="
                font-family: Source Han Sans CN;
                font-weight: bold;
                font-size: 30px;
                color: #333333;
              "
            >
              {{ cTitle }}
            </div>

            <div style="margin-left: auto; margin-right: 10px">
              <el-button
                icon="el-icon-arrow-left"
                circle
                @click="leftBtn()"
              ></el-button>
            </div>
            <div>
              <el-button
                icon="el-icon-arrow-right"
                circle
                @click="rightBtn()"
              ></el-button>
            </div>
          </div>

          <div style="display: flex" v-if="total == 0">
            <div
              v-for="(item, index) in navList"
              :key="index"
              @click="change(index)"
              style="flex: 1; margin-right: 15px"
              class="bg_change"
              :class="isActive == index ? 'isActiveStyle' : ''"
            >
              <div>{{ item.first }}</div>
              <div v-if="item.second">{{ item.second }}</div>
            </div>
          </div>
          <div style="display: flex" v-if="total == 1">
            <div
              v-for="(item, index) in navList2"
              :key="index"
              @click="change(index + 7)"
              style="flex: 1; margin-right: 15px"
              class="bg_change"
              :class="isActive == index + 7 ? 'isActiveStyle' : ''"
            >
              <div>{{ item.first }}</div>
              <div v-if="item.second">{{ item.second }}</div>
            </div>
          </div>

          <div style="display: flex" v-if="total == 2">
            <div
              v-for="(item, index) in navList3"
              :key="index"
              @click="change(index + 14)"
              style="flex: 1; margin-right: 15px"
              class="bg_change"
              :class="isActive == index + 14 ? 'isActiveStyle' : ''"
            >
              <div>{{ item.first }}</div>
              <div v-if="item.second">{{ item.second }}</div>
            </div>
          </div>

          <div style="display: flex" v-if="total == 3">
            <div
              v-for="(item, index) in navList4"
              :key="index"
              @click="change(index + 21)"
              style="flex: 1; margin-right: 15px"
              class="bg_change"
              :class="isActive == index + 21 ? 'isActiveStyle' : ''"
            >
              <div>{{ item.first }}</div>
              <div v-if="item.second">{{ item.second }}</div>
            </div>
          </div>

          <div style="display: flex" v-if="total == 4">
            <div
              v-for="(item, index) in navList5"
              :key="index"
              @click="change(index + 28)"
              style="flex: 1; margin-right: 15px"
              class="bg_change"
              :class="isActive == index + 28 ? 'isActiveStyle' : ''"
            >
              <div>{{ item.first }}</div>
              <div v-if="item.second">{{ item.second }}</div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="isActive == null"
        style="width: 2000px; margin: 0 auto; background-color: #fff"
      >
        <div
          style="
            width: 1600px;
            margin: 0 auto;
            margin-top: 50px;
            height: 1000px;
          "
        >
          <div style="display: flex; align-items: center">
            <div style="flex: 1; padding: 20px">
              <h1
                style="
                  border-bottom: 1px solid #ccc;
                  padding-bottom: 10px;
                  position: relative;
                "
              >
                为什么要办增值电信业务经营许可证？
                <div
                  style="
                    height: 3px;
                    background-color: #f66262;
                    width: 70px;
                    position: absolute;
                    bottom: 0px;
                  "
                ></div>
              </h1>
              <p
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 16px;
                  color: #333333;
                  margin: 20px 0 35px;
                "
              >
                根据中华人民共和国国务院令第291号《中华人民共和国电信条例》、第292号《互联网信息服务管理办法》，经营电信业务，必须依照规定取得国务院信息产业主管部门或者省、自治区、直辖市电信管理机构颁发的电信业务经营许可证。未取得电信业务经营许可证，任何组织或个人不得从事电信业务经营活动。否则，就属于非法经营，并将面临相应处罚。
              </p>

              <div style="font-size: 18px; color: #333333">
                <div style="display: flex; align-items: center; margin: 35px 0">
                  <span
                    style="
                      display: inline-block;
                      height: 8px;
                      width: 8px;
                      border-radius: 50%;
                      background-color: #f66262;
                    "
                  ></span>
                  <span style="margin-left: 5px"
                    >市场合法合规准入门槛必须资质重要途径。</span
                  >
                </div>

                <div style="display: flex; align-items: center; margin: 35px 0">
                  <span
                    style="
                      display: inline-block;
                      height: 8px;
                      width: 8px;
                      border-radius: 50%;
                      background-color: #f66262;
                    "
                  ></span>
                  <span style="margin-left: 5px"
                    >增强品牌实力，客户认可程度。</span
                  >
                </div>

                <div style="display: flex; align-items: center; margin: 35px 0">
                  <span
                    style="
                      display: inline-block;
                      height: 8px;
                      width: 8px;
                      border-radius: 50%;
                      background-color: #f66262;
                    "
                  ></span>
                  <span style="margin-left: 5px"
                    >享受国家税务减免优惠，政府资金扶持。</span
                  >
                </div>
              </div>
            </div>
            <div style="flex: 1">
              <img
                style="width: 100%; height: 100%"
                src="https://atqf.oss-cn-beijing.aliyuncs.com/file/2024-07-12/c_1.jpg"
                alt=""
              />
            </div>
          </div>

          <div style="display: flex; align-items: center">
            <div style="flex: 1">
              <img
                style="height: 100%; width: 100%"
                src="https://atqf.oss-cn-beijing.aliyuncs.com/file/2024-07-12/c_2.jpg"
                alt=""
              />
            </div>

            <div style="flex: 1; padding: 20px">
              <h1
                style="
                  border-bottom: 1px solid #ccc;
                  padding-bottom: 10px;
                  position: relative;
                "
              >
                该怎样办理增值电信业务经营许可证？
                <div
                  style="
                    height: 3px;
                    background-color: #f66262;
                    width: 70px;
                    position: absolute;
                    bottom: 0px;
                  "
                ></div>
              </h1>
              <p
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 16px;
                  color: #333333;
                  margin: 20px 0 35px;
                "
              >
                经营电信业务，必须依照规定取得国务院信息产业主管部门或者省、自治区、直辖市电信管理机构颁发的电信业务经营许可证。未取得电信业务经营许可证，任何组织或个人不得从事电信业务经营活动。那么怎么办理增值电信业务经营许可证呢?
              </p>

              <div style="font-size: 18px; color: #333333">
                <div style="display: flex; align-items: center">
                  <span
                    style="
                      display: inline-block;
                      height: 8px;
                      width: 8px;
                      border-radius: 50%;
                      background-color: #f66262;
                    "
                  ></span>
                  <span style="margin-left: 5px">基础材料准备</span>
                </div>
                <div
                  style="
                    margin-bottom: 35px;
                    font-size: 16px;
                    margin-left: 10px;
                  "
                >
                  (协助您准备所需材料)
                </div>

                <div style="display: flex; align-items: center">
                  <span
                    style="
                      display: inline-block;
                      height: 8px;
                      width: 8px;
                      border-radius: 50%;
                      background-color: #f66262;
                    "
                  ></span>
                  <span style="margin-left: 5px"
                    >增强品牌实力，客户认可程度。</span
                  >
                </div>
                <div
                  style="
                    margin-bottom: 35px;
                    font-size: 16px;
                    margin-left: 10px;
                  "
                >
                  (剩下的事就交给我们了)
                </div>

                <div style="display: flex; align-items: center">
                  <span
                    style="
                      display: inline-block;
                      height: 8px;
                      width: 8px;
                      border-radius: 50%;
                      background-color: #f66262;
                    "
                  ></span>
                  <span style="margin-left: 5px"
                    >享受国家税务减免优惠，政府资金扶持。</span
                  >
                </div>
                <div
                  style="
                    margin-bottom: 35px;
                    font-size: 16px;
                    margin-left: 10px;
                  "
                >
                  (So Easy)
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style="width: 2000px; margin: 0 auto; margin-top: 50px; height: 900px"
          class="d_bg"
        >
          <div
            style="
              padding: 70px 0 20px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              font-size: 30px;
              color: #FFFFFF;
            "
          >
            代办增值电信业务经营许可证,我们还能为您提供！
          </div>
          <div
            style="
              font-family: Source Han Sans CN;
              font-weight: bold;
              font-size: 18px;
              color: #FFFFFF;
            "
          >
            企业取得增值电信业务经营许可证后，我们还可以为您提供以下服务
          </div>

          <div
            style="
              display: flex;
              align-items: center;
              width: 1600px;
              margin: 50px auto 0;
            "
          >
            <div style="flex: 1" class="d_content">
              <div style="text-align: center; margin-top: 50px">
                <img
                  style="height: 170px; width: 170px"
                  src="@/assets/img/zzbl/d_3.png"
                  alt=""
                /><img src="" alt="" />
              </div>
              <div class="d_titel1">取证后业务开展</div>
              <div class="d_titel2">域名和空间相关备案手续;</div>
              <div class="d_titel2">为企业提供专业行业领域业务优化建议。</div>
            </div>
            <div
              style="
                flex: 1;
                border-left: 1px solid #918d8c;
                border-right: 1px solid #918d8c;
              "
              class="d_content"
            >
              <div style="text-align: center; margin-top: 50px">
                <img
                  style="height: 170px; width: 170px"
                  src="@/assets/img/zzbl/d_4.png"
                  alt=""
                /><img src="" alt="" />
              </div>
              <div class="d_titel1">取证后通道租用或资源对接</div>
              <div class="d_titel2">IDC/ISP各省通管局的系统对接</div>
              <div class="d_titel2">SP码号的运营商合作对接。</div>
            </div>
            <div style="flex: 1" class="d_content">
              <div style="text-align: center; margin-top: 50px">
                <img
                  style="height: 170px; width: 170px"
                  src="@/assets/img/zzbl/d_5.png"
                  alt=""
                /><img src="" alt="" />
              </div>
              <div class="d_titel1">取证后许可证后续事务</div>
              <div class="d_titel2">许可证年检 许可证增项</div>
              <div class="d_titel2">许可证变更 许可证缋期</div>
              <div class="d_titel2">可证注销</div>
            </div>
          </div>

          <div
            style="
              font-family: Source Han Sans CN;
              font-weight: bold;
              font-size: 30px;
              color: #FFFFFF;
              margin: 80px 0 50px;
            "
          >
            将申请准入变得更加简单、高效、便捷！
          </div>

          <div
            style="
              width: 320px;
              height: 46px;
              background-color: #fff;
              text-align: center;
              line-height: 46px;
              border-radius: 30px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              font-size: 24px;
              color: #F55655;
              margin: 0 auto;
              cursor: pointer;
            "
            @click="dialogVisible = true"
          >
            立即咨询、即刻下证
          </div>
        </div>

        <bottomWiedt
          :bottomTitle="bottomTitle"
          style="margin-top: 80px"
        ></bottomWiedt>
      </div>

      <one v-if="isActive == 0"></one>

      <two v-if="isActive == 1"></two>

      <three v-if="isActive == 2"></three>

      <four v-if="isActive == 3"></four>

      <five v-if="isActive == 4"></five>

      <six v-if="isActive == 5"></six>

      <seven v-if="isActive == 6"></seven>

      <eight v-if="isActive == 7"></eight>

      <nine v-if="isActive == 8"></nine>

      <ten v-if="isActive == 9"></ten>

      <eleven v-if="isActive == 10"></eleven>

      <twelve v-if="isActive == 11"></twelve>

      <thirteen v-if="isActive == 12"></thirteen>

      <fourteen v-if="isActive == 13"></fourteen>

      <fifteen v-if="isActive == 14"></fifteen>

      <sixteen v-if="isActive == 15"></sixteen>

      <seventeen v-if="isActive == 16"></seventeen>

      <eighteen v-if="isActive == 17"></eighteen>

      <nineteen v-if="isActive == 18"></nineteen>

      <twenty v-if="isActive == 19"></twenty>

      <twentyOne v-if="isActive == 20"></twentyOne>

      <twentyTwo v-if="isActive == 21"></twentyTwo>

      <twenty_three v-if="isActive == 22"></twenty_three>

      <twenty_four v-if="isActive == 23"></twenty_four>

      <twenty_five v-if="isActive == 24"></twenty_five>

      <twenty_six v-if="isActive == 25"></twenty_six>

      <twenty_seven v-if="isActive == 26"></twenty_seven>

      <twenty_eight v-if="isActive == 27"></twenty_eight>

      <twenty_nine v-if="isActive == 28"></twenty_nine>

      <thirty v-if="isActive == 29"></thirty>

      <thirty_one v-if="isActive == 30"></thirty_one>

      <thirty_two v-if="isActive == 31"></thirty_two>

      <thirty_three v-if="isActive == 32"></thirty_three>

      <thirty_four v-if="isActive == 33"></thirty_four>
    </div>

    <Footer></Footer>
    <Dialog @Visible="Visible" :dialogVisible="dialogVisible"></Dialog>
  </div>
</template>
<script>
import Dialog from "@/components/dialog";
import Footer from "@/components/footer";
import Nav from "@/components/nav";
import one from "@/views/components/qualifications/one.vue";
import two from "@/views/components/qualifications/two.vue";
import three from "@/views/components/qualifications/three.vue";
import four from "@/views/components/qualifications/four.vue";
import five from "@/views/components/qualifications/five.vue";
import six from "@/views/components/qualifications/six.vue";
import seven from "@/views/components/qualifications/seven.vue";
import eight from "@/views/components/qualifications/eight.vue";
import nine from "@/views/components/qualifications/nine.vue";
import ten from "@/views/components/qualifications/ten.vue";
import eleven from "@/views/components/qualifications/eleven.vue";
import twelve from "@/views/components/qualifications/twelve.vue";
import thirteen from "@/views/components/qualifications/thirteen.vue";
import fourteen from "@/views/components/qualifications/fourteen.vue";
import fifteen from "@/views/components/qualifications/fifteen.vue";
import sixteen from "@/views/components/qualifications/sixteen.vue";
import seventeen from "@/views/components/qualifications/seventeen.vue";
import eighteen from "@/views/components/qualifications/eighteen.vue";
import nineteen from "@/views/components/qualifications/nineteen.vue";
import twenty from "@/views/components/qualifications/twenty.vue";
import twentyOne from "@/views/components/qualifications/twenty_one.vue";
import twentyTwo from "@/views/components/qualifications/twenty_two.vue";
import twenty_three from "@/views/components/qualifications/twenty_three.vue";
import twenty_four from "@/views/components/qualifications/twenty_four.vue";
import twenty_five from "@/views/components/qualifications/twenty_five.vue";
import twenty_six from "@/views/components/qualifications/twenty_six.vue";
import twenty_seven from "@/views/components/qualifications/twenty_seven.vue";
import twenty_eight from "@/views/components/qualifications/twenty_eight.vue";
import twenty_nine from "@/views/components/qualifications/twenty_nine.vue";
import thirty from "@/views/components/qualifications/thirty.vue";
import thirty_one from "@/views/components/qualifications/thirty_one.vue";
import thirty_two from "@/views/components/qualifications/thirty_two.vue";
import thirty_three from "@/views/components/qualifications/thirty_three.vue";
import thirty_four from "@/views/components/qualifications/thirty_four.vue";
import bottomWiedt from "@/views/components/qualifications/bottomWiedt";

export default {
  components: {
    bottomWiedt,
    Dialog,
    Footer,
    Nav,
    one,
    two,
    three,
    four,
    five,
    six,
    seven,
    eight,
    nine,
    ten,
    eleven,
    twelve,
    thirteen,
    fourteen,
    fifteen,
    sixteen,
    seventeen,
    eighteen,
    nineteen,
    twenty,
    twentyOne,
    twentyTwo,
    twenty_three,
    twenty_four,
    twenty_five,
    twenty_six,
    twenty_seven,
    twenty_eight,
    twenty_nine,
    thirty,
    thirty_one,
    thirty_two,
    thirty_three,
    thirty_four,
  },
  data() {
    return {
      bottomTitle: "创业前沿资讯",
      cTitle: "增值电信业务经营许可证",
      scrollPosition: 0,
      dialogVisible: false,
      internalActiveName: "2",
      isActive: null,
      navList: [
        { first: "互联网信息服务业务", second: " (ICP证)" },
        { first: "移动网信息服务业务", second: " (SP证)" },
        { first: "在线数据处理业务", second: "(EDI)" },
        { first: "呼叫中心业务" },
        { first: "数据中心业务", second: "(IDC证)" },
        { first: "内容分发网络业务 ", second: "(CDN证)" },
        { first: "国内多方通信服务业务 " },
      ],
      navList2: [
        { first: "网络托管业务" },
        { first: "存储转发类业务" },
        { first: "办理外商投资电信业务审批" },
        { first: "95码号申请" },
        { first: "10690码号/10691码号/SP码号申请" },
        { first: "10698码号申请 " },
        { first: "网络出版服务许可证申请" },
      ],
      navList3: [
        { first: "出版物许可证申请办理" },
        { first: "互联网新闻信息服务许可证" },
        { first: "互联网药品信息服务许可证申请" },
        { first: "互联网药品交易许可证申请" },
        { first: "互联网资质代办平台" },
        { first: "广播电视节目制作许可证 " },
        { first: "营业性演出许可证" },
      ],
      navList4: [
        { first: "域名备案/网站备案/ICP网站备案" },
        { first: "公安网备" },
        { first: "经营性网站备案" },
        { first: "信息系统安全等级保护备案" },
        { first: "商业特许经营企业备案" },
        { first: "许可年检 " },
        { first: "可信网站" },
      ],
      navList5: [
        { first: "网络文化经营许可证" },
        { first: "营业性演出许可证" },
        { first: "艺术品经营单位备案" },
        { first: "美术品进出口经营 活动审批" },
        { first: "游戏版号" },
        { first: "游戏备案 " },
      ],
      total: 0,
    };
  },
  created() {
    this.isActive = this.$route.query.id;
  },
  watch: {
    $route: {
      // 值发生变化时的回调函数
      handler(now) {
        this.isActive = now.query.id;
        if (this.isActive == 0) {
          this.cTitle = "增值电信业务经营许可证";
        } else if (this.isActive == 28) {
          this.cTitle = "网络文化/网络游戏";
        } else if (this.isActive == 19) {
          this.cTitle = "影视审批";
        } else if (this.isActive == 21) {
          this.cTitle = "网站备案";
        } else if (this.isActive == 16) {
          this.cTitle = "互联网医药";
        } else if (this.isActive == 13) {
          this.cTitle = "互联网出版";
        }

        if (
          this.isActive == null ||
          (this.isActive >= 0 && this.isActive <= 6)
        ) {
          this.total = 0;
        } else if (this.isActive > 6 && this.isActive <= 13) {
          this.total = 1;
        } else if (this.isActive > 13 && this.isActive <= 20) {
          this.total = 2;
        } else if (this.isActive > 20 && this.isActive <= 27) {
          this.total = 3;
        } else if (this.isActive > 27 && this.isActive <= 34) {
          this.total = 4;
        }
      },
      immediate: true, //立即执行
    },
  },
  methods: {
    handleScroll() {
      this.scrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      console.log("Current scroll position:", this.scrollPosition);
    },
    Visible() {
      this.dialogVisible = false;
    },
    change(val) {
      this.isActive = val;
    },
    leftBtn() {
      if (this.total == 0) {
        this.total = 0;
      } else {
        this.total--;
      }
    },
    rightBtn() {
      if (this.total == 4) {
        this.total = 4;
      } else {
        this.total++;
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style scoped>
.topStyle {
  position: fixed;
  top: 100px;
  width: 2000px;
}
.d_titel1 {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 24px;
  color: #FFFFFF;
  margin: 20px 0;
}
.d_titel2 {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 16px;
  color: #FFFFFF;
}
.d_content {
  height: 380px;
}
.d_bg {
  text-align: center;
  height: 100%;
  width: 100%;
  background-image: url("https://atqf.oss-cn-beijing.aliyuncs.com/file/2024-07-12/d_bg.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.isActiveStyle {
  background: linear-gradient(-28deg, #F55656 0%, #FFB77C 100%);
  color: #FFFFFF !important;
}
.bg_change {
  cursor: pointer;
  background-color: #fff;
  border-radius: 10px;
  text-align: center;
  height: 80px;
  padding-top: 30px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 16px;
  color: #666666;
}
.c_bg {
  z-index: 99;
  text-align: center;
  height: 100%;
  width: 2000px;
  background-image: url("~@/assets/img/zzbl/c_bg.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.bg {
  text-align: center;
  height: 400px;
  width: 100%;
  background-image: url("https://atqf.oss-cn-beijing.aliyuncs.com/file/2024-07-12/banner (1).jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
</style>