<template>
  <div>
    <div style="width: 1400px; margin: 0 auto">
      <middleWiedt
        :middleImg="middleImg"
        :middleTitle="middleTitle"
        :middleContent="middleContent"
        :middleList="middleList"
      ></middleWiedt>

      <div style="background-color: #f4f4f4; padding: 0 20px">
        <div
          style="
            font-family: Source Han Sans CN;
            font-weight: bold;
            font-size: 24px;
            color: #333333;
            padding: 20px 0;
            border-bottom: 1px solid #D0D0D0;
            position: relative;
          "
        >
          <div class="title"></div>
          增值电信业务经营许可证年检
        </div>

        <div style="display: flex">
          <div class="box_content" v-for="(item, index) in list" :key="index">
            <img
              style="width: 300px"
              v-if="item.img == 1"
              src="@/assets/img/zzbl/27/2.png"
              alt=""
            />
            <img
              style="width: 300px"
              v-if="item.img == 2"
              src="@/assets/img/zzbl/27/3.png"
              alt=""
            />
            <img
              style="width: 300px"
              v-if="item.img == 3"
              src="@/assets/img/zzbl/27/4.png"
              alt=""
            />
            <img
              style="width: 300px"
              v-if="item.img == 4"
              src="@/assets/img/zzbl/27/5.png"
              alt=""
            />
            <div class="box_title">{{ item.title }}</div>
            <div class="box_contents">{{ item.content }}</div>
            <div
              style="display: flex; align-items: center; margin: 35px 0 20px"
            >
              <div
                style="
                  background: #EE3D33;
                  flex: 1;
                  text-align: center;
                  padding: 7px 0;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin-right: 10px;
                  cursor: pointer;
                "
                @click="dialogVisible = true"
              >
                查看详情
              </div>
              <div
                style="
                  flex: 1;
                  text-align: center;
                  padding: 7px 0;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #EE3D33;
                  margin-right: 10px;
                  border: 1px solid #EE3D33;
                  cursor: pointer;
                "
                @click="dialogVisible = true"
              >
                立即咨询
              </div>
            </div>
          </div>
        </div>

        <div style="display: flex; margin-top: 20px">
          <div class="box_content" v-for="(item, index) in lists" :key="index">
            <img
              style="width: 300px"
              v-if="item.img == 1"
              src="@/assets/img/zzbl/27/6.png"
              alt=""
            />
            <img
              style="width: 300px"
              v-if="item.img == 2"
              src="@/assets/img/zzbl/27/7.png"
              alt=""
            />
            <img
              style="width: 300px"
              v-if="item.img == 3"
              src="@/assets/img/zzbl/27/8.png"
              alt=""
            />
            <img
              style="width: 300px"
              v-if="item.img == 4"
              src="@/assets/img/zzbl/27/9.png"
              alt=""
            />
            <div class="box_title">{{ item.title }}</div>
            <div class="box_contents">{{ item.content }}</div>
            <div
              style="display: flex; align-items: center; margin: 35px 0 20px"
            >
              <div
                style="
                  background: #EE3D33;
                  flex: 1;
                  text-align: center;
                  padding: 7px 0;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin-right: 10px;
                  cursor: pointer;
                "
                @click="dialogVisible = true"
              >
                查看详情
              </div>
              <div
                style="
                  flex: 1;
                  text-align: center;
                  padding: 7px 0;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #EE3D33;
                  margin-right: 10px;
                  border: 1px solid #EE3D33;
                  cursor: pointer;
                "
                @click="dialogVisible = true"
              >
                立即咨询
              </div>
            </div>
          </div>
        </div>

        <div style="display: flex; margin-top: 20px">
          <div class="box_content">
            <img
              style="width: 300px"
              src="@/assets/img/zzbl/27/10.png"
              alt=""
            />
            <div class="box_title">固定网国内数据传送业务</div>
            <div class="box_contents">
              固定网国内数据传送业务是指互联网数据传送业务以外的，在固定网中以有线方式提供的国内端到端数据传送业务。
              年检时间：每年1-3月（注：全网3-6月）
            </div>
            <div
              style="display: flex; align-items: center; margin: 35px 0 20px"
            >
              <div
                style="
                  background: #EE3D33;
                  flex: 1;
                  text-align: center;
                  padding: 7px 0;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin-right: 10px;
                  cursor: pointer;
                "
                @click="dialogVisible = true"
              >
                查看详情
              </div>
              <div
                style="
                  flex: 1;
                  text-align: center;
                  padding: 7px 0;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #EE3D33;
                  margin-right: 10px;
                  border: 1px solid #EE3D33;
                  cursor: pointer;
                "
                @click="dialogVisible = true"
              >
                立即咨询
              </div>
            </div>
          </div>
          <div class="box_content">
            <img
              style="width: 300px"
              src="@/assets/img/zzbl/27/11.png"
              alt=""
            />
            <div class="box_title">网络托管业务</div>
            <div class="box_contents">
              是指受用户委托，代管用户自有或租用的国内网络、网络元素或设备，包括为用户提供设备放置、网络管理、运行和维护服务，以及为用户提供互联互通和其他网络应用的管理和维护服务。
              年检时间：每年1-3月（注：全网3-6月）
            </div>
            <div
              style="display: flex; align-items: center; margin: 35px 0 20px"
            >
              <div
                style="
                  background: #EE3D33;
                  flex: 1;
                  text-align: center;
                  padding: 7px 0;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin-right: 10px;
                  cursor: pointer;
                "
                @click="dialogVisible = true"
              >
                查看详情
              </div>
              <div
                style="
                  flex: 1;
                  text-align: center;
                  padding: 7px 0;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #EE3D33;
                  margin-right: 10px;
                  border: 1px solid #EE3D33;
                  cursor: pointer;
                "
                @click="dialogVisible = true"
              >
                立即咨询
              </div>
            </div>
          </div>
          <div class="box_content">
            <img
              style="width: 300px"
              src="@/assets/img/zzbl/27/12.png"
              alt=""
            />
            <div class="box_title">用户驻地网业务</div>
            <div class="box_contents">
              用户驻地网业务是指以有线或无线方式，利用公用通信网相连的用户驻地网（CPN）相关网络设施提供的网络接入设施服务业务。
              年检时间：每年1-3月（注：全网3-6月）
            </div>
            <div
              style="display: flex; align-items: center; margin: 35px 0 20px"
            >
              <div
                style="
                  background: #EE3D33;
                  flex: 1;
                  text-align: center;
                  padding: 7px 0;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin-right: 10px;
                  cursor: pointer;
                "
                @click="dialogVisible = true"
              >
                查看详情
              </div>
              <div
                style="
                  flex: 1;
                  text-align: center;
                  padding: 7px 0;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #EE3D33;
                  margin-right: 10px;
                  border: 1px solid #EE3D33;
                  cursor: pointer;
                "
                @click="dialogVisible = true"
              >
                立即咨询
              </div>
            </div>
          </div>

          <div style="flex: 1"></div>
        </div>

        <div
          style="
            font-family: Source Han Sans CN;
            font-weight: bold;
            font-size: 24px;
            color: #333333;
            padding: 20px 0;
            border-bottom: 1px solid #D0D0D0;
            position: relative;
            margin-top: 30px;
          "
        >
          <div class="title"></div>
          文化行业年检
        </div>
        <div style="display: flex; margin-top: 20px">
          <div class="box_content">
            <img
              style="width: 300px"
              src="@/assets/img/zzbl/27/13.png"
              alt=""
            />
            <div class="box_title">固定网国内数据传送业务</div>
            <div class="box_contents">
              营业性演出是指以营利为目的为公众举办的现场文艺表演活动。
              年检时间：每年1-3月 营业性演出许可证北京不年检，其他地区不确定!
            </div>
            <div
              style="display: flex; align-items: center; margin: 35px 0 20px"
            >
              <div
                style="
                  background: #EE3D33;
                  flex: 1;
                  text-align: center;
                  padding: 7px 0;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin-right: 10px;
                  cursor: pointer;
                "
                @click="dialogVisible = true"
              >
                查看详情
              </div>
              <div
                style="
                  flex: 1;
                  text-align: center;
                  padding: 7px 0;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #EE3D33;
                  margin-right: 10px;
                  border: 1px solid #EE3D33;
                  cursor: pointer;
                "
                @click="dialogVisible = true"
              >
                立即咨询
              </div>
            </div>
          </div>
          <div style="flex: 1"></div>

          <div style="flex: 1"></div>

          <div style="flex: 1"></div>
        </div>

        <div
          style="
            font-family: Source Han Sans CN;
            font-weight: bold;
            font-size: 24px;
            color: #333333;
            padding: 20px 0;
            border-bottom: 1px solid #D0D0D0;
            position: relative;
            margin-top: 30px;
          "
        >
          <div class="title"></div>
          视听行业年检
        </div>
        <div style="display: flex; margin-top: 20px">
          <div class="box_content">
            <img
              style="width: 300px"
              src="@/assets/img/zzbl/27/14.png"
              alt=""
            />
            <div class="box_title">摄制电影许可证</div>
            <div class="box_contents">
              国家对电影摄制、进口、出口、发行、放映和电影片公映实行许可制度，企业从事摄制电影工作就需要申请摄制电影许可证。
            </div>
            <div
              style="display: flex; align-items: center; margin: 35px 0 20px"
            >
              <div
                style="
                  background: #EE3D33;
                  flex: 1;
                  text-align: center;
                  padding: 7px 0;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin-right: 10px;
                  cursor: pointer;
                "
                @click="dialogVisible = true"
              >
                查看详情
              </div>
              <div
                style="
                  flex: 1;
                  text-align: center;
                  padding: 7px 0;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #EE3D33;
                  margin-right: 10px;
                  border: 1px solid #EE3D33;
                  cursor: pointer;
                "
                @click="dialogVisible = true"
              >
                立即咨询
              </div>
            </div>
          </div>
          <div class="box_content">
            <img
              style="width: 300px"
              src="@/assets/img/zzbl/27/15.png"
              alt=""
            />
            <div class="box_title">电影放映许可证</div>
            <div class="box_contents">
              国家对电影放映实行许可制度，未经许可，任何单位和法人不得从事电影片放映活动，电影放映经营许可证俗称：影院电影放映许可证，是设立电影放映机构的前置许可。
            </div>
            <div
              style="display: flex; align-items: center; margin: 35px 0 20px"
            >
              <div
                style="
                  background: #EE3D33;
                  flex: 1;
                  text-align: center;
                  padding: 7px 0;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin-right: 10px;
                  cursor: pointer;
                "
                @click="dialogVisible = true"
              >
                查看详情
              </div>
              <div
                style="
                  flex: 1;
                  text-align: center;
                  padding: 7px 0;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #EE3D33;
                  margin-right: 10px;
                  border: 1px solid #EE3D33;
                  cursor: pointer;
                "
                @click="dialogVisible = true"
              >
                立即咨询
              </div>
            </div>
          </div>
          <div class="box_content">
            <img
              style="width: 300px"
              src="@/assets/img/zzbl/27/16.png"
              alt=""
            />
            <div class="box_title">电影发行许可证</div>
            <div class="box_contents">
              国家对电影摄制、进口、出口、发行、放映和电影片公映实行许可制度。
            </div>
            <div
              style="display: flex; align-items: center; margin: 35px 0 20px"
            >
              <div
                style="
                  background: #EE3D33;
                  flex: 1;
                  text-align: center;
                  padding: 7px 0;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin-right: 10px;
                  cursor: pointer;
                "
                @click="dialogVisible = true"
              >
                查看详情
              </div>
              <div
                style="
                  flex: 1;
                  text-align: center;
                  padding: 7px 0;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #EE3D33;
                  margin-right: 10px;
                  border: 1px solid #EE3D33;
                  cursor: pointer;
                "
                @click="dialogVisible = true"
              >
                立即咨询
              </div>
            </div>
          </div>

          <div class="box_content">
            <img
              style="width: 300px"
              src="@/assets/img/zzbl/27/17.png"
              alt=""
            />
            <div class="box_title">广播电视节目制作许可证</div>
            <div class="box_contents">
              设立广播电视节目制作经营机构或从事专题、专栏、综艺、动画片、广播剧、电视剧等广播电视节目的制作和节目版权的交易、代理交易等活动的行为就需要申请广播电视节目制作经营许可证，简称广播电视节目制作许可证。
            </div>
            <div
              style="display: flex; align-items: center; margin: 35px 0 20px"
            >
              <div
                style="
                  background: #EE3D33;
                  flex: 1;
                  text-align: center;
                  padding: 7px 0;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin-right: 10px;
                  cursor: pointer;
                "
                @click="dialogVisible = true"
              >
                查看详情
              </div>
              <div
                style="
                  flex: 1;
                  text-align: center;
                  padding: 7px 0;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #EE3D33;
                  margin-right: 10px;
                  border: 1px solid #EE3D33;
                  cursor: pointer;
                "
                @click="dialogVisible = true"
              >
                立即咨询
              </div>
            </div>
          </div>
        </div>

        <div
          style="
            font-family: Source Han Sans CN;
            font-weight: bold;
            font-size: 24px;
            color: #333333;
            padding: 20px 0;
            border-bottom: 1px solid #D0D0D0;
            position: relative;
            margin-top: 30px;
          "
        >
          <div class="title"></div>
          出版行业年检
        </div>
        <div style="display: flex; margin-top: 20px">
          <div class="box_content">
            <img
              style="width: 300px"
              src="@/assets/img/zzbl/27/14.png"
              alt=""
            />
            <div class="box_title">出版物经营许可证</div>
            <div class="box_contents">
              国家对出版物发行依法实行许可制度，未经许可，任何单位和个人不得从事出版物发行活动。企业从事出版物发行就必须申请出版物经营许可证，简称出版物许可证。
            </div>
            <div
              style="display: flex; align-items: center; margin: 35px 0 20px"
            >
              <div
                style="
                  background: #EE3D33;
                  flex: 1;
                  text-align: center;
                  padding: 7px 0;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin-right: 10px;
                  cursor: pointer;
                "
                @click="dialogVisible = true"
              >
                查看详情
              </div>
              <div
                style="
                  flex: 1;
                  text-align: center;
                  padding: 7px 0;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #EE3D33;
                  margin-right: 10px;
                  border: 1px solid #EE3D33;
                  cursor: pointer;
                "
                @click="dialogVisible = true"
              >
                立即咨询
              </div>
            </div>
          </div>
          <div class="box_content">
            <img
              style="width: 300px"
              src="@/assets/img/zzbl/27/15.png"
              alt=""
            />
            <div class="box_title">网络出版许可证</div>
            <div class="box_contents">
              网络出版服务是指通过信息网络向公众提供网络出版物，从事网络出版服务，必须依法经过出版行政主管部门批准，取得网络出版许可证（旧称互联网出版许可证）。
            </div>
            <div
              style="display: flex; align-items: center; margin: 35px 0 20px"
            >
              <div
                style="
                  background: #EE3D33;
                  flex: 1;
                  text-align: center;
                  padding: 7px 0;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin-right: 10px;
                  cursor: pointer;
                "
                @click="dialogVisible = true"
              >
                查看详情
              </div>
              <div
                style="
                  flex: 1;
                  text-align: center;
                  padding: 7px 0;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #EE3D33;
                  margin-right: 10px;
                  border: 1px solid #EE3D33;
                  cursor: pointer;
                "
                @click="dialogVisible = true"
              >
                立即咨询
              </div>
            </div>
          </div>
          <div style="flex: 1"></div>
          <div style="flex: 1"></div>
        </div>

        <div
          style="
            font-family: Source Han Sans CN;
            font-weight: bold;
            font-size: 24px;
            color: #333333;
            padding: 20px 0;
            border-bottom: 1px solid #D0D0D0;
            position: relative;
            margin-top: 30px;
          "
        >
          <div class="title"></div>
          人力资源行业年检
        </div>
        <div style="display: flex; margin-top: 20px">
          <div class="box_content">
            <img
              style="width: 300px"
              src="@/assets/img/zzbl/27/18.png"
              alt=""
            />
            <div class="box_title">人力资源服务许可证</div>
            <div class="box_contents">
              是人才中介服务机构成立的前提，人才中介服务机构是指为用人单位和人才提供中介服务及其他相关服务的专营或兼营的组织，企业从事人力资源服务就需要申请人力资源服务许可证。
              年检时间：2017年1月16至3月25日
            </div>
            <div
              style="display: flex; align-items: center; margin: 35px 0 20px"
            >
              <div
                style="
                  background: #EE3D33;
                  flex: 1;
                  text-align: center;
                  padding: 7px 0;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin-right: 10px;
                  cursor: pointer;
                "
                @click="dialogVisible = true"
              >
                查看详情
              </div>
              <div
                style="
                  flex: 1;
                  text-align: center;
                  padding: 7px 0;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #EE3D33;
                  margin-right: 10px;
                  border: 1px solid #EE3D33;
                  cursor: pointer;
                "
                @click="dialogVisible = true"
              >
                立即咨询
              </div>
            </div>
          </div>
          <div class="box_content">
            <img
              style="width: 300px"
              src="@/assets/img/zzbl/27/19.png"
              alt=""
            />
            <div class="box_title">劳务派遣行政许可证</div>
            <div class="box_contents">
              又称人才派遣、人才租赁、劳动派遣、劳动力租赁、雇员租赁，经营劳务派遣业务，应当向所在地有许可管辖权的人力资源社会保障行政部门依法申请行政许可。
              年检时间：每年1-3月
            </div>
            <div
              style="display: flex; align-items: center; margin: 35px 0 20px"
            >
              <div
                style="
                  background: #EE3D33;
                  flex: 1;
                  text-align: center;
                  padding: 7px 0;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin-right: 10px;
                  cursor: pointer;
                "
                @click="dialogVisible = true"
              >
                查看详情
              </div>
              <div
                style="
                  flex: 1;
                  text-align: center;
                  padding: 7px 0;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #EE3D33;
                  margin-right: 10px;
                  border: 1px solid #EE3D33;
                  cursor: pointer;
                "
                @click="dialogVisible = true"
              >
                立即咨询
              </div>
            </div>
          </div>
          <div style="flex: 1"></div>
          <div style="flex: 1"></div>
        </div>

        <div
          style="
            font-family: Source Han Sans CN;
            font-weight: bold;
            font-size: 24px;
            color: #333333;
            padding: 20px 0;
            border-bottom: 1px solid #D0D0D0;
            position: relative;
            margin-top: 30px;
          "
        >
          <div class="title"></div>
          工商年检
        </div>
        <div style="display: flex; margin-top: 20px">
          <div class="box_content">
            <img
              style="width: 300px"
              src="@/assets/img/zzbl/27/20.png"
              alt=""
            />
            <div class="box_title">工商年检</div>
            <div class="box_contents">
              企业、个体工商户和农民专业合作社应当通过企业信用信息公示系统报送上一年度年度报告。
              法定年检时间：每年3月1日至6月30日
            </div>
            <div
              style="display: flex; align-items: center; margin: 35px 0 20px"
            >
              <div
                style="
                  background: #EE3D33;
                  flex: 1;
                  text-align: center;
                  padding: 7px 0;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin-right: 10px;
                  cursor: pointer;
                "
                @click="dialogVisible = true"
              >
                查看详情
              </div>
              <div
                style="
                  flex: 1;
                  text-align: center;
                  padding: 7px 0;
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #EE3D33;
                  margin-right: 10px;
                  border: 1px solid #EE3D33;
                  cursor: pointer;
                "
                @click="dialogVisible = true"
              >
                立即咨询
              </div>
            </div>
          </div>

          <div style="flex: 1"></div>
          <div style="flex: 1"></div>
          <div style="flex: 1"></div>
        </div>
      </div>

      <Dialog @Visible="Visible" :dialogVisible="dialogVisible"></Dialog>
    </div>

    <bottomWiedt
      :bottomTitle="bottomTitle"
      style="margin-top: 50px"
    ></bottomWiedt>
  </div>
</template>
<script>
import middleWiedt from "./middleWiedt.vue";
import bottomWiedt from "./bottomWiedt.vue";
import Dialog from "@/components/dialog";

export default {
  components: {
    middleWiedt,
    bottomWiedt,
    Dialog,
  },
  data() {
    return {
      dialogVisible: false,
      middleImg: "27",
      middleList: [],
      middleTitle: "企业资质年检业务启动",
      middleContent:
        "增值电信业务经营许可证年检：ICP许可证年检、SP许可证年检、IDC许可证年检、ISP许可证年检、EDI许可证年检、码号年报营业性演出许可证许可证年检、摄制电影许可证年检、出版物经营许可证年检、网络出版许可证年检、人力资源服务许可证年检、劳务派遣行政许可证年检",
      bottomTitle: "创业前沿资讯",

      list: [
        {
          img: "1",
          title: "增值电信业务经营许可证年检",
          content:
            "“信息服务业务（仅含互联网）”资质，官方标准称为增值电信业务经营许可证（仅限互联网信息服务）。业内俗称为ICP许可证、经营性ICP。年检时间：每年1-3月（注：全网3-6月）",
        },
        {
          img: "2",
          title: "SP信息服务业务(不含互联网)",
          content:
            "“信息服务业务（不含互联网）”资质，官方标准称为增值电信业务经营许可证（不含互联网信息服务）。业内俗称为SP许可证。年检时间：每年1-3月（注：全网3-6月）",
        },
        {
          img: "3",
          title: "SP信息服务业务(不含互联网)",
          content:
            "是通过境内设立呼叫中心平台，为境内外单位提供面向国内用户的呼叫中心业务。从事呼叫中心业务需申请呼叫中心经营许可证，简称呼叫中心许可证、呼叫中心牌照。年检时间：每年1-3月（注：全网3-6月）",
        },
        {
          img: "4",
          title: "SP信息服务业务(不含互联网)",
          content:
            "是利用各种与公用通信网相连的数据与交易事务处理应用平台，通过公用通信网互联网为用户提供在线数据处理和交易/事物处理的业务牌照，简称edi证或edi资质。年检时间：每年1-3月（注：全网3-6月）",
        },
      ],

      lists: [
        {
          img: "1",
          title: "CDN内容分发网络业务",
          content:
            "CDN内容分发网络业务是利用分布在不同区域服务器群组成流量分配管理网络平台，提高用户访问速度的服务。年检时间：每年1-3月（注：全网3-6月）",
        },
        {
          img: "2",
          title: "ISP互联网接入服务业务",
          content:
            "ISP互联网接入服务业务是为各类用户提供接入互联网的服务。年检时间：每年1-3月（注：全网3-6月）",
        },
        {
          img: "3",
          title: "IDC互联网数据中心业务",
          content:
            "是指利用相应的机房设施，以外包出租的方式为用户的服务器等互联网或其他网络相关设备提供放置、代理维护及管理服务，从事IDC业务就需要申请IDC许可证，简称IDC证年检时间：每年1-3月（注：全网3-6月）",
        },
        {
          img: "4",
          title: "码号",
          content:
            "电信网码号其目的在于为有效利用电信网码号资源，保障公平竞争，促进电信事业的健康发展。",
        },
      ],

      list3: [
        {
          img: "1",
          title: "CDN内容分发网络业务",
          content:
            "CDN内容分发网络业务是利用分布在不同区域服务器群组成流量分配管理网络平台，提高用户访问速度的服务。年检时间：每年1-3月（注：全网3-6月）",
        },
        {
          img: "2",
          title: "ISP互联网接入服务业务",
          content:
            "ISP互联网接入服务业务是为各类用户提供接入互联网的服务。年检时间：每年1-3月（注：全网3-6月）",
        },
        {
          img: "3",
          title: "IDC互联网数据中心业务",
          content:
            "是指利用相应的机房设施，以外包出租的方式为用户的服务器等互联网或其他网络相关设备提供放置、代理维护及管理服务，从事IDC业务就需要申请IDC许可证，简称IDC证年检时间：每年1-3月（注：全网3-6月）",
        },
        {},
      ],
    };
  },
  methods: {
    Visible() {
      this.dialogVisible = false;
    },
  },
};
</script>
<style scoped>
.box_contents {
  height: 130px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 16px;
  color: #666666;
}
.box_title {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  margin: 10px 0;
}
.box_content {
  flex: 1;
  border: 1px solid #D2D2D2;
  padding: 10px;
  margin-right: 15px;
}
.content_title {
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
}
.content {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 18px;
  color: #333333;
}
.content div {
  margin-bottom: 15px;
}
.title {
  height: 5px;
  width: 75px;
  background-color: #F55656;
  position: absolute;
  bottom: -3px;
}
.content_title {
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
}
.content {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 18px;
  color: #333333;
}
.content div {
  margin-bottom: 15px;
}
.title {
  height: 5px;
  width: 75px;
  background-color: #F55656;
  position: absolute;
  bottom: -3px;
}
.xk_content .none {
  width: 135px;
  height: 135px;
}
.none {
  width: 100%;
  height: 280px;
}
.kp div {
  margin-right: 15px;
  flex: 1;
}
.title_middle {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  text-align: center;
}
.xk_t {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
.youjiantou {
  margin-top: 60px;
  height: 20px;
  width: 80px;
}
.xk_title {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  margin: 10px 0;
}
.xk_content {
  text-align: center;
  flex: 1;
}

.ICP_content {
  text-align: center;
  flex: 1;
  margin: 30px;
  border-right: 2px solid #ccc;
}
.title {
  text-align: center;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 30px;
  color: #333333;
}
</style>