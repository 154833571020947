<template>
  <div
    :style="{ width: screenWidth + 'px' }"
    style="height: 800px; margin: auto"
  >
    <div class="bg">
      <div style="padding-top: 50px; position: relative">
        <img
          style="
            height: 80px;
            width: 100px;
            position: absolute;
            top: 10px;
            left: 38%;
          "
          src="@/assets/img/evaluate/2.png"
          alt=""
        />
        <el-card :style="{ width: screenWidth - 20 + 'px' }" style="height: 130px; margin: 0 auto">
          <div
            style="
              font-family: Source Han Sans CN;
              font-weight: bold;
              font-size: 20px;
              color: #EE3D33;
              margin-top: 25px;
            "
          >
            邀请您对服务评价
          </div>
          <div
            style="
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #434343;
              margin-top: 10px;
            "
          >
            真诚邀请您对本次的服务做出评价
          </div>
        </el-card>

        <el-card :style="{ width: screenWidth - 20 + 'px' }" style="min-height: 460px; margin: 10px auto 0">
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              margin-bottom: 15px;
            "
          >
            <img
              style="width: 18px; height: 18px; margin-right: 10px"
              src="@/assets/img/evaluate/3.png"
              alt=""
            />
            <div style="margin-right: 30px" class="title">服务评价</div>
            <div>
              <el-rate v-model="rateValue1"></el-rate>
            </div>
          </div>
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              margin-bottom: 15px;
            "
          >
            <img
              style="width: 18px; height: 18px; margin-right: 10px"
              src="@/assets/img/evaluate/4.png"
              alt=""
            />
            <div style="margin-right: 30px" class="title">效率评价</div>
            <div>
              <el-rate v-model="rateValue2"></el-rate>
            </div>
          </div>
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              margin-bottom: 15px;
            "
          >
            <img
              style="width: 18px; height: 18px; margin-right: 10px"
              src="@/assets/img/evaluate/5.png"
              alt=""
            />
            <div style="margin-right: 30px" class="title">专业评价</div>
            <div>
              <el-rate v-model="rateValue3"></el-rate>
            </div>
          </div>

          <el-input
            type="textarea"
            :rows="5"
            placeholder="投诉建议:
本次评价对改进客户体验非常重要，本次对设计师及 客服不可见。"
            v-model="textarea"
          >
          </el-input>

          <div
            style="
              width: 260px;
              height: 35px;
              line-height: 35px;
              text-align: center;
              margin: 0 auto;
              background-color: #ee3d33;
              color: #fff;
              border-radius: 30px;
              margin-top: 50px;
              cursor: pointer;
            "
            @click="submit()"
          >
            提交
          </div>

          <div style="margin-top: 20px">
            <img
              style="width: 100px; height: 30px"
              src="@/assets/img/evaluate/8.png"
              alt=""
            />
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      screenWidth: 0,
      screenHeight: 0,

      rateValue1: 0,
      rateValue2: 0,
      rateValue3: 0,
      textarea: "",
    };
  },
  created() {},
  mounted() {
    this.updateScreenWidth();
    window.addEventListener("resize", this.updateScreenWidth);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateScreenWidth);
  },
  methods: {
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
      this.screenHeight = window.innerHeight;
    },
    submit() {
      const url = "https://admin.atqf.cn/prod-api/atqf/evaluation";
      const options = {
        method: "POST", // 请求方法
        headers: {
          "Content-Type": "application/json", // 设置请求头
        },
        body: JSON.stringify({
          firstScore: this.rateValue1,
          secondScore: this.rateValue2,
          thirdScore: this.rateValue3,
          proposal: this.textarea,
        }), // 设置请求体
      };

      fetch(url, options)
        .then((response) => response.json())
        .then((res) => {
          if (res.code == 200) {
            this.$message("提交成功！");
          } else {
            this.$message(res.msg);
          }
        })
        .catch((error) => console.error("Error:", error));
    },
  },
};
</script>
<style scoped>
::v-deep .el-textarea__inner {
  border: 1px solid #fff !important;
  background-color: #f8f8f8 !important;
  width: 280px !important;
  margin: 0 auto !important;
  font-size: 14px !important;
  color: #AAAAAA !important;
}
::v-deep .el-icon-star-off {
  color: #c43931 !important;
}
::v-deep .el-icon-star-on {
  color: transparent !important;
  /* 使用伪元素和线性渐变来制作渐变文字 */
  background-image: linear-gradient(
    to right,
    #fddacc,
    #f6956d,
    #f7835f,
    #ef4538
  ) !important;
  -webkit-background-clip: text !important;
  background-clip: text !important;
}

.title {
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #434343;
  font-size: 18px;
}
.bg {
  text-align: center;
  width: 100%;
  height: 150px;
  background-image: url("~@/assets/img/evaluate/1.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
</style>