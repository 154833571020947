<template>
  <div style="margin-top: 120px">
    <Nav></Nav>
    <div style="width: 2000px; margin: 0 auto">
      <div>
        <img
          style="height: 400px; width: 100%"
          src="https://atqf.oss-cn-beijing.aliyuncs.com/file/2024-07-11/banner.jpg"
          alt=""
        />
      </div>

      <div style="width: 1600px; margin: 0 auto; margin-top: 50px">
        <div style="margin-top: 70px; height: 750px; padding-bottom: 30px">
          <div style="background-color: #fff">
            <div
              style="
                text-align: center;
                font-family: SourceHanSansCN-Bold;
                margin: 30px 0 10px 0;
                font-size: 37px;
                font-weight: bold;
              "
            >
              工商服务在您身边
            </div>

            <div
              style="
                margin: 10px 0 30px 0;
                text-align: center;
                font-family: Source Han Sans CN;
                font-weight: bold;
                font-size: 16px;
                color: #ADADAD;
              "
            >
              以诚待客、效率为先、积极进取、追求完美
            </div>

            <div style="width: 1600px; margin: 0 auto; background-color: #fff">
              <div class="kh_content">
                <div>
                  <img
                    style="width: 100px; height: 100px"
                    src="@/assets/img/gscs/c_1.png"
                    alt=""
                  />
                  <div
                    style="
                      width: 45px;
                      height: 2px;
                      background-color: #333333;
                      margin: 0 auto 30px;
                    "
                  ></div>
                  <div
                    style="
                      margin: 0 auto;
                      height: 50px;
                      font-size: 18px;
                      color: #333333;
                    "
                  >
                    注册公司
                  </div>
                </div>
                <div>
                  <img
                    style="width: 100px; height: 100px"
                    src="@/assets/img/gscs/c_2.png"
                    alt=""
                  />
                  <div
                    style="
                      width: 45px;
                      height: 2px;
                      background-color: #333333;
                      margin: 0 auto 30px;
                    "
                  ></div>
                  <div
                    style="
                      margin: 0 auto;
                      height: 50px;
                      font-size: 18px;
                      color: #333333;
                    "
                  >
                    自贸区注册
                  </div>
                </div>
                <div>
                  <img
                    style="width: 100px; height: 100px"
                    src="@/assets/img/gscs/c_3.png"
                    alt=""
                  />
                  <div
                    style="
                      width: 45px;
                      height: 2px;
                      background-color: #333333;
                      margin: 0 auto 30px;
                    "
                  ></div>
                  <div
                    style="
                      margin: 0 auto;
                      height: 50px;
                      font-size: 18px;
                      color: #333333;
                    "
                  >
                    变更注册
                  </div>
                </div>
                <div>
                  <img
                    style="width: 100px; height: 100px"
                    src="@/assets/img/gscs/c_4.png"
                    alt=""
                  />
                  <div
                    style="
                      width: 45px;
                      height: 2px;
                      background-color: #333333;
                      margin: 0 auto 30px;
                    "
                  ></div>
                  <div
                    style="
                      margin: 0 auto;
                      height: 50px;
                      font-size: 18px;
                      color: #333333;
                    "
                  >
                    代理记账
                  </div>
                </div>
              </div>
              <div class="kh_content">
                <div>
                  <img
                    style="width: 100px; height: 100px"
                    src="@/assets/img/gscs/c_5.png"
                    alt=""
                  />
                  <div
                    style="
                      width: 45px;
                      height: 2px;
                      background-color: #333333;
                      margin: 0 auto 30px;
                    "
                  ></div>
                  <div
                    style="
                      margin: 0 auto;
                      height: 50px;
                      font-size: 18px;
                      color: #333333;
                    "
                  >
                    专项审批
                  </div>
                </div>
                <div>
                  <img
                    style="width: 100px; height: 100px"
                    src="@/assets/img/gscs/c_6.png"
                    alt=""
                  />
                  <div
                    style="
                      width: 45px;
                      height: 2px;
                      background-color: #333333;
                      margin: 0 auto 30px;
                    "
                  ></div>
                  <div
                    style="
                      margin: 0 auto;
                      height: 50px;
                      font-size: 18px;
                      color: #333333;
                    "
                  >
                    吊销转注销
                  </div>
                </div>
                <div>
                  <img
                    style="width: 100px; height: 100px"
                    src="@/assets/img/gscs/c_7.png"
                    alt=""
                  />
                  <div
                    style="
                      width: 45px;
                      height: 2px;
                      background-color: #333333;
                      margin: 0 auto 30px;
                    "
                  ></div>
                  <div
                    style="
                      margin: 0 auto;
                      height: 50px;
                      font-size: 18px;
                      color: #333333;
                    "
                  >
                    集团公司
                  </div>
                </div>
                <div>
                  <img
                    style="width: 100px; height: 100px"
                    src="@/assets/img/gscs/c_8.png"
                    alt=""
                  />
                  <div
                    style="
                      width: 45px;
                      height: 2px;
                      background-color: #333333;
                      margin: 0 auto 30px;
                    "
                  ></div>
                  <div
                    style="
                      margin: 0 auto;
                      height: 50px;
                      font-size: 18px;
                      color: #333333;
                    "
                  >
                    执照收购
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            @click="dialogVisible = true"
            style="
              border: 2px solid #E7242A;
              width: 450px;
              text-align: center;
              padding: 12px 0;
              color: #E7242A;
              font-weight: bold;
              border-radius: 30px;
              font-size: 18px;
              margin: 50px auto;
              cursor: pointer;
            "
          >
            点击咨询
          </div>
        </div>
      </div>

      <div style="width: 100%; margin: 0 auto; margin-top: 50px">
        <div style="height: 600px" class="g_bg">
          <div
            style="
              width: 1600px;
              margin: 0 auto;
              padding-top: 30px;
              position: relative;
            "
          >
            <div
              style="
                text-align: center;
                font-family: SourceHanSansCN-Bold;
                margin: 30px 0 10px 0;
                font-size: 37px;
                font-weight: bold;
                color: #fff;
              "
            >
              公司注册流程
            </div>

            <div
              style="
                margin: 10px 0 30px 0;
                text-align: center;
                font-family: Source Han Sans CN;
                font-weight: bold;
                font-size: 16px;
                color: #fff;
              "
            >
              您只需要提供资料，剩下的交于我们办理
            </div>

            <div style="margin-top: 80px; margin-left: 45px">
              <div style="display: flex; align-items: center">
                <div style="text-align: center">
                  <div>
                    <img
                      style="width: 150px; height: 150px"
                      src="@/assets/img/gscs/g_2.png"
                      alt=""
                    />
                  </div>
                  <div
                    style="
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      font-size: 18px;
                      color: #FFFFFF;
                      margin-top: 20px;
                    "
                  >
                    公司核名
                  </div>
                </div>

                <div style="margin: 0 30px">
                  <img
                    style="width: 60px; height: 25px"
                    src="@/assets/img/gscs/youjiantou.png"
                    alt=""
                  />
                </div>
                <div style="text-align: center">
                  <div>
                    <img
                      style="width: 150px; height: 150px"
                      src="@/assets/img/gscs/g_3.png"
                      alt=""
                    />
                  </div>
                  <div
                    style="
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      font-size: 18px;
                      color: #FFFFFF;
                      margin-top: 20px;
                    "
                  >
                    约号
                  </div>
                </div>

                <div style="margin: 0 30px">
                  <img
                    style="width: 60px; height: 25px"
                    src="@/assets/img/gscs/youjiantou.png"
                    alt=""
                  />
                </div>
                <div style="text-align: center">
                  <div>
                    <img
                      style="width: 150px; height: 150px"
                      src="@/assets/img/gscs/g_4.png"
                      alt=""
                    />
                  </div>
                  <div
                    style="
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      font-size: 18px;
                      color: #FFFFFF;
                      margin-top: 20px;
                    "
                  >
                    交件
                  </div>
                </div>

                <div style="margin: 0 30px">
                  <img
                    style="width: 60px; height: 25px"
                    src="@/assets/img/gscs/youjiantou.png"
                    alt=""
                  />
                </div>
                <div style="text-align: center">
                  <div>
                    <img
                      style="width: 150px; height: 150px"
                      src="@/assets/img/gscs/g_4.png"
                      alt=""
                    />
                  </div>
                  <div
                    style="
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      font-size: 18px;
                      color: #FFFFFF;
                      margin-top: 20px;
                    "
                  >
                    营业执照
                  </div>
                </div>

                <div style="margin: 0 30px">
                  <img
                    style="width: 60px; height: 25px"
                    src="@/assets/img/gscs/youjiantou.png"
                    alt=""
                  />
                </div>
                <div style="text-align: center">
                  <div>
                    <img
                      style="width: 150px; height: 150px"
                      src="@/assets/img/gscs/g_5.png"
                      alt=""
                    />
                  </div>
                  <div
                    style="
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      font-size: 18px;
                      color: #FFFFFF;
                      margin-top: 20px;
                    "
                  >
                    刻章
                  </div>
                </div>

                <div style="margin: 0 30px">
                  <img
                    style="width: 60px; height: 25px"
                    src="@/assets/img/gscs/youjiantou.png"
                    alt=""
                  />
                </div>
                <div style="text-align: center">
                  <div>
                    <img
                      style="width: 150px; height: 150px"
                      src="@/assets/img/gscs/g_6.png"
                      alt=""
                    />
                  </div>
                  <div
                    style="
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      font-size: 18px;
                      color: #FFFFFF;
                      margin-top: 20px;
                    "
                  >
                    开户
                  </div>
                </div>
              </div>
            </div>

            <div
              style="width: 1600px; margin: 0 auto; height: 200px"
              class="g_last"
            >
              <img
                style="
                  margin-left: 50px;
                  width: 30px;
                  height: 30px;
                  margin-left: 400px;
                  margin-top: 75px;
                "
                src="@/assets/img/home/dianhua.png"
                alt=""
              />
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 24px;
                  color: #333333;
                  margin-top: 75px;
                  margin-left: 10px;
                "
              >
                拨打免费热线由您的专属顾问来帮您
              </div>

              <div
                style="
                  cursor: pointer;
                  padding: 10px 50px;
                  background-color: #E7242A;
                  color: #fff;
                  text-align: center;
                  border-radius: 30px;
                  margin-left: auto;
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #FFFFFF;
                  margin-right: 50px;
                  cursor: pointer;
                  margin-top: 75px;
                "
                @click="dialogVisible = true"
              >
                立即咨询
              </div>
            </div>
          </div>
        </div>

        <div style="background-color: #f8f8f8; height: 750px">
          <div style="width: 1600px; margin: 0 auto; padding-top: 120px">
            <div
              style="
                text-align: center;
                font-family: Source Han Sans CN;
                font-weight: bold;
                font-size: 30px;
                color: #333333;
                margin-bottom: 50px;
              "
            >
              爱听企服优势
            </div>

            <div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <div style="width: 40%; display: flex">
                  <div
                    style="
                      margin-left: auto;
                      width: 200px;
                      background-color: #e24540;
                      border-radius: 30px;
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      font-size: 24px;
                      color: #FFFFFF;
                      padding: 5px 30px;
                      text-align: center;
                    "
                  >
                    爱听企服代理优势
                  </div>
                </div>
                <div style="width: 20%; text-align: center">
                  <img
                    style="height: 100px; width: 70px"
                    src="@/assets/img/gscs/a_1.png"
                    alt=""
                  />
                </div>

                <div style="width: 40%">
                  <div
                    style="
                      text-align: center;
                      width: 200px;
                      background-color: #e5e5e5;
                      border-radius: 30px;
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      font-size: 24px;
                      padding: 5px 30px;
                    "
                  >
                    其他代理机构
                  </div>
                </div>
              </div>

              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-bottom: 10px;
                "
              >
                <div
                  style="
                    background-color: #e24540;
                    border-radius: 30px;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    font-size: 24px;
                    color: #FFFFFF;
                    padding: 5px 30px;
                    display: flex;
                    align-items: center;
                    width: 40%;
                  "
                >
                  <div class="num">01</div>
                  <div style="margin-left: auto" class="title">
                    享有更多的政府财政扶植
                  </div>
                </div>
                <div style="display: flex; align-items: center; width: 20%">
                  <div
                    style="
                      flex: 1;
                      height: 1px;
                      width: 100%;
                      border-bottom: 3px dashed #ccc;
                      margin: 0 15px;
                    "
                  ></div>
                  <div style="flex: 1">
                    <img src="@/assets/img/gscs/a_2.png" alt="" />
                  </div>
                  <div
                    style="
                      flex: 1;
                      height: 1px;
                      width: 100%;
                      border-bottom: 3px dashed #ccc;
                      margin: 0 15px;
                    "
                  ></div>
                </div>

                <div
                  style="
                    width: 40%;
                    background-color: #e5e5e5;
                    border-radius: 30px;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    font-size: 18px;
                    padding: 5px 30px;
                    color: #333333;
                    display: flex;
                    align-items: center;
                  "
                >
                  <div>其他代理机构</div>
                  <div
                    style="
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      font-size: 24px;
                      color: #333333;
                      font-style: italic;
                      margin-left: auto;
                    "
                  >
                    01
                  </div>
                </div>
              </div>

              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-bottom: 10px;
                "
              >
                <div
                  style="
                    background-color: #e24540;
                    border-radius: 30px;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    font-size: 24px;
                    color: #FFFFFF;
                    padding: 5px 30px;
                    display: flex;
                    align-items: center;
                    width: 40%;
                  "
                >
                  <div class="num">02</div>
                  <div style="margin-left: auto" class="title">
                    足不出户，快递直达
                  </div>
                </div>
                <div style="display: flex; align-items: center; width: 20%">
                  <div
                    style="
                      flex: 1;
                      height: 1px;
                      width: 100%;
                      border-bottom: 3px dashed #ccc;
                      margin: 0 15px;
                    "
                  ></div>
                  <div style="flex: 1">
                    <img src="@/assets/img/gscs/a_3.png" alt="" />
                  </div>
                  <div
                    style="
                      flex: 1;
                      height: 1px;
                      width: 100%;
                      border-bottom: 3px dashed #ccc;
                      margin: 0 15px;
                    "
                  ></div>
                </div>

                <div
                  style="
                    width: 40%;
                    background-color: #e5e5e5;
                    border-radius: 30px;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    font-size: 18px;
                    padding: 5px 30px;
                    color: #333333;
                    display: flex;
                    align-items: center;
                  "
                >
                  <div>未经许可无证经营</div>
                  <div
                    style="
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      font-size: 24px;
                      color: #333333;
                      font-style: italic;
                      margin-left: auto;
                    "
                  >
                    02
                  </div>
                </div>
              </div>

              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-bottom: 10px;
                "
              >
                <div
                  style="
                    background-color: #e24540;
                    border-radius: 30px;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    font-size: 24px;
                    color: #FFFFFF;
                    padding: 5px 30px;
                    display: flex;
                    align-items: center;
                    width: 40%;
                  "
                >
                  <div class="num">03</div>
                  <div style="margin-left: auto" class="title">
                    专业信息保密机制，安全有保障
                  </div>
                </div>
                <div style="display: flex; align-items: center; width: 20%">
                  <div
                    style="
                      flex: 1;
                      height: 1px;
                      width: 100%;
                      border-bottom: 3px dashed #ccc;
                      margin: 0 15px;
                    "
                  ></div>
                  <div style="flex: 1">
                    <img src="@/assets/img/gscs/a_4.png" alt="" />
                  </div>
                  <div
                    style="
                      flex: 1;
                      height: 1px;
                      width: 100%;
                      border-bottom: 3px dashed #ccc;
                      margin: 0 15px;
                    "
                  ></div>
                </div>

                <div
                  style="
                    width: 40%;
                    background-color: #e5e5e5;
                    border-radius: 30px;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    font-size: 18px;
                    padding: 5px 30px;
                    color: #333333;
                    display: flex;
                    align-items: center;
                  "
                >
                  <div>外包服务，安全性较低</div>
                  <div
                    style="
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      font-size: 24px;
                      color: #333333;
                      font-style: italic;
                      margin-left: auto;
                    "
                  >
                    03
                  </div>
                </div>
              </div>

              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-bottom: 10px;
                "
              >
                <div
                  style="
                    background-color: #e24540;
                    border-radius: 30px;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    font-size: 24px;
                    color: #FFFFFF;
                    padding: 5px 30px;
                    display: flex;
                    align-items: center;
                    width: 40%;
                  "
                >
                  <div class="num">04</div>
                  <div style="margin-left: auto" class="title">
                    专业客服一对- -贴心服务
                  </div>
                </div>
                <div style="display: flex; align-items: center; width: 20%">
                  <div
                    style="
                      flex: 1;
                      height: 1px;
                      width: 100%;
                      border-bottom: 3px dashed #ccc;
                      margin: 0 15px;
                    "
                  ></div>
                  <div style="flex: 1">
                    <img src="@/assets/img/gscs/a_5.png" alt="" />
                  </div>
                  <div
                    style="
                      flex: 1;
                      height: 1px;
                      width: 100%;
                      border-bottom: 3px dashed #ccc;
                      margin: 0 15px;
                    "
                  ></div>
                </div>

                <div
                  style="
                    width: 40%;
                    background-color: #e5e5e5;
                    border-radius: 30px;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    font-size: 18px;
                    padding: 5px 30px;
                    color: #333333;
                    display: flex;
                    align-items: center;
                  "
                >
                  <div>响应慢，态度冷漠</div>
                  <div
                    style="
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      font-size: 24px;
                      color: #333333;
                      font-style: italic;
                      margin-left: auto;
                    "
                  >
                    04
                  </div>
                </div>
              </div>

              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-bottom: 10px;
                "
              >
                <div
                  style="
                    background-color: #e24540;
                    border-radius: 30px;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    font-size: 24px;
                    color: #FFFFFF;
                    padding: 5px 30px;
                    display: flex;
                    align-items: center;
                    width: 40%;
                  "
                >
                  <div class="num">05</div>
                  <div style="margin-left: auto" class="title">
                    价格透明，无隐形消费
                  </div>
                </div>
                <div style="display: flex; align-items: center; width: 20%">
                  <div
                    style="
                      flex: 1;
                      height: 1px;
                      width: 100%;
                      border-bottom: 3px dashed #ccc;
                      margin: 0 15px;
                    "
                  ></div>
                  <div style="flex: 1">
                    <img src="@/assets/img/gscs/a_6.png" alt="" />
                  </div>
                  <div
                    style="
                      flex: 1;
                      height: 1px;
                      width: 100%;
                      border-bottom: 3px dashed #ccc;
                      margin: 0 15px;
                    "
                  ></div>
                </div>

                <div
                  style="
                    width: 40%;
                    background-color: #e5e5e5;
                    border-radius: 30px;
                    font-family: Source Han Sans CN;
                    font-weight: bold;
                    font-size: 18px;
                    padding: 5px 30px;
                    color: #333333;
                    display: flex;
                    align-items: center;
                  "
                >
                  <div>价格透明，无隐形消费</div>
                  <div
                    style="
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      font-size: 24px;
                      color: #333333;
                      font-style: italic;
                      margin-left: auto;
                    "
                  >
                    05
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style="width: 1600px; margin: 0 auto; height: 500px">
          <div
            style="
              margin: 70px 0 50px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              font-size: 30px;
              color: #333333;
              text-align: center;
            "
          >
            我们的成果
          </div>

          <div style="display: flex; align-items: center; height: 350px">
            <div
              style="
                flex: 1;
                height: 100%;
                margin-right: 25px;
                border: 1px solid #D0D0D0;
                padding: 15px;
                border-radius: 5px;
              "
            >
              <img
                style="height: 300px; width: 100%"
                src="@/assets/img/gscs/z_1.png"
                alt=""
              />
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin-top: 20px;
                "
              >
                营业执照(正副本)
              </div>
            </div>

            <div
              style="
                flex: 1;
                height: 100%;
                margin-right: 25px;
                border: 1px solid #D0D0D0;
                padding: 15px;
              "
            >
              <img
                style="height: 300px; width: 100%"
                src="@/assets/img/gscs/z_2.png"
                alt=""
              />
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin-top: 20px;
                "
              >
                企业统一社会信用代码证
              </div>
            </div>
            <div
              style="
                flex: 1;
                height: 100%;
                margin-right: 25px;
                border: 1px solid #D0D0D0;
                padding: 15px;
              "
            >
              <img
                style="height: 300px; width: 100%"
                src="@/assets/img/gscs/z_3.png"
                alt=""
              />
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin-top: 20px;
                "
              >
                公司章程
              </div>
            </div>
            <div
              style="
                flex: 1;
                height: 100%;
                margin-right: 25px;
                border: 1px solid #D0D0D0;
                padding: 15px;
              "
            >
              <img
                style="height: 300px; width: 100%"
                src="@/assets/img/gscs/z_4.png"
                alt=""
              />
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin-top: 20px;
                "
              >
                房屋租赁合同公
              </div>
            </div>
            <div
              style="
                flex: 1;
                height: 100%;
                margin-right: 25px;
                border: 1px solid #D0D0D0;
                padding: 15px;
              "
            >
              <img
                style="height: 300px; width: 100%"
                src="@/assets/img/gscs/z_4.png"
                alt=""
              />
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin-top: 20px;
                "
              >
                司印章及其他资料
              </div>
            </div>
          </div>
        </div>

        <div style="width: 2000px; background-color: #f8f8f8; margin: 0 auto">
          <div
            style="
              margin-top: 50px;
              width: 1600px;
              margin: 0 auto;
              height: 950px;
            "
          >
            <div
              style="
                padding: 50px 0;
                font-family: Source Han Sans CN;
                font-weight: bold;
                font-size: 30px;
                color: #333333;
                text-align: center;
              "
            >
              热门业务
            </div>

            <div
              style="
                display: flex;
                height: 400px;
                align-items: end;
                text-align: center;
              "
            >
              <div style="flex: 1; margin-right: 20px; position: relative">
                <img
                  style="height: 320px; width: 100%"
                  src="@/assets/img/gscs/r_1.png"
                  alt=""
                />
                <div
                  style="
                    text-align: center;
                    position: absolute;
                    top: 30px;
                    left: 19%;
                  "
                >
                  <img src="@/assets/img/gscs/r_7.png" alt="" />
                  <div
                    style="
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      font-size: 24px;
                      color: #fff;
                      margin: 20px 0;
                    "
                  >
                    科技公司注册
                  </div>
                  <div
                    style="
                      background-color: #fff;
                      height: 54px;
                      line-height: 54px;
                      width: 320px;
                      text-align: center;
                      margin: 0 auto;
                      border-radius: 30px;
                      border: 1px solid #D0D0D0;
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      font-size: 16px;
                      cursor: pointer;
                    "
                    @click="dialogVisible = true"
                  >
                    点击咨询
                  </div>
                </div>
              </div>
              <div style="flex: 1; margin-right: 20px; text-align: center">
                <img
                  style="height: 400px"
                  src="@/assets/img/gscs/r_2.png"
                  alt=""
                />
              </div>
              <div style="flex: 1; position: relative">
                <img
                  style="height: 320px; width: 100%"
                  src="@/assets/img/gscs/r_1.png"
                  alt=""
                />
                <div
                  style="
                    text-align: center;
                    position: absolute;
                    top: 30px;
                    left: 19%;
                  "
                >
                  <img src="@/assets/img/gscs/r_9.png" alt="" />
                  <div
                    style="
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      font-size: 24px;
                      color: #fff;
                      margin: 20px 0;
                    "
                  >
                    文化传媒公司注册
                  </div>
                  <div
                    style="
                      background-color: #fff;
                      height: 54px;
                      line-height: 54px;
                      width: 320px;
                      text-align: center;
                      margin: 0 auto;
                      border-radius: 30px;
                      border: 1px solid #D0D0D0;
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      font-size: 16px;
                      cursor: pointer;
                    "
                    @click="dialogVisible = true"
                  >
                    点击咨询
                  </div>
                </div>
              </div>
            </div>

            <div
              style="
                display: flex;
                height: 350px;
                align-items: end;
                text-align: center;
              "
            >
              <div style="flex: 1; margin-right: 20px; position: relative">
                <img
                  style="height: 320px; width: 100%"
                  src="@/assets/img/gscs/r_1.png"
                  alt=""
                />
                <div
                  style="
                    text-align: center;
                    position: absolute;
                    top: 30px;
                    left: 19%;
                  "
                >
                  <img src="@/assets/img/gscs/r_10.png" alt="" />
                  <div
                    style="
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      font-size: 24px;
                      color: #fff;
                      margin: 20px 0;
                    "
                  >
                    网络公司注册
                  </div>
                  <div
                    style="
                      background-color: #fff;
                      height: 54px;
                      line-height: 54px;
                      width: 320px;
                      text-align: center;
                      margin: 0 auto;
                      border-radius: 30px;
                      border: 1px solid #D0D0D0;
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      font-size: 16px;
                      cursor: pointer;
                    "
                    @click="dialogVisible = true"
                  >
                    点击咨询
                  </div>
                </div>
              </div>
              <div style="flex: 1; margin-right: 20px; position: relative">
                <img
                  style="height: 320px; width: 100%"
                  src="@/assets/img/gscs/r_1.png"
                  alt=""
                />
                <div
                  style="
                    text-align: center;
                    position: absolute;
                    top: 30px;
                    left: 19%;
                  "
                >
                  <img src="@/assets/img/gscs/r_11.png" alt="" />
                  <div
                    style="
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      font-size: 24px;
                      color: #fff;
                      margin: 20px 0;
                    "
                  >
                    金融公司注册
                  </div>
                  <div
                    style="
                      background-color: #fff;
                      height: 54px;
                      line-height: 54px;
                      width: 320px;
                      text-align: center;
                      margin: 0 auto;
                      border-radius: 30px;
                      border: 1px solid #D0D0D0;
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      font-size: 16px;
                      cursor: pointer;
                    "
                    @click="dialogVisible = true"
                  >
                    点击咨询
                  </div>
                </div>
              </div>
              <div style="flex: 1; position: relative">
                <img
                  style="height: 320px; width: 100%"
                  src="@/assets/img/gscs/r_1.png"
                  alt=""
                />
                <div
                  style="
                    text-align: center;
                    position: absolute;
                    top: 30px;
                    left: 19%;
                  "
                >
                  <img src="@/assets/img/gscs/r_7.png" alt="" />
                  <div
                    style="
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      font-size: 24px;
                      color: #fff;
                      margin: 20px 0;
                    "
                  >
                    通信工程类公司注册
                  </div>
                  <div
                    style="
                      background-color: #fff;
                      height: 54px;
                      line-height: 54px;
                      width: 320px;
                      text-align: center;
                      margin: 0 auto;
                      border-radius: 30px;
                      border: 1px solid #D0D0D0;
                      font-family: Source Han Sans CN;
                      font-weight: bold;
                      font-size: 16px;
                      cursor: pointer;
                    "
                    @click="dialogVisible = true"
                  >
                    点击咨询
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <bottomWiedt
          :bottomTitle="bottomTitle"
          style="margin-top: 80px"
        ></bottomWiedt>
      </div>
    </div>

    <Footer></Footer>
    <Dialog @Visible="Visible" :dialogVisible="dialogVisible"></Dialog>
  </div>
</template>
<script>
import Footer from "@/components/footer";
import Nav from "@/components/nav";
import bottomWiedt from "@/views/components/qualifications/bottomWiedt";
import Dialog from "@/components/dialog";
export default {
  components: {
    Footer,
    Nav,
    Dialog,
    bottomWiedt
  },
  data() {
    return {
      dialogVisible: false,
      bottomTitle: "创业前沿资讯",
    };
  },
  methods: {
    Visible() {
      this.dialogVisible = false;
    },
  },
};
</script>
<style scoped>
.title {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 18px;
  color: #FFFFFF;
}
.num {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 24px;
  color: #FFFFFF;
  line-height: 29px;
  font-style: italic;
}
.g_last {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  position: absolute;
  bottom: -240px;
  background-image: url("~@/assets/img/home/kj_1.png");
  background-size: cover;
  width: 100%;
  height: 100%; /* 或具体像素值，如 500px */
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 999999;
}
.g_bg {
  border-radius: 5px;
  background-image: url("https://atqf.oss-cn-beijing.aliyuncs.com/file/2024-07-12/g_1.jpg");
  background-size: cover;
  width: 100%;
  height: 100%; /* 或具体像素值，如 500px */
  background-position: center center;
  background-repeat: no-repeat;
}
.kh_content {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.kh_content div {
  background-color: #ebf3ff;
  height: 260px;
  flex: 1;
  margin-right: 20px;
  border-radius: 8px;
  text-align: center;
}
.kh_content div img {
  margin: 30px auto;
}
</style>