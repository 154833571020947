<template>
  <div>
    <div style="width: 1400px; margin: 0 auto">
      <middleWiedt
        :middleImg="middleImg"
        :middleTitle="middleTitle"
        :middleContent="middleContent"
        :middleList="middleList"
      ></middleWiedt>

      <div style="margin-bottom: 100px; margin-top: 50px">
        <div style="margin: 0 40px 40px">
          <div class="title" style="margin-bottom: 40px">
            艺术品经营单位备案服务明细
          </div>
          <div
            style="
              background-color: #fff;
              display: flex;
              justify-content: center;
            "
          >
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/2.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                申请
              </div>
            </div>
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/3.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                变更/增项
              </div>
            </div>
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/4.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                续期/注销
              </div>
            </div>
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/5.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                转让
              </div>
            </div>
          </div>
        </div>
        <div class="title" style="margin-bottom: 35px">
          艺术品经营单位备案办理服务流程
        </div>
        <div style="display: flex">
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/6.png" alt="" />
            <div class="xk_title">咨询业务意向</div>
            <div class="xk_t">（确定您办理的业务类型）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/7.png" alt="" />
            <div class="xk_title">商务合作洽谈</div>
            <div class="xk_t">（为您量身定制解决方案）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/8.png" alt="" />
            <div class="xk_title">基础材料准备</div>
            <div class="xk_t">（协助您准备所需硬性材料）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/9.png" alt="" />
            <div class="xk_title">提交审核</div>
            <div class="xk_t">（剩下的事就交给我们了）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/10.png" alt="" />
            <div class="xk_title">领取许可证</div>
          </div>
        </div>
      </div>
    </div>
    <bottomWiedt  style="margin-top: 50px;" :bottomTitle="bottomTitle"></bottomWiedt>
  </div>
</template>
<script>
import middleWiedt from "./middleWiedt.vue";
import bottomWiedt from "./bottomWiedt.vue";

export default {
  components: {
    middleWiedt,
    bottomWiedt,
  },
  data() {
    return {
      middleImg: "32",
      middleList: [
        {
          title: "哪些企业需要美术品进出口经营活动审批",
          content: [
            {
              one:
                "从境外进口或者向境外出口艺术品的，应当在艺术品进出口前，提出申请。",
              two:
                "以销售、商业宣传为目的在境内公共展览场所举办有境外艺术品创作者或者境外艺术品参加的展示活动，应当由举办单位于展览日45日 前提出申请。",
            },
          ],
          remerk:
            "从境外进口或向境外出口美术品的经营活动，分为两类:一般性美术品进出口经营活动、涉外商业美术品展览活动。",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "备案需要哪些材料",
          content: [
            {
              one: "1、营业执照、对外贸易经营者备案登记表",
              two: "2、进出口艺术品的来源、目的地",
              three: "3、艺术品图录",
              four: "4、主办或者承办单位的营业执照、对外贸易经营者备案登记表",
              five: "5、参展的境外艺术品创作者或者境外参展单位的名录",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "禁止经营含有以下内容的艺术品",
          content: [
            {
              one: "(一)走私、盗窃等来源不合法的艺术品",
              two: "(二)伪造、变造或者冒充他人名义的艺术品",
              three:
                "(三)除有合法手续、准许经营的以外，法律、法规禁止交易的动物、植物、矿物、金属、化石等为材质的艺术品",
              four: "(四)国家规定禁止交易的其他艺术品",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
      ],
      middleTitle: "美术品进出口经营活动审批",
      middleContent:
        "美术品进出口经营活动是指从境外进口或向境外出口美术品的经营活动。企业从事美术品进出口经营活动就需要申请美术品进出口经营活动许可证。注：同一批已经批准进口或出口的美术品复出口或复进口，进口单位可持原批准文件正本到原进口或出口口岸海关办理相关手续，文化行政部门不再重复审批。如与原批准文件内容不符，则应当到文化行政部门重新办理审批手续。",
      bottomTitle: "创业前沿资讯",
    };
  },
};
</script>
<style scoped>
.kp div {
  margin-right: 15px;
  flex: 1;
}
.title_middle {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  text-align: center;
}
.xk_t {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
.youjiantou {
  margin-top: 60px;
  height: 20px;
  width: 80px;
}
.xk_title {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  margin: 10px 0;
}
.xk_content {
  text-align: center;
  flex: 1;
}
.xk_content .none {
  width: 135px;
  height: 135px;
}
.ICP_content {
  text-align: center;
  flex: 1;
  margin: 30px;
}
.titles {
  height: 5px;
  width: 75px;
  background-color: #F55656;
  position: absolute;
  bottom: -3px;
}
.title {
  text-align: center;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 30px;
  color: #333333;
}
</style>