<template>
  <div>
    <div style="width: 1400px; margin: 0 auto">
      <middleWiedt
        :middleImg="middleImg"
        :middleTitle="middleTitle"
        :middleContent="middleContent"
        :middleList="middleList"
      ></middleWiedt>

      <div style="width: 1400px; margin: 0 auto 50px; padding-bottom: 30px">
        <div style="margin-bottom: 90px">
          <div class="title" style="margin: 50px 0">
            营业性演出许可证办理需要准备的材料
          </div>
          <div style="display: flex; align-items: center" class="kp">
            <div style="position: relative">
              <img class="none" src="@/assets/img/zzbl/four/3.png" alt="" />
              <div
                style="
                  position: absolute;
                  text-align: center;
                  left: 10%;
                  top: 25%;
                "
              >
                <div
                  style="
                    width: 65px;
                    height: 65px;
                    line-height: 65px;
                    background: #F4F4F4;
                    border-radius: 50%;
                    border: 1px solid #EE3D33;
                    font-family: Alimama ShuHeiTi;
                    font-weight: bold;
                    font-size: 28px;
                    color: #EE3D33;
                    font-style: italic;
                    background-color: #fff;
                    margin: 0 auto;
                  "
                >
                  01
                </div>
                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 18px;
                    color: #FFFFFF;
                    margin: 15px 0;
                  "
                >
                  演出经纪机构名称预先核准通知书或营业执照副本原件及复印件盖公章
                </div>
              </div>
            </div>
            <div style="position: relative">
              <img class="none" src="@/assets/img/zzbl/four/4.png" alt="" />
              <div
                style="
                  position: absolute;
                  text-align: center;
                  left: 13%;
                  top: 25%;
                "
              >
                <div
                  style="
                    width: 65px;
                    height: 65px;
                    line-height: 65px;
                    background: #F4F4F4;
                    border-radius: 50%;
                    border: 1px solid #EE3D33;
                    font-family: Alimama ShuHeiTi;
                    font-weight: bold;
                    font-size: 28px;
                    color: #EE3D33;
                    font-style: italic;
                    background-color: #fff;
                    margin: 0 auto;
                  "
                >
                  02
                </div>
                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 18px;
                    color: #FFFFFF;
                    margin: 15px 0;
                  "
                >
                  法定代表人简历、邮箱、座机、手机、邮编
                </div>
              </div>
            </div>
            <div style="position: relative">
              <img class="none" src="@/assets/img/zzbl/four/5.png" alt="" />
              <div
                style="
                  position: absolute;
                  text-align: center;
                  left: 5%;
                  top: 25%;
                "
              >
                <div
                  style="
                    width: 65px;
                    height: 65px;
                    line-height: 65px;
                    background: #F4F4F4;
                    border-radius: 50%;
                    border: 1px solid #EE3D33;
                    font-family: Alimama ShuHeiTi;
                    font-weight: bold;
                    font-size: 28px;
                    color: #EE3D33;
                    font-style: italic;
                    background-color: #fff;
                    margin: 0 auto;
                  "
                >
                  03
                </div>
                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 18px;
                    color: #FFFFFF;
                    margin: 15px 0;
                  "
                >
                  法定代表人和主要负责人的身份证明（身份证复印件、护照复印件）
                </div>
              </div>
            </div>
          </div>

          <div style="display: flex; align-items: center" class="kp">
            <div style="position: relative">
              <img class="none" src="@/assets/img/zzbl/four/6.png" alt="" />
              <div
                style="
                  position: absolute;
                  text-align: center;
                  left: 5%;
                  top: 25%;
                "
              >
                <div
                  style="
                    width: 65px;
                    height: 65px;
                    line-height: 65px;
                    background: #F4F4F4;
                    border-radius: 50%;
                    border: 1px solid #EE3D33;
                    font-family: Alimama ShuHeiTi;
                    font-weight: bold;
                    font-size: 28px;
                    color: #EE3D33;
                    font-style: italic;
                    background-color: #fff;
                    margin: 0 auto;
                  "
                >
                  04
                </div>
                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 18px;
                    color: #FFFFFF;
                    margin: 15px 0;
                  "
                >
                  公司最近经会计师事务所审计的企业法人年度财务会计报告或验资报告，电信主管部门规定的其他相关会计资料
                </div>
              </div>
            </div>
            <div style="position: relative">
              <img class="none" src="@/assets/img/zzbl/four/7.png" alt="" />
              <div
                style="
                  position: absolute;
                  text-align: center;
                  left: 5%;
                  top: 25%;
                "
              >
                <div
                  style="
                    width: 65px;
                    height: 65px;
                    line-height: 65px;
                    background: #F4F4F4;
                    border-radius: 50%;
                    border: 1px solid #EE3D33;
                    font-family: Alimama ShuHeiTi;
                    font-weight: bold;
                    font-size: 28px;
                    color: #EE3D33;
                    font-style: italic;
                    background-color: #fff;
                    margin: 0 auto;
                  "
                >
                  05
                </div>
                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 18px;
                    color: #FFFFFF;
                    margin: 15px 0;
                  "
                >
                  公司章程，公司股权结构及股东的有关情况
                </div>
              </div>
            </div>
            <div style="position: relative">
              <img class="none" src="@/assets/img/zzbl/four/8.png" alt="" />
              <div
                style="
                  position: absolute;
                  text-align: center;
                  left: 15%;
                  top: 25%;
                "
              >
                <div
                  style="
                    width: 65px;
                    height: 65px;
                    line-height: 65px;
                    background: #F4F4F4;
                    border-radius: 50%;
                    border: 1px solid #EE3D33;
                    font-family: Alimama ShuHeiTi;
                    font-weight: bold;
                    font-size: 28px;
                    color: #EE3D33;
                    font-style: italic;
                    background-color: #fff;
                    margin: 0 auto;
                  "
                >
                  06
                </div>
                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 18px;
                    color: #FFFFFF;
                    margin: 15px 0;
                  "
                >
                  公司法定代表人签署的公司依法经营电信业务的承诺书
                </div>
              </div>
            </div>
            <div style="position: relative">
              <img class="none" src="@/assets/img/zzbl/five/3.png" alt="" />
              <div
                style="
                  position: absolute;
                  text-align: center;
                  left: 15%;
                  top: 25%;
                "
              >
                <div
                  style="
                    width: 65px;
                    height: 65px;
                    line-height: 65px;
                    background: #F4F4F4;
                    border-radius: 50%;
                    border: 1px solid #EE3D33;
                    font-family: Alimama ShuHeiTi;
                    font-weight: bold;
                    font-size: 28px;
                    color: #EE3D33;
                    font-style: italic;
                    background-color: #fff;
                    margin: 0 auto;
                  "
                >
                  07
                </div>
                <div
                  style="
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    font-size: 18px;
                    color: #FFFFFF;
                    margin: 15px 0;
                  "
                >
                  申请经营的电信业务依照法律、行政法规及国家有关规定须经有关主管部门事先审核同意的，应当提交有关主管部门审核同意的文件
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style="margin: 50px 40px 40px">
          <div class="title" style="margin-bottom: 40px">
            营业性演出许可证服务
          </div>
          <div
            style="
              background-color: #fff;
              display: flex;
              justify-content: center;
            "
          >
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/2.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                申请
              </div>
            </div>
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/3.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                变更/增项
              </div>
            </div>
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/4.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                续期/注销
              </div>
            </div>
            <div class="ICP_content">
              <div
                style="
                  background-color: #f4f4f4;
                  width: 130px;
                  height: 130px;
                  margin: 0 auto;
                "
              >
                <img
                  style="margin-top: 25px"
                  src="@/assets/img/zzbl/one/5.png"
                  alt=""
                />
              </div>
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 18px;
                  color: #333333;
                  margin: 20px 0;
                "
              >
                转让
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="margin-bottom: 100px">
        <div class="title" style="margin-bottom: 50px">
          营业性演出许可证办理服务流程
        </div>
        <div style="display: flex">
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/6.png" alt="" />
            <div class="xk_title">咨询业务意向</div>
            <div class="xk_t">（确定您办理的业务类型）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/7.png" alt="" />
            <div class="xk_title">商务合作洽谈</div>
            <div class="xk_t">（为您量身定制解决方案）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/8.png" alt="" />
            <div class="xk_title">基础材料准备</div>
            <div class="xk_t">（协助您准备所需硬性材料）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/9.png" alt="" />
            <div class="xk_title">提交审核</div>
            <div class="xk_t">（剩下的事就交给我们了）</div>
          </div>
          <div class="xk_content">
            <img
              class="youjiantou"
              src="@/assets/img/zzbl/one/youjiantou.png"
              alt=""
            />
          </div>
          <div class="xk_content">
            <img class="none" src="@/assets/img/zzbl/one/10.png" alt="" />
            <div class="xk_title">领取许可证</div>
          </div>
        </div>
      </div>
    </div>
    <bottomWiedt style="margin-top: 50px;" :bottomTitle="bottomTitle"></bottomWiedt>
  </div>
</template>
<script>
import middleWiedt from "./middleWiedt.vue";
import bottomWiedt from "./bottomWiedt.vue";

export default {
  components: {
    middleWiedt,
    bottomWiedt,
  },
  data() {
    return {
      middleImg: "20",
      middleList: [
        {
          title: "营业性演出许可证办理机构",
          content: [
            {
              one: "文化局",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "未申请营业性演出许可证开展业务有何处罚?",
          content: [
            {
              one:
                "未经批准举办营业性演出的，由县级人民政府文化主管部门责令停止演出，没收违法所得，并处违法所得8倍以上10倍以下的罚款;没有违法所得或者违法所得不足1万元的，并处5万元以上10万元以下的罚款;情节严重的，由原发证机关吊销营业性演出许可证。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "营业性演出许可办理条件",
          content: [
            {
              one: "(一)经营范围里必须要有演出经纪",
              two: "(二)港澳台外资除外需另外添加条件",
              three:
                "(三)实体办公地址(不需要核查,最好不要提供集中区的注册地址)",
              four: "(四)有3名以上专职演出经纪人员(演出经纪资格证)。",
            },
          ],
          remerk: "",
          icon: false,
          img: "20",
          iconImg: "",
        },
        {
          title: "办理营业性演出许可证注意事项",
          content: [
            {
              one: "(一)内资在公司注册所在地文化局办理;",
              two:
                "(二)中外合资，中方必须占51%以上，办理由当地文化局报文化部审批;",
              three:
                "(三)公司有两年以上的演出举办经历方可办理外国人来华演出审批;",
              four: "(四)公司办理了演出经纪机构许可证后方可签约艺人",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
      ],
      middleTitle: "营业性演出许可证",
      middleContent:
        "营业性演出许可证指的是具有开展经营性演出活动申请资格的承办者，在通过了文化部或者各地文化局审核后取得的一种允许其可以正式演出的演出许可性证书。其中，申请资格指的是取得文艺性演出许可的文艺表演团体、演出场所以及个体演员，方可从事各类营业性演出活动。营业性演出是指以营利为目的为公众举办的现场文艺表演活动，无论是国外来华的演出，还是大陆、港澳台的演出，都属于营业性演出。提供演出经纪资格证人员挂靠咨询",
      bottomTitle: "创业前沿资讯",
    };
  },
};
</script>
<style scoped>
.xk_content .none {
  width: 135px;
  height: 135px;
}
.none {
  width: 100%;
  height: 280px;
}
.kp div {
  margin-right: 15px;
  flex: 1;
}
.title_middle {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  text-align: center;
}
.xk_t {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
.youjiantou {
  margin-top: 60px;
  height: 20px;
  width: 80px;
}
.xk_title {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  margin: 10px 0;
}
.xk_content {
  text-align: center;
  flex: 1;
}

.ICP_content {
  text-align: center;
  flex: 1;
  margin: 30px;
  border-right: 2px solid #ccc;
}
.title {
  text-align: center;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 30px;
  color: #333333;
}
</style>