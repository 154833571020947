<template>
  <div>
    <div style="width: 1400px; margin: 0 auto">
      <middleWiedt
        :middleImg="middleImg"
        :middleTitle="middleTitle"
        :middleContent="middleContent"
        :middleList="middleList"
      ></middleWiedt>
    </div>
    <bottomWiedt
      :bottomTitle="bottomTitle"
      style="margin-top: 50px"
    ></bottomWiedt>
  </div>
</template>
<script>
import middleWiedt from "./middleWiedt.vue";
import bottomWiedt from "./bottomWiedt.vue";

export default {
  components: {
    middleWiedt,
    bottomWiedt,
  },
  data() {
    return {
      middleImg: "26",
      middleList: [
        {
          title: "申请商业特许经营备案法律依据",
          content: [
            {
              one: "《商业特许经营企业备案》(商务部令2004年第 25号)",
              two: "《商业特许经营管理条例》(国务院令第485号)",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "不办理商业特许经营备案擅自开展业务有什么后果",
          content: [
            {
              one:
                "特许人不具申请条件，从事特许经营活动的，由商务主管部门责令改正，没收违法所得，处10万元以上50万元以下的罚款，并予以公告。企业以外的其他单位和个人作为特许人从事特许经营活动的，由商务主管部门责令停止非法经营活动，没收违法所得，并处10万元以上50万元以下的罚款。",
              two:
                "特许人未依照规定向商务主管部门备案的，由商务主管部门责令限期备案，处1万元以上5万元以下的罚款;逾期仍不备案的，处5万元以上10万元以下的罚款，并予以公告。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
        {
          title: "办理商业特许经营备案申请条件",
          content: [
            {
              one: "一、特许人向商务主管部门备案，应当提交下列文件、资料:",
              two: "(一)营业执照复印件或者企业登记(注册)证书复印件1份:",
              three: "(二)特许经营合同样本;",
              four: "(三)特许经营操作手册;",
              five: "(四)市场计划书;",
              six:
                "(五)表明其符合《商业特许经营管理条例》第七条规定(第七条 特许人从事特许经营活动应当拥有成熟的经营模式，并具备为被特许人持续提供经营指导、技术支持和业务培训等服务的能力。)的书面承诺及相关证明材料;",
              seven: "(六)国务院商务主管部门规定的其他文件、资料。",
              eight:
                "特许经营的产品或者服务，依法应当经批准方可经营的，特许人还应当提交有关批准文件。",
            },
          ],
          remerk: "",
          icon: false,
          img: "",
          iconImg: "",
        },
      ],
      middleTitle: "商业特许经营企业备案",
      middleContent:
        "商业特许经营(以下简称特许经营)，是指拥有注册商标、企业标志、专利、专有技术等经营资源的企业(以下称特许人)，以合同形式将其拥有的经营资源许可其他经营者(以下称被特许人)使用，被特许人按照合同约定在统一的经营模式下开展经营，并向特许人支付特许经营费用的经营活动。通企业以外的其他单位和个人不得作为特许人从事特许经营活动。",
      bottomTitle: "创业前沿资讯",
    };
  },
};
</script>
<style scoped>
.xk_content .none {
  width: 135px;
  height: 135px;
}
.none {
  width: 100%;
  height: 260px;
}
.kp div {
  margin-right: 15px;
  flex: 1;
}
.title_middle {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  text-align: center;
}
.xk_t {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}
.youjiantou {
  margin-top: 60px;
  height: 20px;
  width: 80px;
}
.xk_title {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  margin: 10px 0;
}
.xk_content {
  text-align: center;
  flex: 1;
}

.ICP_content {
  text-align: center;
  flex: 1;
  margin: 30px;
  border-right: 2px solid #ccc;
}
.title {
  text-align: center;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 30px;
  color: #333333;
}
</style>