<template>
  <div>
    <div style="width: 2000px; margin: 0 auto" class="bg">
      <div style="position: relative">
        <div
          style="
            width: 778px;
            height: 246px;
            background: #000000;
            border-radius: 12px;
            opacity: 0.45;
            position: absolute;
            left: 30%;
            top: 100px;
          "
        ></div>
        <div style="position: absolute; left: 37%; top: 135px">
          <div
            style="
              height: 48px;
              font-family: Alimama ShuHeiTi;
              font-weight: bold;
              font-size: 51px;
              color: #FFFFFF;
            "
          >
            办理高新培育及认定
          </div>

          <div style="display: flex; align-items: center; margin-top: 50px">
            <div style="display: flex; align-items: center">
              <span
                style="
                  display: inline-block;
                  width: 9px;
                  height: 9px;
                  background: #FFFFFF;
                  margin-right: 10px;
                "
              ></span>
              <span
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                "
                >多年办理经验</span
              >
            </div>

            <div style="display: flex; align-items: center; margin-left: 140px">
              <span
                style="
                  display: inline-block;
                  width: 9px;
                  height: 9px;
                  background: #FFFFFF;
                  margin-right: 10px;
                "
              ></span>
              <span
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                "
                >多年办理经验</span
              >
            </div>
          </div>

          <div style="display: flex; align-items: center; margin-top: 30px">
            <div style="display: flex; align-items: center">
              <span
                style="
                  display: inline-block;
                  width: 9px;
                  height: 9px;
                  background: #FFFFFF;
                  margin-right: 10px;
                "
              ></span>
              <span
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                "
                >多年办理经验</span
              >
            </div>

            <div style="display: flex; align-items: center; margin-left: 140px">
              <span
                style="
                  display: inline-block;
                  width: 9px;
                  height: 9px;
                  background: #FFFFFF;
                  margin-right: 10px;
                "
              ></span>
              <span
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #FFFFFF;
                "
                >多年办理经验</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="width: 1600px; margin: 0 auto; padding-top: 35px">
      <div style="display: flex">
        <div>
          <img
            src="https://atqf.oss-cn-beijing.aliyuncs.com/file/2024-07-12/2.jpg"
            alt=""
          />
        </div>
        <div style="margin-left: 30px">
          <div class="top_title">是什么</div>
          <div class="top_content">
            在《国家重点支持的高新技术领域》，持续进行研究开发与技术成果转化，形成企业核心自主知识产权，并以此为基础开展经营活动，在中国境内（不包括港、澳、台地区）注册一年以上的居民企业。
          </div>

          <div class="top_title">哪些部门审核</div>
          <div class="top_content">市（科技部、财政部、税务局）</div>

          <div class="top_title">办理依据</div>
          <div class="top_content">《高新技术企业认定管理办法》</div>
          <div class="top_content">《高新技术企业认定管理工作指引》</div>
          <div class="top_content">
            《北京市高新技术企业认定管理工作实施方案》
          </div>

          <div class="top_title">有效期及复审要求</div>
          <div class="top_content">
            高新技术企业资格自颁发证书之日起有效期为三年。企业应在期满前三个月内提出复审申请，不提出复审申请或复审不合格的，其高新技术企业资格到期自动失效。
          </div>
        </div>
      </div>

      <div
        style="
          padding: 50px 0 30px 0;
          font-family: Source Han Sans CN;
          font-weight: bold;
          font-size: 24px;
          color: #333333;
          text-align: center;
          position: relative;
        "
      >
        <div
          style="
            height: 6px;
            width: 75px;
            background-color: #F55656;
            position: absolute;
            bottom: 0;
            left: 48%;
          "
        ></div>
        国家重点支持的高新技术领域
      </div>

      <div style="display: flex; align-items: center; margin-top: 35px">
        <div class="gj_content">
          <div style="margin: 55px">
            <img src="@/assets/img/propertyRight/3/10.png" alt="" />
          </div>
          <div class="gj_text">电子信息技术</div>
        </div>

        <div class="gj_content">
          <div style="margin: 55px">
            <img src="@/assets/img/propertyRight/3/11.png" alt="" />
          </div>
          <div class="gj_text">生物与新医药技术</div>
        </div>
        <div class="gj_content">
          <div style="margin: 55px">
            <img src="@/assets/img/propertyRight/3/12.png" alt="" />
          </div>
          <div class="gj_text">航空航天技术</div>
        </div>
        <div class="gj_content">
          <div style="margin: 55px">
            <img src="@/assets/img/propertyRight/3/13.png" alt="" />
          </div>
          <div class="gj_text">新材料技术</div>
        </div>
      </div>

      <div style="display: flex; align-items: center; margin-top: 35px">
        <div class="gj_content">
          <div style="margin: 55px">
            <img src="@/assets/img/propertyRight/3/14.png" alt="" />
          </div>
          <div class="gj_text">高技术服务业</div>
        </div>

        <div class="gj_content">
          <div style="margin: 55px">
            <img src="@/assets/img/propertyRight/3/15.png" alt="" />
          </div>
          <div class="gj_text">新能源及节能技术</div>
        </div>
        <div class="gj_content">
          <div style="margin: 55px">
            <img src="@/assets/img/propertyRight/3/16.png" alt="" />
          </div>
          <div class="gj_text">资源与环境技术</div>
        </div>
        <div class="gj_content">
          <div style="margin: 55px">
            <img src="@/assets/img/propertyRight/3/17.png" alt="" />
          </div>
          <div class="gj_text">高新技术改造传统产业</div>
        </div>
      </div>
    </div>

    <div style="width: 2000px; margin: 80px auto 0" class="middle_bg">
      <div style="width: 1600px; margin: 0 auto">
        <div
          style="
            padding: 50px 0;
            font-family: Source Han Sans CN;
            font-weight: bold;
            font-size: 24px;
            color: #fff;
            text-align: center;
            position: relative;
          "
        >
          <div
            style="
              height: 6px;
              width: 75px;
              background-color: #F55656;
              position: absolute;
              bottom: 0;
              left: 48%;
            "
          ></div>
          高新认证的好处
        </div>

        <div style="display: flex; margin-top: 30px">
          <div class="gx_content">
            <div style="position: absolute; top: 30px; left: 10px">
              <img src="@/assets/img/propertyRight/3/18.png" alt="" />
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 24px;
                  color: #FFFFFF;
                  margin-top: 10px;
                "
              >
                促进企业科技转型
              </div>
            </div>
            <img
              src="https://atqf.oss-cn-beijing.aliyuncs.com/file/2024-07-12/3.jpg"
              alt=""
            />
          </div>
          <div class="gx_content">
            <div style="position: absolute; top: 30px; left: 10px">
              <img src="@/assets/img/propertyRight/3/19.png" alt="" />
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 24px;
                  color: #FFFFFF;
                  margin-top: 10px;
                "
              >
                提高企业资本价值
              </div>
            </div>
            <img
              src="https://atqf.oss-cn-beijing.aliyuncs.com/file/2024-07-12/4.jpg"
              alt=""
            />
          </div>
          <div class="gx_content">
            <div style="position: absolute; top: 30px; left: 10px">
              <img src="@/assets/img/propertyRight/3/20.png" alt="" />
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 24px;
                  color: #FFFFFF;
                  margin-top: 10px;
                "
              >
                提升企业品牌形象
              </div>
            </div>
            <img
              src="https://atqf.oss-cn-beijing.aliyuncs.com/file/2024-07-12/5.jpg"
              alt=""
            />
          </div>
          <div class="gx_content">
            <div style="position: absolute; top: 30px; left: 10px">
              <img src="@/assets/img/propertyRight/3/21.png" alt="" />
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 24px;
                  color: #FFFFFF;
                  margin-top: 10px;
                "
              >
                享受税收减免优惠政策
              </div>
            </div>
            <img
              src="https://atqf.oss-cn-beijing.aliyuncs.com/file/2024-07-12/s.jpg"
              alt=""
            />
          </div>
          <div class="gx_content">
            <div style="position: absolute; top: 30px; left: 10px">
              <img src="@/assets/img/propertyRight/3/22.png" alt="" />
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 24px;
                  color: #FFFFFF;
                  margin-top: 10px;
                "
              >
                提高企业市场价值
              </div>
            </div>
            <img
              src="https://atqf.oss-cn-beijing.aliyuncs.com/file/2024-07-12/w.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>

    <div style="width: 1600px; margin: 0 auto">
      <div
        style="
          font-family: Source Han Sans CN;
          font-weight: bold;
          font-size: 30px;
          color: #333333;
          margin: 40px 0;
          text-align: center;
        "
      >
        申请高新技术企业必备条件
      </div>

      <div style="display: flex">
        <div class="sq_content">
          <img src="@/assets/img/propertyRight/3/23.png" alt="" />
          <div class="sq_title">基础条件</div>
          <div class="sq_text">
            在中国境内（不包括港、澳、台地区） 注册满一年以上的居民企业；
          </div>
          <div class="sq_text">
            6个以上（含）实用新型专利或软件著作权， 或者1个以上发明专利。
          </div>
        </div>
        <div class="sq_content">
          <img src="@/assets/img/propertyRight/3/24.png" alt="" />
          <div class="sq_title">人员要求</div>
          <div class="sq_text">大学专科以上学历科技人员占职工总人数>30%；</div>
          <div class="sq_text">研发人员占职工总人数>10%</div>
        </div>
        <div class="sq_content">
          <img src="@/assets/img/propertyRight/3/25.png" alt="" />
          <div class="sq_title">产品收入要求</div>
          <div class="sq_text">
            上一年度的高新技术产品（服务） 收入占企业当年总收入的60%以上
          </div>
        </div>
        <div class="sq_content">
          <img src="@/assets/img/propertyRight/3/26.png" alt="" />
          <div class="sq_title">研究开发费用要求</div>
          <div class="sq_text">研究开发费用总额占销售收入总额的比例</div>
          <div class="sq_text">(1)销售收入《5000万元》6%;</div>
          <div class="sq_text">(2)销售收入5000万元~20,000万元34%;</div>
          <div class="sq_text">
            (3)销售收入>20,000万元>3%中国境内发生的研究开发费用总额/全部研发费用
            ≥60%
          </div>
        </div>
      </div>
    </div>

    <div style="width: 2000px; margin: 80px auto 0">
      <img
        style="width: 100%"
        src="https://atqf.oss-cn-beijing.aliyuncs.com/file/2024-07-12/20240712172339.jpg"
        alt=""
      />
    </div>

    <div style="width: 1600px; margin: 0 auto">
      <div
        style="
          font-family: Source Han Sans CN;
          font-weight: bold;
          font-size: 30px;
          color: #333333;
          margin: 40px 0;
          text-align: center;
        "
      >
        高新技术企业认证您需要准备
      </div>

      <div style="display: flex">
        <div class="js_content">
          <div style="margin: 80px 0">高企认定申请书</div>
        </div>
        <div class="js_content">
          <div style="margin-top: 70px">企业资质证明资料</div>
          <div>（行政部）</div>
        </div>
        <div class="js_content">
          <div style="margin-top: 70px">企业资质证明资料</div>
          <div>（行政部）</div>
        </div>
        <div class="js_content">
          <div style="margin-top: 70px">企业资质证明资料</div>
          <div>（行政部）</div>
        </div>
        <div class="js_content">
          <div style="margin-top: 70px">企业资质证明资料</div>
          <div>（行政部）</div>
        </div>
      </div>

      <div style="display: flex; margin-top: 20px">
        <div class="js_content">
          <div style="margin-top: 70px">企业职工人数、学历结构及研</div>
          <div>发人员比例等说明（行政部）</div>
        </div>
        <div class="js_content">
          <div style="margin-top: 70px">科研立项证明材料</div>
          <div>(技术部)</div>
        </div>
        <div class="js_content">
          <div style="margin-top: 70px">企业自主知识产权情况</div>
          <div>(技术部)</div>
        </div>
        <div class="js_content">
          <div style="margin-top: 70px">企业科技成果转化能力</div>
          <div>(技术部)</div>
        </div>
        <div class="js_content">
          <div style="margin-top: 70px">研究开发的组织管理</div>
          <div>水平的证明(行政部)</div>
        </div>
      </div>

      <div
        style="
          padding: 50px 0 0px 0;
          font-family: Source Han Sans CN;
          font-weight: bold;
          font-size: 24px;
          color: #333333;
          text-align: center;
          position: relative;
          margin-bottom: 50px;
          margin-top: 50px;
        "
      >
        服务保障
      </div>

      <div style="display: flex; align-items: center; margin-top: 35px">
        <div class="gj_contents" style="margin-right: 30px">
          <div style="margin: 55px">
            <img src="@/assets/img/propertyRight/3/27.png" alt="" />
          </div>
          <div class="gj_text">经验丰富</div>
          <div class="gj_texts">多年经验为您保驾护航</div>
        </div>

        <div class="gj_contents" style="margin-right: 30px">
          <div style="margin: 55px">
            <img src="@/assets/img/propertyRight/3/28.png" alt="" />
          </div>
          <div class="gj_text">生物与新医药技术</div>
          <div class="gj_texts">多年经验为您保驾护航</div>
        </div>
        <div class="gj_contents">
          <div style="margin: 55px">
            <img src="@/assets/img/propertyRight/3/29.png" alt="" />
          </div>
          <div class="gj_text">航空航天技术</div>
          <div class="gj_texts">专业对接人员,帮你节省不必要环节</div>
        </div>
      </div>

      <div style="display: flex; align-items: center; margin-top: 35px">
        <div class="gj_contents" style="margin-right: 30px">
          <div style="margin: 55px">
            <img src="@/assets/img/propertyRight/3/30.png" alt="" />
          </div>
          <div class="gj_text">高技术服务业</div>
          <div class="gj_texts">
            自有服务器保障客户数据安全 专人保障客户资料免遭外泄
          </div>
        </div>

        <div class="gj_contents" style="margin-right: 30px">
          <div style="margin: 55px">
            <img src="@/assets/img/propertyRight/3/31.png" alt="" />
          </div>
          <div class="gj_text">新能源及节能技术</div>
          <div class="gj_texts">服务均由3年以上 资深人士操作保障品质</div>
        </div>
        <div class="gj_contents">
          <div style="margin: 55px">
            <img src="@/assets/img/propertyRight/3/32.png" alt="" />
          </div>
          <div class="gj_text">资源与环境技术</div>
          <div class="gj_texts">
            售前咨询，下单5分钟内响应， 关键环节100%提醒
          </div>
        </div>
      </div>
    </div>

    <bottomWiedt
      :bottomTitle="bottomTitle"
      style="margin-top: 80px"
    ></bottomWiedt>
  </div>
</template>

<script>
import bottomWiedt from "@/views/components/qualifications/bottomWiedt";
export default {
  components: {
    bottomWiedt,
  },
  data() {
    return {
      value: "",
      value1: "",
      bottomTitle: "创业前沿资讯",
    };
  },
};
</script>
<style scoped>
.bg {
  height: 450px;
  width: 100%;
  background-image: url("https://atqf.oss-cn-beijing.aliyuncs.com/file/2024-07-25/nbxjavdbhI.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.gj_texts {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
}
.js_content {
  height: 200px;
  margin-right: 5px;
  text-align: center;
  flex: 1;
  background-color: #f2f2f2;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 16px;
  color: #333333;
}
.gx_bj {
  height: 700px;
  width: 100%;
  background-image: url("https://atqf.oss-cn-beijing.aliyuncs.com/file/2024-07-12/20240712172339.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.sq_text {
  margin-top: 10px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 16px;
  color: #666666;
}
.sq_title {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 24px;
  color: #333333;
  margin: 15px 0;
}
.sq_content {
  flex: 1;
  background-color: #f9f9f9;
  padding: 35px 20px;
  margin-right: 10px;
  height: 260px;
}
.gx_content {
  position: relative;
  flex: 1;
}
.middle_bg {
  height: 700px;
  width: 100%;
  background-image: url("https://atqf.oss-cn-beijing.aliyuncs.com/file/2024-07-12/c.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.gj_text {
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 24px;
  color: #333333;
  margin-bottom: 25px;
}
.gj_contents {
  flex: 1;
  text-align: center;
  border: 1px solid #9CB3DD;
  margin-right: 20px;
  height: 300px;
}
.gj_content {
  flex: 1;
  text-align: center;
  border: 1px solid #9CB3DD;
  margin-right: 20px;
}
.top_title {
  margin-top: 15px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  font-size: 24px;
  color: #333333;
}

.top_content {
  margin-top: 8px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
}
</style>