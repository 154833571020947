<template>
  <div style="width: 2000px; margin: 0 auto">
    <div style="width: 1600px; margin: 0 auto">
      <div style="display: flex">
        <div style="flex: 1">
          <img src="@/assets/img/propertyRight/one/1.png" alt="" />
        </div>
        <div style="flex: 1">
          <div
            style="
              font-family: Source Han Sans CN;
              font-weight: bold;
              font-size: 24px;
              color: #333333;
              padding: 20px 0;
              border-bottom: 1px solid #D0D0D0;
              position: relative;
            "
          >
            <div
              style="
                height: 5px;
                width: 75px;
                background-color: #F55656;
                position: absolute;
                bottom: -3px;
              "
            ></div>
            商标注册
          </div>

          <div style="margin: 30px 0">
            <el-input
              style="width: 420px"
              v-model="value"
              placeholder="请输入商标名称"
            ></el-input>
          </div>
          <div>
            <el-input
              style="width: 420px"
              v-model="value1"
              placeholder="请输入手机号码"
            ></el-input>
          </div>
          <div
            style="
              background: #EE3D33;
              border-radius: 8px;
              width: 420px;
              text-align: center;
              height: 42px;
              line-height: 42px;
              color: #fff;
              margin-top: 40px;
              cursor: pointer;
            "
            @click="submit()"
          >
            立即查询
          </div>
        </div>
      </div>

      <div
        style="
          background-color: #f3f3f3;
          margin: 30px 0 50px;
          min-height: 700px;
        "
      >
        <div
          style="
            font-family: Source Han Sans CN;
            font-weight: bold;
            font-size: 24px;
            color: #333333;
            padding: 20px 0;
            border-bottom: 1px solid #D0D0D0;
            position: relative;
            text-align: center;
            margin-bottom: 40px;
          "
        >
          <div
            style="
              height: 5px;
              width: 95px;
              background-color: #F55656;
              position: absolute;
              bottom: -3px;
              left: 755px;
            "
          ></div>
          商标注册
        </div>
        <div class="bg" style="position: relative">
          <div
            style="
              position: absolute;
              left: -160px;
              top: 50px;
              text-align: left;
            "
          >
            <div
              style="
                font-family: Source Han Sans CN;
                font-weight: bold;
                font-size: 24px;
                color: #333333;
              "
            >
              <i class="el-icon-circle-check" style="color: #ee3d33"></i>
              品牌创建
            </div>
            <div
              style="
                font-family: Source Han Sans CN;
                font-weight: 400;
                font-size: 16px;
                color: #333333;
                margin: 5px 0;
              "
            >
              区别商品或服务出处
            </div>
            <div
              style="
                font-family: Source Han Sans CN;
                font-weight: 400;
                font-size: 16px;
                color: #333333;
                margin: 5px 0;
              "
            >
              引导消费者认牌消费的习惯
            </div>
            <div
              style="
                font-family: Source Han Sans CN;
                font-weight: 400;
                font-size: 16px;
                color: #333333;
                margin: 5px 0;
              "
            >
              商业影响力至关重要的地位
            </div>
          </div>

          <div
            style="
              position: absolute;
              right: -220px;
              top: 50px;
              text-align: left;
            "
          >
            <div
              style="
                font-family: Source Han Sans CN;
                font-weight: bold;
                font-size: 24px;
                color: #333333;
              "
            >
              <i class="el-icon-circle-check" style="color: #ee3d33"></i>
              品牌保护
            </div>
            <div
              style="
                font-family: Source Han Sans CN;
                font-weight: 400;
                font-size: 16px;
                color: #333333;
                margin: 5px 0;
              "
            >
              有利于保护公司无形资产
            </div>
            <div
              style="
                font-family: Source Han Sans CN;
                font-weight: 400;
                font-size: 16px;
                color: #333333;
                margin: 5px 0;
              "
            >
              避免行业内恶性竞争
            </div>
            <div
              style="
                font-family: Source Han Sans CN;
                font-weight: 400;
                font-size: 16px;
                color: #333333;
                margin: 5px 0;
              "
            >
              防止同品类企业侵权。
            </div>
          </div>

          <div
            style="
              position: absolute;
              right: -270px;
              bottom: -35px;
              text-align: left;
            "
          >
            <div
              style="
                font-family: Source Han Sans CN;
                font-weight: bold;
                font-size: 24px;
                color: #333333;
              "
            >
              <i class="el-icon-circle-check" style="color: #ee3d33"></i>
              无形资产
            </div>
            <div
              style="
                font-family: Source Han Sans CN;
                font-weight: 400;
                font-size: 16px;
                color: #333333;
                margin: 5px 0;
              "
            >
              选择具有新颖创意的商标注册
            </div>
            <div
              style="
                font-family: Source Han Sans CN;
                font-weight: 400;
                font-size: 16px;
                color: #333333;
                margin: 5px 0;
              "
            >
              具有非常高的经济利益
            </div>
            <div
              style="
                font-family: Source Han Sans CN;
                font-weight: 400;
                font-size: 16px;
                color: #333333;
                margin: 5px 0;
              "
            >
              商标可作为无形资产进行质押贷款
            </div>
          </div>

          <div
            style="
              position: absolute;
              left: -160px;
              bottom: -35px;
              text-align: left;
            "
          >
            <div
              style="
                font-family: Source Han Sans CN;
                font-weight: bold;
                font-size: 24px;
                color: #333333;
              "
            >
              <i class="el-icon-circle-check" style="color: #ee3d33"></i>
              必备资质
            </div>
            <div
              style="
                font-family: Source Han Sans CN;
                font-weight: 400;
                font-size: 16px;
                color: #333333;
                margin: 5px 0;
              "
            >
              企业拓展网络营销宣传面
            </div>
            <div
              style="
                font-family: Source Han Sans CN;
                font-weight: 400;
                font-size: 16px;
                color: #333333;
                margin: 5px 0;
              "
            >
              及消费市场入驻电商平台的资质要求
            </div>
            <div
              style="
                font-family: Source Han Sans CN;
                font-weight: 400;
                font-size: 16px;
                color: #333333;
                margin: 5px 0;
              "
            >
              (如:入驻超市、天猫、京东等电商平台)
            </div>
          </div>
        </div>

        <div class="bottom_bg">
          <div style="margin: 0 70px; padding-top: 50px">
            <div style="display: flex; align-items: center">
              <img src="@/assets/img/propertyRight/one/4.png" alt="" />
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: bold;
                  font-size: 24px;
                  color: #333333;
                  margin-left: 20px;
                "
              >
                注册商标的方法
              </div>
            </div>

            <div style="display: flex; align-items: center; margin-top: 50px">
              <img src="@/assets/img/propertyRight/one/5.png" alt="" />
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #333333;
                  margin-left: 20px;
                "
              >
                自行登录商标局官网进行注册。
              </div>
            </div>

            <div style="display: flex; align-items: center; margin-top: 50px">
              <img src="@/assets/img/propertyRight/one/5.png" alt="" />
              <div
                style="
                  font-family: Source Han Sans CN;
                  font-weight: 400;
                  font-size: 18px;
                  color: #333333;
                  margin-left: 20px;
                  width: 700px;
                "
              >
                交由「爱听企服」代理:免费查询商标、指导商标注册类别、判断注册成功
                率、协助准备材料、提起申请、与商标局实时对接注册信息。
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div
          style="
            font-family: Source Han Sans CN;
            font-weight: bold;
            font-size: 24px;
            color: #333333;
            padding: 20px 0;
            border-bottom: 1px solid #D0D0D0;
            position: relative;
          "
        >
          <div
            style="
              height: 5px;
              width: 75px;
              background-color: #F55656;
              position: absolute;
              bottom: -3px;
            "
          ></div>
          商标注册的流程
        </div>

        <div
          style="
            margin-top: 35px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            font-size: 18px;
            color: #333333;
          "
        >
          整个流程平均耗时:13个月
        </div>
        <div
          style="
            margin-top: 15px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            font-size: 18px;
            color: #333333;
          "
        >
          4个月左右下发受理通知书+6个月实质审查+公示期3个月
        </div>

        <div style="text-align: center; margin-top: 50px">
          <img src="@/assets/img/propertyRight/one/6.png" alt="" />
        </div>
      </div>
    </div>
    <div class="b_c" style="margin-bottom: 50px;padding: 50px 0;margin-top: 50px;">
      <div style="width: 1600px; margin: 0 auto">
        <div
          style="
            font-family: Source Han Sans CN;
            font-weight: bold;
            font-size: 24px;
            color: #333333;
            padding: 20px 0;
            border-bottom: 1px solid #D0D0D0;
            position: relative;
          "
        >
          <div
            style="
              height: 5px;
              width: 75px;
              background-color: #F55656;
              position: absolute;
              bottom: -3px;
            "
          ></div>
          商标注册的流程
        </div>

        <div class="b_title">一.商标受通:</div>
        <div class="b_content">
          《注册申请受理通知书》仅表明商标注册申请已被商标局受理，并不表明该申请已被核准。
        </div>

        <div class="b_title">二.驳回复审:</div>
        <div class="b_content">
          商标注册申请被驳回的，如果对驳回决定不服，申请人可以自收到驳回通知之日起15日内向商标评审委员会申请复审。
        </div>

        <div class="b_title">三.异议答辩:</div>
        <div class="b_content">
          申请注册的商标被提出异议的，如果申请人(即被异议人)对商标局的不予注册决定不服，可以自收到通知之日起15日内向商标评审委员会申请复审。
        </div>

        <div class="b_title">四.商标续展:</div>
        <div class="b_content">
          注册商标的有效期为10年，自核准注册之日起计算。注册商标有效期满需要继续使用的，商标注册人应当在期满前12个月内办理续展手续。
        </div>
        <div class="b_content">
          商标注册人在此期间未能办理的，可以在期满后的6个月的宽展期内提出，但须缴纳受理续展注册迟延费。
        </div>
        <div class="b_content">
          宽展期满后仍未提出续展申请的，商标局将注销该注册商标，如果原注册人想继续拥有该商标专用权，则须重新提出注册申请。
        </div>
      </div>
    </div>

    <bottomWiedt style="margin-top: 50px;" :bottomTitle="bottomTitle"></bottomWiedt>
  </div>
</template>

<script>
import bottomWiedt from "@/views/components/qualifications/bottomWiedt";
export default {
  components: {
    bottomWiedt,
  },
  data() {
    return {
      value: "",
      value1: "",
      bottomTitle: "创业前沿资讯",
    };
  },
  methods: {
    submit() {
      if (this.value == "") {
        this.$message("请输入名称！");
        return;
      }
      if (this.value1.length != 11) {
        this.$message("请输入正确的手机号");
        return;
      }

      const url = "https://admin.atqf.cn/prod-api/atgw/wgsjzl";
      const options = {
        method: "POST", // 请求方法
        headers: {
          "Content-Type": "application/json", // 设置请求头
        },
        body: JSON.stringify({
          phonenumber: this.value1,
          status: "0",
          name: this.value,
        }), // 设置请求体
      };

      fetch(url, options)
        .then((response) => response.json())
        .then((res) => {
          if (res.code == 200) {
            this.$message("提交成功！");
            this.value = "";
            this.value1 = "";
          } else {
            this.$message(res.msg);
          }
        })
        .catch((error) => console.error("Error:", error));
    },
  },
};
</script>
<style scoped>
.b_title {
  font-family: Source Han Sans CN;
  font-weight: 800;
  font-size: 18px;
  color: #333333;
  margin: 15px 0 10px;
}
.b_content {
  font-family: Source Han Sans CN;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
  margin: 15px 0 10px;
}
.bg {
  margin: 0 auto;
  text-align: center;
  height: 470px;
  width: 680px;
  background-image: url("~@/assets/img/propertyRight/one/2.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.bottom_bg {
  margin: 100px auto 0;
  height: 400px;
  width: 1600px;
  background-image: url("~@/assets/img/propertyRight/one/3.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.b_c {
  margin: 0 auto;
  height: 450px;
  width: 2000px;
  background-image: url("https://atqf.oss-cn-beijing.aliyuncs.com/file/2024-07-12/矩形 27 拷贝 6.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
</style>